import React, { useEffect, useContext } from 'react'

// COMPONENTS
import TasksHeader from './TasksHeader/TasksHeader'
import TasksTable from './TasksTable/TasksTable'
import FlyoutMainSection from './FlyoutMainSection/FlyoutMainSection'

// CONTEXTS
import { PageTasksContext } from 'contexts/PageTasksContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { getTasksListByTypeApi } from 'services/tasks/getTasksListApi'

// STYLES
import useStyles from './tasksUseStyles'

const Tasks = () => {
  const { 
    pageType,
    isRightInfoOpen, 
    isLoading,
    changeIsLoading,
    changeTableData, 
    changeSearch,
    changeSelectedRowList,
    changeIsRightInfoOpen,
  } = useContext(PageTasksContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchGetTasksListApi = async () => {
    changeIsLoading(true)

    const data = await getTasksListByTypeApi(pageType)
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }

    changeIsLoading(false)
  }

  useEffect(() => {
    changeSearch('')
    changeSelectedRowList([])
    changeIsRightInfoOpen(false)
    changeTableData([])

    fetchGetTasksListApi()

    return () => fetchGetTasksListApi()
  }, [pageType]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['tasksRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* HEADER */}
        <TasksHeader/>

        <div
          className={
            isRightInfoOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <TasksTable/>
          </div>

          {/* RIGHT CONTENT */}
          <div
            className={
              isRightInfoOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <FlyoutMainSection/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Tasks
