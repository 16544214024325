// MATERIAL UI CORES
import Menu from '@material-ui/core/Menu'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 4,
    boxShadow: `0px 10px 10px ${colors['boxShadow']}`,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

export default CustomMenu