import React, { createContext, useState } from 'react'

const PagePlacesContext = createContext()

const PagePlacesContextProvider = (props) => {
  // TYPE = BOOLEAN
  const [ isRightContentOpen, setIsRightContentOpen ] = useState(true)
  const [ isLoading, setIsLoading ] = useState(false)

  // TYPE = ARRAY
  const [ selectedRowList, setSelectedRowList ] = useState([])
  const [ tableData, setTableData ] = useState([])
  
  // TYPE = OBJECT
  const [ addPlacesObject, setAddPlacesObject ] = useState(null)

  // TYPE = STRING
  const [ search, setSearch ] = useState('')

  const changeAddPlacesObject = (inputObject) => {
    setAddPlacesObject(inputObject)
  }

  const changeSelectedRowList = (inputRowList) => {
    setSelectedRowList(inputRowList)
  }

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeIsRightContentOpen = (inputState) => {
    setIsRightContentOpen(inputState)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  return (
    <PagePlacesContext.Provider
      value={{
        addPlacesObject, changeAddPlacesObject,
        selectedRowList, changeSelectedRowList,
        search, changeSearch,
        tableData, changeTableData,
        isRightContentOpen, changeIsRightContentOpen,
        isLoading, changeIsLoading,
      }}
    >
      {props['children']}
    </PagePlacesContext.Provider>
  )
}

export { PagePlacesContextProvider, PagePlacesContext }