import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// COMPONENTS
import FormOne from './FormOne/FormOne'
import FormTwo from './FormTwo/FormTwo'

// CUSTOM COMPONENTS
import CustomStepperLabel from './CustomStepperLabel/CustomStepperLabel'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './rightSectionUseStyles'

const RightSection = () => {
	const classes = useStyles()

  const [ activeStep, setActiveStep ] = useState(0)

  const stepList = [ 1, 2 ]

  return (
    <Grid 
      item 
      xs={12} md={6} lg={4}
    >
      <div className={classes['rightSection']}>
        <div className={classes['rightContent']}>
          {/* TITLE */}
          <Typography
            variant='h4'
            className={classes['title']}
          >
            Register
          </Typography>

          {/* CAPTION */}
          <Typography
            variant='h6'
            className={classes['caption']}
          >
            Already have an account?&nbsp;
            <Link
              to='/sign-in'
              className={classes['signInLink']}
            >
              Sign In
            </Link>
          </Typography>

          {/* STEPPER */}
          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
          >
            {stepList.map(item => (
              <Step key={item}>
                <StepLabel StepIconComponent={CustomStepperLabel}></StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* FORM */}
          {activeStep === 0 ?
          <FormOne setActiveStep={setActiveStep}/> :
          <FormTwo setActiveStep={setActiveStep}/>
          }

          {/* AGREEMENT TEXT */}
          <Typography
            variant='subtitle1'
            className={classes['agreementText']}
          >
            By registering, I agree to the&nbsp;
            <span className={classes['agreementTextBold']}>
              Terms of Service
            </span>
            &nbsp;and&nbsp;
            <span className={classes['agreementTextBold']}>
              Policies.
            </span>
          </Typography>
        </div>
      </div>
    </Grid>
  )
}

export default RightSection
