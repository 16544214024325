import React from 'react'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconAdd from '@material-ui/icons/Add'

// STYLES
import useStyles from './addButtonUseStyles'

const AddButton = (props) => {
  const { onClick, className } = props

  const classes = useStyles()

  return (
    <IconButton 
      onClick={onClick}
      className={
        className ?
        `${classes['addButtonRoot']} ${className}` :
        classes['addButtonRoot']
      }
    >
      <IconAdd/>
    </IconButton>
  )
}

export default AddButton
