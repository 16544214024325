import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'

// IMAGES
import IconSidebarToggle from 'images/icons/sidebar_toggle.svg'
import LogoValidFullColor from 'images/logo/valid_full_color.png'
import LogoLacakFullColor from 'images/logo/lacak_full_color.svg'
import LogoWulingFullColor from 'images/logo/wuling_full_color.svg'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './toggleUseStyles'

const Toggle = (props) => {
  const { 
    isSidebarOpen, 
    setIsSidebarOpen,
  } = props

  const { userRoles } = useContext(CurrentUserContext)

  const history = useHistory()

  const classes = useStyles()

  // let companyLogo = LogoLacakFullColor
  // if(userRoles.includes('wuling')) companyLogo = LogoWulingFullColor

  return (
    <div>
      <div className={classes['logoAndToogleContainer']}>
        {/* TOGGLE */}
        <img 
          src={IconSidebarToggle}
          alt=''
          className={
            isSidebarOpen ?
            classes['iconToggleSidebar'] :
            classes['iconToogleSidebarOnClose']
          }
          onClick={() => setIsSidebarOpen(current => !current)}
        />

        {/* LOGO */}
        {isSidebarOpen &&
        <img 
          src={LogoValidFullColor}
          alt=''
          className={classes['companyLogo']}
          onClick={() => history.push('/dashboard')}
        />}
      </div>

      {/* CLIENT NAME TEXT */}
      {(isSidebarOpen && !userRoles.includes('wuling')) && 
      <Typography
        variant='subtitle2'
        className={classes['clientName']}
      >
        Wuling Finance
      </Typography>}
    </div>
  )
}

export default Toggle
