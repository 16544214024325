import React from 'react'

// COMPONENTS
import ShowMap from './ShowMap/ShowMap'
import MainSection from './MainSection/MainSection'
import Summary from './Summary/Summary'

const RightInfo = () => {
  const containerStyle = {
    width: '100%',
    height: 400,
    borderRadius: '4px 4px 0px 0px',
  }

  return (
    <>
      <ShowMap containerStyle={containerStyle}/>
      <MainSection/>
      <Summary/>
    </>
  )
}

export default RightInfo
