import React, { useEffect, useContext } from 'react'

// COMPONENTS
import CarModelHeader from '../CarModel/CarModelHeader/CarModelHeader'
import CarModelTable from '../CarModel/CarModelTable/CarModelTable'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { getCarModelListApi } from 'services/carModel/getCarModelListApi'

// STYLES
import useStyles from './carModelUseStyles'

const CarModel = () => {
  const { 
    changeTableData, 
    isLoading,
    changeIsLoading, 
  } = useContext(PageCarModelContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchGetCarModelListApi = async () => {
    changeIsLoading(true)

    const data = await getCarModelListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }

    changeIsLoading(false)
  }

  useEffect(() => {
    fetchGetCarModelListApi()

    return () => fetchGetCarModelListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['carModelRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* HEADER */}
        <CarModelHeader/>

        <div className={classes['contentCard']}>
          {/* TABLE */}
          <CarModelTable/>
        </div>
      </>}
    </div>
  )
}

export default CarModel
