// FIREBASE
import { getAuth } from 'firebase/auth'

export const deleteSalesOrderApi = async (inputSalesOrderId) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/${inputSalesOrderId}`
  
  const response = await fetch(completeUrl, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  return response
}