import React, { useEffect, useContext } from 'react'

// COMPONENTS
import SalesOrderHeader from './SalesOrderHeader/SalesOrderHeader'
import SalesOrderTable from './SalesOrderTable/SalesOrderTable'
import FlyoutMainSection from './FlyoutMainSection/FlyoutMainSection'

// CONTEXTS
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { formatGetSalesOrderListApi } from 'services/salesOrder/getSalesOrderListApi'

// STYLES
import useStyles from './salesOrderUseStyles'

const SalesOrder = () => {
  const { 
    isRightInfoOpen, 
    changeTableData,
    isLoading,
    changeIsLoading,
  } = useContext(PageSalesOrderContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchFormatGetSalesOrderListApi = async () => {
    changeIsLoading(true)

    const data = await formatGetSalesOrderListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }

    changeIsLoading(false)
  }

  useEffect(() => {
    fetchFormatGetSalesOrderListApi()

    return () => fetchFormatGetSalesOrderListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['salesOrderRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        <SalesOrderHeader/>

        <div
          className={
            isRightInfoOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <SalesOrderTable/>
          </div>
          
          {/* RIGHT CONTENT */}
          <div
            className={
              isRightInfoOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <FlyoutMainSection/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default SalesOrder
