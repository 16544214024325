import React from 'react'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './activityLogUseStyles'

const ActivityLog = () => {
  const classes = useStyles()

  return (
    <div>
      <Typography
        variant='subtitle2'
        className={classes['title']}
      >
        Activity Log
      </Typography>
    </div>
  )
}

export default ActivityLog
