// FIREBASE
import { getAuth } from 'firebase/auth'

export const getPlacesListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const responseJson = await response.json()
  return responseJson
}