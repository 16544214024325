import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogAddOrEditAccessory from '../DialogAddOrEditAccessory/DialogAddOrEditAccessory'
import DialogDeleteAccessory from '../DialogDeleteAccessory/DialogDeleteAccessory'

// CONTEXTS
import { PageAccessoriesContext } from 'contexts/PageAccessoriesContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconDelete from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './accessoriesTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const AccessoriesTable = () => {
  const { 
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList,
  } = useContext(PageAccessoriesContext)

  const classes = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ isHovered, setIsHovered ] = useState(null)
  const [ dialogAddOrEditAccessory, setDialogAddOrEditAccessory ] = useState(null)
  const [ dialogDeleteAccessory, setDialogDeleteAccessory ] = useState(null)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'name',
      headerName: 'Accessories Name',
      flex: 1,
      minWidth: 300,
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === isHovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditAccessory(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeleteAccessory(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (search !== '') {
      const newData = tableData.filter(item => 
        item['name'].toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
        onRowLeave={(params, event) => setIsHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            null,
          )
        }
      />

      {/* DIALOG ADD OR EDIT */}
      {dialogAddOrEditAccessory &&
      <DialogAddOrEditAccessory
        type='edit'
        dialogAddOrEditAccessory={dialogAddOrEditAccessory}
        setDialogAddOrEditAccessory={setDialogAddOrEditAccessory}
      />}

      {/* DIALOG DELETE */}
      <DialogDeleteAccessory
        dialogDeleteAccessory={dialogDeleteAccessory}
        setDialogDeleteAccessory={setDialogDeleteAccessory}
      />
    </>
  )
}

export default AccessoriesTable
