import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
	rightSection: {
		height: '100vh',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 800,
	},
	rightContent: {
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			width: '80%',
		},
		[theme.breakpoints.up('sm')]: {
			width: '75%',
    },
		minHeight: 800,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	title: {
		fontWeight: 600,
		marginBottom: 5,
	},
	caption: {
		fontWeight: 500,
		color: colors['darkgray'],
		marginBottom: 50,
	},
	signInLink: {
    color: colors['dodgerblue'],
		textDecoration: 'none',
		fontWeight: 600,
		cursor: 'pointer',
	},
  circleStepLabel: {
    backgroundColor: 'orange'
  },
  agreementText: {
    width: '100%',
		bottom: 10,
		position: 'absolute',
  },
  agreementTextBold: {
    fontWeight: 600,
  },
}))

export default useStyles