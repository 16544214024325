import React, { useState, useContext } from 'react'

// COMPONENTS
import DialogDelivery from '../DialogDelivery/DialogDelivery'
import DialogReceive from '../DialogReceive/DialogReceive'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconLocalShipping from '@mui/icons-material/LocalShipping'
import IconInventory from '@mui/icons-material/Inventory'

// STYLES
import useStyles from './dialogQuickActionUseStyles'

const DialogQuickAction = (props) => {
  const {
    isDialogQuickActionOpen,
    setIsDialogQuickActionOpen,
  } = props

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageStockOutContext)

  const classes = useStyles()

  const [ dialogDelivery, setDialogDelivery ] = useState(null)
  const [ dialogReceive, setDialogReceive ] = useState(null)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  let quickActionList = [
    {
      title: 'Delivery',
      caption: 'Create delivery',
      icon: IconLocalShipping,
      instruction: 0,
    },
  ]

  if(
    selectedData[0] && 
    selectedData[0]['shipments']['length'] > 0 && 
    !selectedData[0]['shipments'][selectedData[0]['shipments']['length'] - 1]['receivedBy']
  ) {
    quickActionList = [
      {
        title: 'Receive',
        caption: 'Create receive',
        icon: IconInventory,
        instruction: 1,
      },
    ]
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDialogQuickActionOpen(false)
    }
  }

  const onQuickActionItemIsClicked = (inputInstruction) => {
    if(inputInstruction === 0) {
      setDialogDelivery(true)
    }
    else if(inputInstruction === 1) {
      setDialogReceive(true)
    }
    setIsDialogQuickActionOpen(false)
  }

  return (
    <>
      <CustomDialog 
        open={isDialogQuickActionOpen}
        onClose={handleClose}
      >
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          Quick Action
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* MENU LIST */}
          {quickActionList.map((item, index) => (
            <div
              key={index}
              className={classes['itemListContainer']}
              onClick={() => onQuickActionItemIsClicked(item['instruction'])}
            >
              {/* ICON */}
              <div className={classes['iconContainer']}>
                <item.icon style={{ color: 'white', height: 15 }}/>
              </div>

              {/* TEXTS */}
              <div>
                {/* ITEM TITLE */}
                <Typography
                  variant='subtitle1'
                  className={classes['itemTitle']}
                >
                  {item['title']}
                </Typography>

                {/* ITEM CAPTION */}
                <Typography
                  variant='subtitle2'
                  className={classes['itemCaption']}
                >
                  {item['caption']}
                </Typography>
              </div>
            </div>
          ))}
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions className={classes['dialogAction']}>
          {/* CANCEL BUTTON */}
          <Button onClick={() => setIsDialogQuickActionOpen(false)}>
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>
        </CustomDialogActions>
      </CustomDialog>

      {/* DIALOG DELIVERY */}
      <DialogDelivery
        dialogDelivery={dialogDelivery}
        setDialogDelivery={setDialogDelivery}
        setIsDialogQuickActionOpen={setIsDialogQuickActionOpen}
      />

      {/* DIALOG RECEIVE */}
      <DialogReceive
        dialogReceive={dialogReceive}
        setDialogReceive={setDialogReceive}
        setIsDialogQuickActionOpen={setIsDialogQuickActionOpen}
      />
    </>
  )
}

export default DialogQuickAction
