import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'

const AuthenticationRoute = (props) => {
  const { currentUser }= useContext(CurrentUserContext)

  return (
    <Route {...props}>
      { currentUser ? <Redirect to='/dashboard'/> : props['children'] }
    </Route>
  )
}

export default AuthenticationRoute