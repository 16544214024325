// MATERIAL UI CORES
import FormControl from '@material-ui/core/FormControl'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: colors['dodgerblue'],
    },
    '& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
        borderColor: colors['dodgerblue'],
      },
      '&.Mui-focused fieldset': {
        borderColor: colors['dodgerblue'],
      },
      borderRadius: 4,
    },
  },
})(FormControl)

export default CustomFormControl