import React, { createContext, useState, useEffect } from 'react'

// FIREBASE
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from 'firebase/app'

// JWT DECODE
import jwt_decode from 'jwt-decode'

const CurrentUserContext = createContext()

const CurrentUserContextProvider = (props) => {
  const TAG = 'CurrentUserContext'

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
  }
  
  const firebaseApp = initializeApp(firebaseConfig)

  const [ currentUser, setCurrentUser ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ userRoles, setUserRoles ] = useState([])

  const auth = getAuth()

  const getCurrentUser = () => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`${TAG}, getCurrentUser, user: `, user)

        const decodedAccessToken = jwt_decode(user['accessToken'])
        decodedAccessToken['roles'] && setUserRoles(decodedAccessToken['roles'])

        setCurrentUser(user)
      } else {
        console.log(`${TAG}, getCurrentUser, uid: `, 'no uid')
        setCurrentUser(null)
      }
      setIsLoading(false)
    })
  }

  const removeCurrentUser = () => {
    setCurrentUser(null)
  }

  useEffect(() => {
    getCurrentUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        userRoles,
        removeCurrentUser,
        firebaseApp,
      }}
    >
      {!isLoading && props['children']}
    </CurrentUserContext.Provider>
  )
}

export { CurrentUserContextProvider, CurrentUserContext }