// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUpdatePlaceApi = async (
  inputPlaceId,
  inputName,
  inputAddress,
  inputLatitude,
  inputLongitude,
  inputRadius,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      placeId: inputPlaceId,
      newName: inputName,
      newAddress: inputAddress,
      newLatitude: inputLatitude,
      newLongitude: inputLongitude,
      newRadius: inputRadius,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}