// FIREBASE
import { getAuth } from 'firebase/auth'

export const getDevicesListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/device/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const formatGetDevicesListApi = async () => {
  const rawListData = await getDevicesListApi()
  
  if(rawListData['error']) return rawListData
  else {
    const output = rawListData.map(item => {
      return {
        id: item['deviceInfo']['id'],
        simNumber: item['deviceInfo']['simNumber'],
        imei: item['deviceInfo']['imei'],
        serialNumber: item['deviceInfo']['snNumber'],
      }
    })
    return output
  }
}