import React, { useState, useContext } from 'react'

// COMPONENTS
import DetailDialog from '../DetailDialog/DetailDialog'

// CONTEXTS
import { PageStaffContext } from 'contexts/PageStaffContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'
import IconStaffInArrow from 'images/icons/staff_in_arrow.svg'
import IconStaffOutArrow from 'images/icons/staff_out_arrow.svg'
import IconTimeline from 'images/icons/staff_timeline.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './summaryUseStyles'

// UTILS
import { 
  getStringDateFromStringDate,
  getDayNameFromStringDate,
  getStringClockFromStringDate,
  getStringTimezoneFromStringDate,
} from 'utils/formatStringDate'

const Summary = () => {
  const classes = useStyles()

  const { 
    selectedRowList,
    tableData,
  } = useContext(PageStaffContext)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const [ isSummaryExpand, setIsSummaryExpand ] = useState(false)
  const [ summaryItemExpand, setSummaryItemExpand ] = useState(null)
  const [ isDetailDialogShown, setIsDetailDialogShown ] = useState(false)
  const [ detailObject, setDetailObject ] = useState(null)

  const onIconExpandIsClicked = (inputIndex) => {
    if(summaryItemExpand === inputIndex) {
      setSummaryItemExpand(null)
    }
    else {
      setSummaryItemExpand(inputIndex)
    }
  }

  const onDetailButtonIsClicked = (inputState, inputItem) => {
    setIsDetailDialogShown(true)
    setDetailObject({
      name: selectedData[0]['name'],
      photo: inputItem['imageUrl'],
      type: inputState,
      time: getStringClockFromStringDate(inputItem['actualTime']),
      gmt: getStringTimezoneFromStringDate(inputItem['actualTime']),
      address: inputItem['address'],
    })
  }

  return (
    <div className={classes['summaryRoot']}>
      {/* SUMMARY TITLE CONTAINER */}
      {selectedData['length'] === 1 &&
      <div className={classes['summaryTitleContainer']}>
        {/* SUMMARY TEXT */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Summary
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsSummaryExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isSummaryExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* SUMMARIES */}
      {selectedData['length'] === 1 &&
      <Collapse
        in={isSummaryExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* SUMMARIES DATA */}
        {selectedData[0]['summary']['length'] > 0 &&
        selectedData[0]['summary'].map((item, index) => (
          <div 
            key={index}
            className={classes['summaryItemContainer']}
          >
            {/* SUMMARY HEADER */}
            <div className={classes['summaryItemHeader']}>
              <div>
                {/* DATE */}
                <Typography
                  variant='subtitle2'
                  className={classes['blackerText']}
                >
                  {getStringDateFromStringDate(item['date'])}
                </Typography>

                {/* DAY */}
                <Typography
                  variant='subtitle2'
                  className={classes['commonText']}
                >
                  {getDayNameFromStringDate(item['date'])}
                </Typography>
              </div>

              {/* ICON EXPAND */}
              <IconButton
                onClick={() => onIconExpandIsClicked(index)}
                className={classes['iconExpandContainer']}
              >
                <img 
                  src={IconExpand}
                  alt=''
                  className={
                    summaryItemExpand === index ?
                    classes['iconCollapse'] :
                    classes['iconExpand']
                  }
                />
              </IconButton>
            </div>

            {/* SUMMARY DETAIL */}
            <Collapse
              in={summaryItemExpand === index} 
              timeout='auto' 
              unmountOnExit
            >
              {item['formattedPunchList'] &&
              item['formattedPunchList'].map((punchListItem, punchListIndex) => (
                <div 
                  key={punchListIndex} 
                  className={classes['summaryItemDetail']}
                >
                  {/* ICON TIMELINE */}
                  <img 
                    src={IconTimeline}
                    alt=''
                    className={classes['iconTimeline']}
                  />

                  {/* DETAIL INFORMATION */}
                  <div className={classes['punchInfoContainer']}>
                    {punchListItem.map((punchItem, punchIndex) => (
                      <div 
                        key={punchIndex} 
                        className={classes['inAndOutContainer']}
                      >
                        {/* ICON AND TEXT */}
                        <div className={classes['arrowAndTextContainer']}>
                          <img
                            src={punchIndex === 1 ? IconStaffInArrow : IconStaffOutArrow}
                            alt=''
                          />

                          <Typography
                            variant='subtitle2'
                            className={classes['blackerText']}
                          >
                            {punchIndex === 1 ? 'IN' : 'OUT'}
                          </Typography>
                        </div>

                        {/* TIME AND GMT TEXTS */}
                        <div>
                          <Typography
                            variant='subtitle2'
                            className={classes['blackerText']}
                          >
                            {punchItem ?
                            getStringClockFromStringDate(punchItem['actualTime']) :
                            'No data'}
                          </Typography>

                          <Typography
                            variant='subtitle2'
                            className={classes['commonText']}
                          >
                            {punchItem ?
                            getStringTimezoneFromStringDate(punchItem['actualTime']) :
                            'No data'}
                          </Typography>
                        </div>

                        {/* DETAIL BUTTON */}
                        {punchItem &&
                        <Button 
                          className={classes['detailButton']}
                          onClick={() => onDetailButtonIsClicked(punchItem['isClockIn'] ? 'in' : 'out', punchItem)}
                        >
                          <Typography
                            variant='subtitle2'
                            className={classes['detailText']}
                          >
                            Detail
                          </Typography>
                        </Button>}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Collapse>
          </div>
        ))}

        {/* NO SUMMARY DATA */}
        {selectedData[0]['summary']['length'] === 0 &&
        <Typography
          variant='subtitle2'
          className={classes['commonText']}
        >
          No summary data
        </Typography>}
      </Collapse>}

      {/* DETAIL DIALOG */}
      <DetailDialog
        isDetailDialogShown={isDetailDialogShown}
        setIsDetailDialogShown={setIsDetailDialogShown}
        detailObject={detailObject}
      />
    </div>
  )
}

export default Summary
