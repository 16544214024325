import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomSearchInput from 'components/Custom/CustomSearchInput'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'

// MATERIAL UI ICONS
import IconClose from '@material-ui/icons/Close'
import IconSearch from '@material-ui/icons/Search'

// STYLES
import useStyles from './searchFieldUseStyles'

const SearchField = (props) => {
  const {
    value,
    onChange,
  } = props

  const [ isSearchOpen, setIsSearchOpen ] = useState(false)

  const classes = useStyles()

  return (
    <div className={classes['searchFieldRoot']}>
      {/* ICON */}
      <IconButton 
        className={classes['iconSearch']}
        onClick={() => setIsSearchOpen(true)}
      >
        <IconSearch/>
      </IconButton>

      {/* TEXT FIELD */}
      <CustomSearchInput 
        className={
          isSearchOpen ?
          classes['customSearchInputWide'] :
          classes['customSearchInputNarrow']
        }
        placeholder='Search'
        value={value}
        onChange={onChange}
        endAdornment={
          // ICON CLOSE
          <InputAdornment position='end'>
            <IconButton
              className={classes['iconClose']}
              onClick={() => setIsSearchOpen(false)}
            >
              <IconClose/>
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  )
}

export default SearchField
