import React, { useEffect, useContext } from 'react'

// COMPONENTS
import StockOutHeader from './StockOutHeader/StockOutHeader'
import StockOutTable from './StockOutTable/StockOutTable'
import MainSection from './MainSection/MainSection'
import DeviceUpdate from './DeviceUpdate/DeviceUpdate'
import RightInfoAddButton from './RightInfoAddButton/RightInfoAddButton'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'

// STYLES
import useStyles from './stockOutUseStyles'

const StockOut = () => {
  const classes = useStyles()

  const { 
    isRightInfoOpen,
    changeTableData,
    isLoading,
    changeIsLoading,
  } = useContext(PageStockOutContext)

  const { changeToast } = useContext(PageAllContext)

  const fetchFormatGetStockOutListApi = async () => {
    changeIsLoading(true)

    const data = await formatGetStockOutListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }

    changeIsLoading(false)
  }

  useEffect(() => {
    fetchFormatGetStockOutListApi()

    return () => fetchFormatGetStockOutListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['stockOutRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        <StockOutHeader/>

        <div
          className={
            isRightInfoOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <StockOutTable/>
          </div>

          {/* RIGHT CONTENT */}
          <div
            className={
              isRightInfoOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <MainSection/>
            <DeviceUpdate/>
            <RightInfoAddButton/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default StockOut
