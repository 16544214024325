import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogDetail from '../DialogDetail/DialogDetail'

// CONTEXTS
import { PageSubmissionsContext } from 'contexts/PageSubmissionsContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './submissionsTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

const SubmissionsTable = () => {
  const { 
    pageType,
    search,
    selectedRowList,
    changeSelectedRowList,
    tableData, 
  } = useContext(PageSubmissionsContext)

  const classes = useStyles()

  const [ hovered, setHovered ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ dialogDetail, setDialogDetail ] = useState(null)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'technicianName',
      headerName: 'Technician Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'submissionId',
      headerName: 'Submission ID',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'taskId',
      headerName: 'Task ID',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'formattedCreationDate',
      headerName: 'Creation Date',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    {
      field: 'formattedTaskStart',
      headerName: 'Task Start',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    {
      field: 'formattedTaskFinish',
      headerName: 'Task Finish',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    {
      field: 'formattedTaskCompletionDate',
      headerName: 'Task Completion Date',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        params['value'] &&
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'imei',
      headerName: 'Imei',
      flex: 1,
      minWidth: 200,
    },
    pageType === 'installation' && 
    {
      field: 'simNumber',
      headerName: 'Sim Number',
      flex: 1,
      minWidth: 200,
    },
    pageType === 'installation' && 
    {
      field: 'socketType',
      headerName: 'Socket Type',
      flex: 1,
      minWidth: 200,
    },
    pageType === 'installation' && 
    {
      field: 'gpsPhotos',
      headerName: 'GPS Photo',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'chassisPhotos',
      headerName: 'Chassis Photo',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'odometerPhotos',
      headerName: 'Odometer Photo',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'vehiclePhotos',
      headerName: 'Vehicle Photo',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'pickup' && 
    {
      field: 'itemPhoto',
      headerName: 'Item Photo',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'technicianSignature',
      headerName: 'Technician Signature',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    pageType === 'installation' && 
    {
      field: 'picName',
      headerName: 'PIC Name',
      flex: 1,
      minWidth: 200,
    },
    pageType === 'installation' && 
    {
      field: 'picSignature',
      headerName: 'PIC Signature',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['linkText']}
          onClick={() => setDialogDetail(params)}
        >
          {params['value']}
        </Typography>
      ),
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === hovered &&
        <div className={classes['actionContainer']}>
          <IconButton>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const newData = tableData.filter(item => 
        (item['technicianName'] && item['technicianName'].toLowerCase().includes(search.toLowerCase())) ||
        (item['submissionId'] && item['submissionId'].toString().toLowerCase().includes(search.toLowerCase())) ||
        (item['taskId'] && item['taskId'].toLowerCase().includes(search.toLowerCase())) ||
        (item['formattedCreationDate'] && 
        `${getStringDateFromStringDate(item['formattedCreationDate'])} ${getStringClockFromStringDate(item['formattedCreationDate'])}`
        .toString().toLowerCase().includes(search.toLowerCase())) ||
        (item['formattedTaskStart'] && 
        `${getStringDateFromStringDate(item['formattedTaskStart'])} ${getStringClockFromStringDate(item['formattedTaskStart'])}`
        .toString().toLowerCase().includes(search.toLowerCase())) ||
        (item['formattedTaskFinish'] && 
        `${getStringDateFromStringDate(item['formattedTaskFinish'])} ${getStringClockFromStringDate(item['formattedTaskFinish'])}`
        .toString().toLowerCase().includes(search.toLowerCase())) ||
        (item['formattedTaskCompletionDate'] && 
        `${getStringDateFromStringDate(item['formattedTaskCompletionDate'])} ${getStringClockFromStringDate(item['formattedTaskCompletionDate'])}`
        .toString().toLowerCase().includes(search.toLowerCase())) ||
        (item['imei'] && item['imei'].toLowerCase().includes(search.toLowerCase())) ||
        (item['simNumber'] && item['simNumber'].toLowerCase().includes(search.toLowerCase())) ||
        (item['socketType'] && item['socketType'].toLowerCase().includes(search.toLowerCase())) ||
        (item['notes'] && item['notes'].toLowerCase().includes(search.toLowerCase())) ||
        (item['picName'] && item['picName'].toLowerCase().includes(search.toLowerCase()))
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setHovered(params['row']['id'])}
        onRowLeave={(params, event) => setHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            null,
          )
        }
      />

      {/* DIALOG DETAIL */}
      {dialogDetail &&
      <DialogDetail
        dialogDetail={dialogDetail}
        setDialogDetail={setDialogDetail}
      />}
    </>
  )
}

export default SubmissionsTable
