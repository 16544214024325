// MATERIAL UI CORES
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomCircularProgress = withStyles((theme) => ({
  root: {
    color: colors['dodgerblue'],
    margin: 'auto',
  },
}))(CircularProgress)

export default CustomCircularProgress
