import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogDisableStaff from '../DialogDisableStaff/DialogDisableStaff'
import DialogUpdateStaff from '../DialogUpdateStaff/DialogUpdateStaff'

// CONTEXTS
import { PageStaffContext } from 'contexts/PageStaffContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconBlock from '@material-ui/icons/Block'
import IconPencil from '@material-ui/icons/Create'

// SERVICES
import { putApproveStaffApi } from 'services/staffs/putApproveStaffApi'
import { formatGetStaffListApi } from 'services/staffs/getStaffListApi'

// STYLES
import useStyles from './staffTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

const StaffTable = () => {
  const { 
    selectedRowList,
    changeSelectedRowList,
    search,
    tableData,
    changeIsRightInfoOpen,
    changeIsLoading,
    changeTableData,
  } = useContext(PageStaffContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ isHovered, setIsHovered ] = useState(null)
  const [ filteredData, setFilteredData ] = useState([]) 
  const [ dialogDisableStaff, setDialogDisableStaff ] = useState(null)
  const [ dialogUpdateStaff, setDialogUpdateStaff ] = useState(null)

  const cellClickRef = useRef(null)

  const onApproveTextIsClicked = async (inputParams) => {
    changeIsLoading(true)

    const response = await putApproveStaffApi(inputParams['id'])
    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully approving a staff', 
        severity: 'success',
      })

      const data = await formatGetStaffListApi()
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'formattedCreatedAt',
      headerName: 'Created',
      flex: 1,
      minWidth: 175,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['approvedText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    {
      field: 'isApproved',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        params['value'] ? 
        <Typography
          variant='subtitle2'
          className={classes['approvedText']}
        >
          Approved
        </Typography> :
        <Typography 
          variant='subtitle2'
          className={classes['approveText']}
          onClick={() => onApproveTextIsClicked(params)}
        >
          Approve
        </Typography>
      ),
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === isHovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogUpdateStaff(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDisableStaff(params)}>
            <IconBlock className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const newData = tableData.filter(item =>
        item['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['phone'].includes(search) ||
        item['email'].toLowerCase().includes(search.toLowerCase()) ||
        `${getStringDateFromStringDate(item['formattedCreatedAt'])} ${getStringClockFromStringDate(item['formattedCreatedAt'])}`
        .toLowerCase().includes(search.toLowerCase()) ||
        (item['isApproved'] ? 
        'Approved'.toLowerCase().includes(search.toLowerCase()) : 
        'Approve'.toLowerCase().includes(search.toLowerCase()))
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
        onRowLeave={(params, event) => setIsHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsRightInfoOpen,
          )
        }
      />

      {/* DIALOG DISABLE */}
      <DialogDisableStaff
        dialogDisable={dialogDisableStaff}
        setDialogDisable={setDialogDisableStaff}
      />

      {/* DIALOG UPDATE STAFF */}
      <DialogUpdateStaff
        dialogUpdateStaff={dialogUpdateStaff}
        setDialogUpdateStaff={setDialogUpdateStaff}
      />
    </>
  )
}

export default StaffTable
