import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  formRoot: {},
  errorMessage: {
    marginBottom: 25,
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
  },
	emailTextField: {
		width: '100%',
		marginBottom: 25,
	},
	passwordTextField: {
		width: '100%',
		marginBottom: 25,
	},
  confirmPasswordTextField: {
		width: '100%',
		marginBottom: 50,
	},
	button: {
		backgroundColor: colors['dodgerblue'],
		color: 'white',
		textTransform: 'none',
		height: 55,
		width: '100%',
		borderRadius: 4,
		'&:hover': {
			backgroundColor: colors['dodgerblue'],
		},
	},
	textInButton: {
		fontWeight: 600,
	},
}))

export default useStyles