import React, { useContext } from 'react'

// COMPONENTS
import SearchField from 'components/SearchField/SearchField'

// CONTEXTS
import { PageSubmissionsContext } from 'contexts/PageSubmissionsContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './submissionsHeaderUseStyles'

const InstallationsHeader = () => {
  const { 
    pageType,
    search, 
    changeSearch,
  } = useContext(PageSubmissionsContext)
  
  const classes = useStyles()

  return (
    <div className={classes['submissionsHeaderRoot']}>
      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        {`${pageType}s`}
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />
    </div>
  )
}

export default InstallationsHeader
