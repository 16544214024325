// MATERIAL UI CORES
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomDropdown = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: colors['whitesmokeGray'],
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    height: 44,
    boxSizing: 'border-box',
    color: colors['lightslategray'],
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      backgroundColor: colors['whitesmokeGray'],
    },
  },
}))(InputBase)

export default CustomDropdown