import React from 'react'

// IMAGES
import IconUpload from 'images/icons/quick_action_dialog_import_uplaod.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './noFileUseStyles'

const NoFile = (props) => {
  const {
    getInputProps, 
    open,
  } = props
  
  const classes = useStyles()

  return (
    <div className={`${classes['dropzoneContainer']} ${classes['noFile']}`}>
      {/* INPUT */}
      <input {...getInputProps()}/>

      {/* UPLOAD FILE BUTTON */}
      <Button 
        className={classes['uploadButton']}
        onClick={open}
      >
        <img src={IconUpload} alt='' className={classes['uploadIcon']}/>
        <Typography
          variant='subtitle2'
          className={classes['uploadText']}
        >
          Upload File
        </Typography>
      </Button>

      {/* CAPTION */}
      <Typography
        variant='subtitle2'
        className={classes['caption']}
      >
        Or drag the file here
      </Typography>
    </div>
  )
}

export default NoFile
