// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUpdateDeviceApi = async (
  inputId,
  inputSimNumber,
  inputImei,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/device/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: inputId,
      simNumber: inputSimNumber,
      imei: inputImei,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}