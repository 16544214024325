// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  adminsHeaderRoot: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
  },
  addIconButton: {
    marginRight: 25,
  },
  title: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  information: {
    fontWeight: 600,
    display: 'none',
  },
  informationVisible: {
    display: 'unset',
    marginRight: 238,
    marginLeft: 24,
  },
}))

export default useStyles