// MATERIAL UI CORES
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: '20px 40px',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
      backgroundColor: colors['whitesmokeGray'],
    },
    '&::-webkit-scrollbar-thumb': {
      width: 5,
      height: 5,
      backgroundColor: colors['darkgray'],
    },
  },
}))(DialogContent)

export default CustomDialogContent