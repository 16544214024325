import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  placesHeaderRoot: {
    width: '100%',
    marginBottom: 24,
    boxSizing: 'border-box',
    backgroundColor: colors['whitesmokeGray'],
    display: 'flex',
    alignItems: 'center',
  },
  addIconButton: {
    marginRight: 25,
  },
  title: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  information: {
    fontWeight: 600,
    display: 'none',
  },
  informationVisible: {
    display: 'unset',
    marginRight: 238,
    marginLeft: 24,
  },
}))

export default useStyles