import React, { useEffect, useContext } from 'react'

// COMPONENTS (SORTED BY ORDER)
import DevicesHeader from './DevicesHeader/DevicesHeader'
import DevicesTable from './DevicesTable/DevicesTable'
import ActivityLog from './ActivityLog/ActivityLog'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// CONTEXTS
import { PageDevicesContext } from 'contexts/PageDevicesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// SERVICES
import { formatGetDevicesListApi } from 'services/devices/getDevicesListApi'

// STYLES
import useStyles from './devicesUseStyles'

const Devices = () => {
  const { 
    isHistoryOpen,
    changeTableData,
    isLoading,
    changeIsLoading,
  } = useContext(PageDevicesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchGetDevicesListApi = async () => {
    changeIsLoading(true)
    const data = await formatGetDevicesListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }
    changeIsLoading(false)
  }

  useEffect(() => {
    fetchGetDevicesListApi()

    return () => fetchGetDevicesListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['devicesRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* DATA GRID HEADER */}
        <DevicesHeader/>

        <div
          className={
            isHistoryOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <DevicesTable/>
          </div>
          
          {/* RIGHT CONTENT */}
          <div
            className={
              isHistoryOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <ActivityLog/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Devices
