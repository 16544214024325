import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  summaryRoot: {
    padding: '0px 20px 20px',
  },
  summaryTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
    marginBottom: 15,
  },
  title: {
    color: colors['black'],
    fontWeight: 600,
  },
  commonText: {
    color: colors['lightslategray'],
    fontWeight: 400,
  },
  blackerText: {
    color: colors['black'],
    fontWeight: 400,
  },
  summaryText: {
    marginTop: 15,
    marginBottom: 0,
  },
  summaryItemContainer: {
    padding: '15px 0px',
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
  },
  summaryItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconExpandContainer: {
    width: 36,
    height: 36,
  },
  iconExpand: {
    cursor: 'pointer',
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  iconCollapse: {
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  summaryItemDetail: {
    display: 'flex',
    alignItems: 'center',
  },
  iconTimeline: {
    height: '100%',
    marginRight: 10,
  },
  punchInfoContainer: {
    width: '100%',
  },
  inAndOutContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px 0px',
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
    '&:first-child': {
      marginTop: 15,
    },
    '&:last-child': {
      border: 'none',
    },
  },
  arrowAndTextContainer: {
    textAlign: 'center',
    width: 30,
    marginRight: 10,
  },
  detailButton: {
    borderRadius: 4,
    padding: '5px 12.5px',
    marginLeft: 'auto',
  },
  detailText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
}))

export default useStyles