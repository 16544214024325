import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  informationRoot: {
    padding: 20,
  },
  instructionText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
  title: {
    color: colors['black'],
    fontWeight: 600,
    paddingBottom: 5,
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
    marginBottom: 15,
  },
  blackerText: {
    color: colors['black'],
    fontWeight: 400,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    color: colors['darkgray'],
    fontWeight: 600,
  },
  iconAndTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconForText: {
    color: colors['darkgray'],
    marginRight: 20,
  },
}))

export default useStyles