import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  detailInfoContainer: {
    display: 'flex',
  },
  photoProfile: {
    width: '35%',
    marginRight: 20,
    borderRadius: 4,
    cursor: 'pointer',
  },
  photoProfileLarge: {
    width: '100%',
  },
  name: {
    fontWeight: 600,
    marginBottom: 15,
    color: colors['black'],
  },
  iconAndTextContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  icon: {
    marginTop: 6,
    marginRight: 10,
  },
  text: {
    fontWeight: 400,
    marginBottom: 0,
    color: colors['slategray'],
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
}))

export default useStyles