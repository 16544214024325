export const getStringDateFromStringDate = (inputString) => {
  const fullDate = new Date(inputString)

  let date = fullDate.getDate()
  date = date < 10 ? `0${date}` : date
  let month = fullDate.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  const year = fullDate.getFullYear()

  const output = `${date}/${month}/${year}`

  return output
}

export const getDayNameFromStringDate = (inputString) => {
  const fullDate = new Date(inputString)
  const output = fullDate.toLocaleString('en-us', { weekday: 'long' })
  return output
}

export const getStringClockFromStringDate = (inputString) => {
  const fullDate = new Date(inputString)

  let hours = fullDate.getHours() < 12 ? fullDate.getHours() : fullDate.getHours() - 12
  hours = hours < 10 ? `0${hours}` : hours
  let minutes = fullDate.getMinutes()
  minutes = minutes < 10 ? `0${minutes}` : minutes
  let seconds = fullDate.getSeconds()
  seconds = seconds < 10 ? `0${seconds}` : seconds

  const amPm = fullDate.getHours() < 12 ? 'AM' : 'PM'

  const output = `${hours}:${minutes}:${seconds} ${amPm}`

  return output
}

export const getStringTimezoneFromStringDate = (inputString) => {
  const fullDate = new Date(inputString)

  let output = fullDate.getTimezoneOffset()

  if(output === -420) output = 'Western Indonesian Time'
  else if(output === -480) output = 'Central Indonesian Time'
  else if(output === -540) output = 'Eastern Indonesian Time'
  
  return output
}