import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AddButtonSmall from 'components/AddButtonSmall/AddButtonSmall'
import DialogAddCarModel from 'components/CrudDialog/DialogAddCarModel/DialogAddCarModel'
import DialogAddPlace from 'components/CrudDialog/DialogAddPlace/DialogAddPlace'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { PageSalesOrderContext } from 'contexts/PageSalesOrderContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconTextSnippet from '@mui/icons-material/TextSnippet'
import IconChromeReaderMode from '@mui/icons-material/ChromeReaderMode'
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconFormatColorFill from '@mui/icons-material/FormatColorFill'
import IconGarage from '@mui/icons-material/Garage'
import IconBallot from '@mui/icons-material/Ballot'
import IconPlace from '@mui/icons-material/Place'
import IconPinDrop from '@mui/icons-material/PinDrop'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconContacts from '@mui/icons-material/Contacts'
import IconCall from '@mui/icons-material/Call'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers'

// SERVICES
import { getCarModelListApi } from 'services/carModel/getCarModelListApi'
import { getPlacesListApi } from 'services/places/getPlacesListApi'
import { postNewSalesOrderApi } from 'services/salesOrder/postNewSalesOrderApi'
import { putUpdateSalesOrderApi } from 'services/salesOrder/putUpdateSalesOrderApi'
import { getSalesOrderListApi } from 'services/salesOrder/getSalesOrderListApi'

// STYLES
import useStyles from './dialogAddOrEditSalesOrderUseStyles'

const DialogAddSalesOrder = (props) => {
  const { 
    type,
    dialogAddOrEditSalesOrder, 
    setDialogAddOrEditSalesOrder,
  } = props

  const { currentUser } = useContext(CurrentUserContext)

  const { 
    changeIsLoading, 
    changeTableData,
  } = useContext(PageSalesOrderContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ customerName, setCustomerName ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['customerName'] : (type === 'add' ? '' : '')
  )
  const [ contractNumber, setContractNumber ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['contractNumber'] : (type === 'add' ? '' : '')
  )
  const [ bpkbName, setBpkbName ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['bpkbCustomerName'] : (type === 'add' ? '' : '')
  )
  const [ carModelList, setCarModelList ] = useState([])
  const [ carModel, setCarModel ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['carModel'] : (type === 'add' ? null : null)
  )
  const [ inputCarModel, setInputCarModel ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['carModel']['name'] : (type === 'add' ? '' : '')
  )
  const [ chassisNumber, setChassisNumber ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['chassisNumber'] : (type === 'add' ? '' : '')
  )
  const [ machineNumber, setMachineNumber ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['machineNumber'] : (type === 'add' ? '' : '')
  )
  const [ dealerList, setDealerList ] = useState([])
  const [ dealerSeller, setDealerSeller ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['sellerPlace'] : (type === 'add' ? null : null)
  )
  const [ inputDealerSeller, setInputDealerSeller ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['sellerPlace']['name'] : (type === 'add' ? '' : '')
  )
  const [ dealerInstallation, setDealerInstallation ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['installationPlace'] : (type === 'add' ? null : null)
  )
  const [ inputDealerInstallation, setInputDealerInstallation ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['installationPlace']['name'] : (type === 'add' ? '' : '')
  )
  const [ selectedDateAndTime, setSelectedDateAndTime ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['installmentDate'] : (type === 'add' ? null : null)
  )
  const [ picName, setPicName ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['picName'] : (type === 'add' ? '' : '')
  )
  const [ picPhoneNumber, setPicPhoneNumber ] = useState(
    type === 'edit' ? dialogAddOrEditSalesOrder['row']['picPhoneNumber'] : (type === 'add' ? '' : '')
  )
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ dialogAddCarModel, setDialogAddCarModel ] = useState(null)
  const [ dialogAddPlace, setDialogAddPlace ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditSalesOrder(false)
    }
  }

  const fetchGetCarModelListApi = async () => {
    setIsDialogLoading(true)

    const data = await getCarModelListApi()
    // console.log(data)
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      setCarModelList(data)
    }

    setIsDialogLoading(false)
  }

  const fetchGetPlacesListApi = async () => {
    setIsDialogLoading(true)

    const data = await getPlacesListApi()
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      setDealerList(data)
    }

    setIsDialogLoading(false)
  }

  const fetchPostNewOrUpdateSalesOrderApi = async () => {
    changeIsLoading(true)

    let response
    if(type === 'edit') {
      response = await putUpdateSalesOrderApi(
        parseInt(dialogAddOrEditSalesOrder['id']),
        parseInt(dealerSeller['id']),
        parseInt(dealerInstallation['id']),
        contractNumber,
        machineNumber,
        customerName,
        bpkbName,
        parseInt(carModel['id']),
        // TODO: ADD CAR COLOR HERE
        chassisNumber,
        currentUser['displayName'],
        selectedDateAndTime,
        picName,
        picPhoneNumber,
      )
    }
    else if(type === 'add') {
      response = await postNewSalesOrderApi(
        dealerSeller['id'],
        dealerInstallation['id'],
        contractNumber,
        machineNumber,
        customerName,
        bpkbName,
        carModel['id'],
        // TODO: ADD CAR COLOR HERE
        chassisNumber,
        currentUser['displayName'],
        selectedDateAndTime,
        picName,
        picPhoneNumber,
      )
    }
    // console.log(response)

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully ${type === 'edit' ? 'edit' : 'add'}ing a ${type === 'add' ? 'new ' : ''}sales order item`, 
        severity: 'success',
      })

      const data = await getSalesOrderListApi()
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }
    
    changeIsLoading(false)
    setDialogAddOrEditSalesOrder(false)
  }

  const onAddButtonSmallIsClicked = (inputState) => {
    if(inputState === 'car model') setDialogAddCarModel(true)
    else if(inputState === 'place') setDialogAddPlace(true)
    // TODO: SHOW DIALOG ADD CAR COLOR HERE
    setDialogAddOrEditSalesOrder(false)
  }

  const onSaveButtonIsClicked = () => {
    if(
      customerName === '' || bpkbName === '' || !carModel || inputCarModel === '' ||
      chassisNumber === '' || machineNumber === '' || !dealerSeller || inputDealerSeller === '' ||
      !dealerInstallation || inputDealerInstallation === '' || !selectedDateAndTime || selectedDateAndTime === '' ||
      picName === '' || picPhoneNumber === ''
    ) {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewOrUpdateSalesOrderApi()
    }
  }

  useEffect(() => {
    fetchGetCarModelListApi()
    fetchGetPlacesListApi()

    return () => {
      fetchGetCarModelListApi()
      fetchGetPlacesListApi()
    }
  }, [dialogAddOrEditSalesOrder]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorMessage(null)
  }, [
    customerName, bpkbName, carModel, inputCarModel,
    chassisNumber, machineNumber, dealerSeller, inputDealerSeller,
    dealerInstallation, inputDealerInstallation, selectedDateAndTime,
    picName, picPhoneNumber
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    {/* DIALOG ADD OR EDIT SALES ORDER */}
    <CustomDialog 
      open={Boolean(dialogAddOrEditSalesOrder)}
      onClose={handleClose}
    >
      {isDialogLoading ?
      <CustomCircularProgress/> :
      <>
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          {type === 'add' && 'Add Sales Order'}
          {type === 'edit' && 'Edit Sales Order'}
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes['errorMessage']}
          >
            {errorMessage}
          </Alert>}

          {/* CUSTOMER NAME */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconAccountBox className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Customer Name' 
              value={customerName}
              onChange={(event) => setCustomerName(event['target']['value'])}
            />
          </div>

          {/* CONTRACT NUMBER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconTextSnippet className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Contract No.' 
              value={contractNumber}
              onChange={(event) => setContractNumber(event['target']['value'])}
            />
          </div>

          {/* BPKB NAME */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconChromeReaderMode className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='BPKB Name' 
              value={bpkbName}
              onChange={(event) => setBpkbName(event['target']['value'])}
            />
          </div>

          {/* CAR MODEL */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconDirectionsCar className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={carModel}
              onChange={(event, newValue) => setCarModel(newValue)}
              inputValue={inputCarModel}
              onInputChange={(event, newInputValue) => setInputCarModel(newInputValue)}
              options={carModelList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Car Model' 
                />
              )}
            />
            <AddButtonSmall 
              className={classes['addSmallButton']}
              onClick={() => onAddButtonSmallIsClicked('car model')}
            />
          </div>

          {/* CHASSIS NUMBER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconGarage className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Chassis No.' 
              value={chassisNumber}
              onChange={(event) => setChassisNumber(event['target']['value'])}
            />
          </div>

          {/* MACHINE NUMBER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconBallot className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Machine No.' 
              value={machineNumber}
              onChange={(event) => setMachineNumber(event['target']['value'])}
            />
          </div>

          {/* DEALER SELLER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconPlace className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={dealerSeller}
              onChange={(event, newValue) => setDealerSeller(newValue)}
              inputValue={inputDealerSeller}
              onInputChange={(event, newInputValue) => setInputDealerSeller(newInputValue)}
              options={dealerList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Dealer Seller' 
                />
              )}
              renderOption={(option) => (
                <div>
                  <Typography
                    variant='subtitle2'
                    className={classes['defaultText']}
                  >
                    {option['name']}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classes['ligtherText']}
                  >
                    {option['address']}
                  </Typography>
                </div>
              )}
            />
            <AddButtonSmall 
              className={classes['addSmallButton']}
              onClick={() => onAddButtonSmallIsClicked('place')}
            />
          </div>

          {/* DEALER INSTALLATION */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconPinDrop className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={dealerInstallation}
              onChange={(event, newValue) => setDealerInstallation(newValue)}
              inputValue={inputDealerInstallation}
              onInputChange={(event, newInputValue) => setInputDealerInstallation(newInputValue)}
              options={dealerList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Dealer Installation' 
                />
              )}
              renderOption={(option) => (
                <div>
                  <Typography
                    variant='subtitle2'
                    className={classes['defaultText']}
                  >
                    {option['name']}
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    className={classes['ligtherText']}
                  >
                    {option['address']}
                  </Typography>
                </div>
              )}
            />
            <AddButtonSmall 
              className={classes['addSmallButton']}
              onClick={() => onAddButtonSmallIsClicked('place')}
            />
          </div>

          {/* DATE AND TIME */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconInsertInvitation className={classes['icon']}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                className={`${classes['textField']} ${classes['datePickerTextField']}`}
                animateYearScrolling
                label='Installation Schedule'
                format='dd/MM/yyyy HH:mm:ss a'
                value={selectedDateAndTime}
                onChange={(date) => setSelectedDateAndTime(date)}
              />
            </MuiPickersUtilsProvider>
          </div>

          {/* PIC NAME */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconContacts className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='PIC Name' 
              value={picName}
              onChange={(event) => setPicName(event['target']['value'])}
            />
          </div>

          {/* PIC PHONE NUMBER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconCall className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='PIC Phone Number' 
              value={picPhoneNumber}
              onChange={(event) => setPicPhoneNumber(event['target']['value'])}
            />
          </div>
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* CANCEL BUTTON */}
          <Button onClick={() => setDialogAddOrEditSalesOrder(false)}>
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>

          {/* OK BUTTON */}
          <Button onClick={() => onSaveButtonIsClicked()}>
            <Typography
              variant='subtitle2'
              className={classes['okText']}
            >
              Save
            </Typography>
          </Button>
        </CustomDialogActions>
      </>}
    </CustomDialog>

    {/* DIALOG ADD CAR MODEL */}
    <DialogAddCarModel
      dialogAddCarModel={dialogAddCarModel}
      setDialogAddCarModel={setDialogAddCarModel}
      setIsParentDialogLoading={setIsDialogLoading}
      setIsParentDialogOpen={setDialogAddOrEditSalesOrder}
    />

    {/* TODO: ADD DIALOG CAR COLOR HERE */}

    {/* DIALOG ADD PLACE */}
    <DialogAddPlace
      dialogAddPlace={dialogAddPlace}
      setDialogAddPlace={setDialogAddPlace}
      setIsParentDialogLoading={setIsDialogLoading}
      setIsParentDialogOpen={setDialogAddOrEditSalesOrder}
    />
    </>
  )
}

export default DialogAddSalesOrder
