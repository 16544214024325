import React from 'react'

// COMPONENTS (SORTED BY ORDER)
import DashboardHeader from './DashboardHeader/DashboardHeader'

// STYLES
import useStyles from './dashboardUseSytles'

const Dashboard = () => {
  const classes = useStyles()

  return (
    <div className={classes['dashboardRoot']}>
      {/* FILTER */}
      <DashboardHeader/>

      {/* CONTENT */}
      <div className={classes['contentCard']}>
      </div>
    </div>
  )
}

export default Dashboard
