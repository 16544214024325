// MATERIAL UI CORES
import Slider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomSlider = withStyles((theme) => ({
  root: {
    color: colors['dodgerblue'],
    '& .PrivateValueLabel-circle-221': {
      height: 26,
      width: 26,
      marginLeft: 3,
      marginTop: 7,
    },
  },
  thumb: {},
  active: {},
  valueLabel: {},
  track: {},
  rail: {
    backgroundColor: colors['lightslategray'],
  },
}))(Slider)

export default CustomSlider