import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    padding: 30,
  },
  noFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  uploadButton: {
    textTransform: 'none',
    border: `1px solid ${colors['dodgerblue']}`,
    backgroundColor: colors['dodgerblue'],
    borderRadius: 4,
    padding: '10px 20px',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: colors['dodgerblue'],
    },
  },
  uploadIcon: {
    marginRight: 10,
  },
  uploadText: {
    color: 'white',
  },
  caption: {
    color: colors['slategray'],
  },
}))

export default useStyles