// FIREBASE
import { getAuth } from 'firebase/auth'

export const putApproveStaffApi = async (inputStaffId) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/staff/approve/${inputStaffId}`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const responseJson = await response.json()
  return responseJson
}