import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Timeline from '@material-ui/lab/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// STYLES
import useStyles from './deviceUpdateUseStyles'

// UTILS
import { 
  getStringDateFromStringDate, 
  getStringClockFromStringDate, 
} from 'utils/formatStringDate'

const DeviceUpdate = () => {
  const classes = useStyles()

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageStockOutContext)

  const [ isDeviceUpdateExpand, setIsDeviceUpdateExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const timelineList = selectedData['length'] > 0 ? selectedData[0]['shipments'] : null
  
  return (
    <div className={classes['deviceUpdateRoot']}>
      {/* DEVICE UPDATE TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['deviceUpdateTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Device Update
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsDeviceUpdateExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isDeviceUpdateExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* TIMELINE */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isDeviceUpdateExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NO UPDATE */}
        {timelineList['length'] === 0 &&
        <Typography
          variant='subtitle2'
          className={classes['timelineTextGray']}
        >
          No Update
        </Typography>}

        {/* TIMELINE SECTION */}
        <Timeline className={classes['timeline']}>
          {timelineList['length'] > 0 &&
          timelineList.map((item, index) => (
            <TimelineItem key={index}>
              {/* TIMELINE OPPOSITE CONTENT */}
              <TimelineOppositeContent className={classes['timelineOpposite']}/>

              {/* TIMELINE SEPARATOR */}
              <TimelineSeparator>
                {/* TIMELINE DOT */}
                <TimelineDot
                  variant='outlined'
                  className={
                    index === timelineList['length'] - 1 ?
                    classes['timelineDotBlue'] :
                    classes['timelineDotGray']
                  }
                />

                {/* TIMELINE CONNECTOR */}
                {index !== timelineList['length'] - 1 &&
                <TimelineConnector
                  className={
                    index === timelineList['length'] - 1 ?
                    classes['timelineConnectorBlue'] :
                    classes['timelineConnectorGray']
                  }
                />}
              </TimelineSeparator>

              {/* TIMELINE CONTENT */}
              <TimelineContent className={classes['timelineContent']}>
                {/* TITLE */}
                <Typography
                  variant='subtitle2'
                  className={
                    index === timelineList['length'] - 1 ?
                    classes['timelineTextBlue'] :
                    `${classes['timelineTextGray']} ${classes['textBold']}`
                  }
                >
                  {`From ${item['from']} to ${item['to']}`}
                </Typography>

                {/* DELIVERY STATUS */}
                <Typography
                  variant='subtitle2'
                  className={
                    index === timelineList['length'] - 1 ?
                    `${classes['timelineTextGray']} ${classes['timelineStatus']} ${classes['timelineStatusDelivery']}` :
                    `${classes['timelineTextGray']} ${classes['timelineStatus']}`
                  }
                >
                  On Delivery
                </Typography>

                {/* COURIER AND DELIVERY ID */}
                <Typography
                  variant='subtitle2'
                  className={classes['timelineTextGray']}
                >
                  {`${item['carrierName']} - ${item['shippingNumber']}`}
                </Typography>

                {/* DELIVERY DATE */}
                <Typography
                  variant='subtitle2'
                  className={classes['timelineTextGray']}
                >
                  {`${getStringDateFromStringDate(item['sentAt'])} ${getStringClockFromStringDate(item['sentAt'])}`}
                </Typography>

                {/* RECEIVED STATUS */}
                {item['receivedBy'] &&
                <Typography
                  variant='subtitle2'
                  className={
                    index === timelineList['length'] - 1 ?
                    `${classes['timelineTextGray']} ${classes['timelineStatus']} ${classes['timelineStatusReceived']} ${classes['timelineStatusReceivedGreen']}` :
                    `${classes['timelineTextGray']} ${classes['timelineStatus']} ${classes['timelineStatusReceived']}`
                  }
                >
                  Received
                </Typography>}

                {/* RECIPIENT NAME */}
                {item['receivedBy'] &&
                <Typography
                  variant='subtitle2'
                  className={classes['timelineTextGray']}
                >
                  {item['receivedBy']}
                </Typography>}

                {/* RECEIVED DATE */}
                {item['receivedBy'] &&
                <Typography
                  variant='subtitle2'
                  className={classes['timelineTextGray']}
                >
                  {`${getStringDateFromStringDate(item['receivedAt'])} ${getStringClockFromStringDate(item['receivedAt'])}`}
                </Typography>}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        {/* DELIVERY IS COMPLETE */}
        {timelineList['length'] === 3 &&
        timelineList[2]['isReceived'] &&
        <Alert
          severity='info'
          className={classes['deliveryCompleteRoot']}
        >
          <Typography
            variant='subtitle2'
            className={classes['timelineTextGray']}
          >
            Delivery is complete.
            It’s time to give the task to the technician.&nbsp;
            <Link 
              className={classes['goToTasksMenu']}
              to='/tasks'
            >
              Go to tasks menu.
            </Link>
          </Typography>
        </Alert>}
      </Collapse>}
    </div>
  )
}

export default DeviceUpdate
