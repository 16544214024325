import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomDropdown from 'components/Custom/CustomDropdown'

// MATERIAL UI CORES
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './dashboardHeaderUseStyles'

const FilterHeader = () => {
  const classes = useStyles()

  const [ places, setPlaces ] = useState(0)
  const [ time, setTime ] = useState(0)

  return (
    <div className={classes['filterHeaderRoot']}>
      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Dashboard
      </Typography>

      {/* GROUP BY PLACES DROPDOWN */}
      <FormControl 
        variant='outlined' 
        className={`${classes['dropdownSelect']} ${classes['groupByPlacesDropdown']}`}
      >
        <NativeSelect
          value={places}
          onChange={(event) => setPlaces(event['target']['value'])}
          label='Group by Places'
          input={<CustomDropdown/>}
        >
          <option value={0}>Group by Places</option>
          <option value={1}>Place 1</option>
          <option value={2}>Place 2</option>
          <option value={3}>Place 3</option>
        </NativeSelect>
      </FormControl>

      {/* TIME DROPDOWN */}
      <FormControl 
        variant='outlined' 
        className={`${classes['dropdownSelect']} ${classes['timeDropdown']}`}
      >
        <NativeSelect
          value={time}
          onChange={(event) => setTime(event['target']['value'])}
          label='Time Filter'
          input={<CustomDropdown/>}
        >
          <option value={0}>Daily</option>
          <option value={1}>Weekly</option>
          <option value={2}>Monthly</option>
        </NativeSelect>
      </FormControl>
    </div>
  )
}

export default FilterHeader
