import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageStaffContext } from 'contexts/PageStaffContext'
import { PageAllContext } from 'contexts/PageAllContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconAccountCircle from '@material-ui/icons/AccountCircle'
import IconCheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import IconEmail from '@material-ui/icons/Email'
import IconEvent from '@material-ui/icons/Event'
import IconPhone from '@material-ui/icons/Phone'

// SERVICES
import { putApproveStaffApi } from 'services/staffs/putApproveStaffApi'
import { formatGetStaffListApi } from 'services/staffs/getStaffListApi'

// STYLES
import useStyles from './mainSectionUseStyles'

const MainSection = () => {
  const classes = useStyles()

  const { 
    selectedRowList,
    tableData,
    changeIsLoading,
    changeTableData,
  } = useContext(PageStaffContext)

  const { changeToast } = useContext(PageAllContext)

  const [ isMainContentExpand, setIsMainContentExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Name',
      content: selectedData[0]['name'] ? selectedData[0]['name'] : '-',
      icon: <IconAccountCircle className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Phone Number',
      content: selectedData[0]['phone'] ? selectedData[0]['phone'] : '-',
      icon: <IconPhone className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Email',
      content: selectedData[0]['email'] ? selectedData[0]['email'] : '-',
      icon: <IconEmail className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Created',
      content: selectedData[0]['formattedCreatedAt'] ? selectedData[0]['formattedCreatedAt'] : '-',
      icon: <IconEvent className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Status',
      content:
        selectedData[0]['isApproved'] ? 
        <Typography
          variant='subtitle2'
          className={classes['approvedText']}
        >
          Approved
        </Typography> :
        <Typography 
          variant='subtitle2'
          className={classes['approveText']}
          onClick={() => onApproveTextIsClicked(selectedData[0])}
        >
          Approve
        </Typography>,
      icon: <IconCheckCircleOutline className={classes['iconForText']}/>,
    },
  ]
  
  const onApproveTextIsClicked = async (inputData) => {
    changeIsLoading(true)

    const response = await putApproveStaffApi(inputData['id'])
    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully approving a staff', 
        severity: 'success',
      })

      const data = await formatGetStaffListApi()
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
  }
  
  return (
    <div className={classes['mainSectionRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}

      {/* MAIN CONTENT TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['mainContentTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Main Section
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsMainContentExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isMainContentExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isMainContentExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NAME, PHONE, EMAIL, AND CREATED DATE */}
        {selectedData['length'] === 1 && data.map((item, index) => (
          <div 
            key={index}
            className={classes['iconAndTextContainer']}
          >
            {/* ICON */}
            {item['icon']}

            <div>
              {/* SUBTITLE */}
              <Typography
                variant='subtitle2'
                className={classes['subtitle']}
              >
                {item['subtitle']}
              </Typography>

              {/* CONTENT */}
              <Typography
                variant='subtitle2'
                className={classes['blackerText']}
              >
                {item['content']}
              </Typography>
            </div>
          </div>
        ))}
      </Collapse>}
    </div>
  )
}

export default MainSection
