import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'

// DATA
import { 
  pageListFullData, 
  pageListWulingData, 
} from './pageListData'

// IMAGES
import IconExpand from 'images/icons/sidebar_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './pageListUseStyles'

const PageList = (props) => {
  const { isSidebarOpen } = props

  const { userRoles } = useContext(CurrentUserContext)

  const classes = useStyles()

  const [ isExpand, setIsExpand ] = useState(null)
  const [ hoveredParent, setHoveredParent ] = useState(null)

  let selectedPageListData = pageListFullData
  if(userRoles.includes('wuling')) selectedPageListData = pageListWulingData
  
  const history = useHistory()
  let location = useLocation()
  location = location['pathname']

  const onParentItemIsClicked = (inputParent, inputIndex) => {
    if(inputParent['type'] === 'single') {
      history.push(`/${parentPathName(inputParent)}`)
    }
    else if(inputParent['type'] === 'collection' && isSidebarOpen) {
      if(!isExpand) {
        setIsExpand(inputIndex)
      }
      else if(isExpand === inputIndex) {
        setIsExpand(null)
      }
      else {
        setIsExpand(inputIndex)
      }
    }
    else if(inputParent['type'] === 'collection' && !isSidebarOpen) {
      history.push(`/${parentPathName(inputParent)}/${inputParent['children'][0].toLowerCase()}`)
    }
  }

  const parentPathName = (inputParent) => {
    return `${inputParent['name'].replaceAll(' ', '-').toLowerCase()}`
  }

  const childrenPathName = (inputChildren) => {
    return `${inputChildren.replaceAll(' ', '-').toLowerCase()}`
  }

  const onChildrenItemIsClicked = (inputParent, inputChildren) => {
    history.push(`/${parentPathName(inputParent)}/${childrenPathName(inputChildren)}`)
  }

  const selectedParentItem = (inputParent) => {
    if(location.split('/')[1] === parentPathName(inputParent)) {
      return true
    }
    return false
  }

  const selectedChildrenItem = (inputParent, inputChildren) => {
    if(location.split('/')[1] === parentPathName(inputParent) &&
    (location.split('/')[2] === childrenPathName(inputChildren))) {
      return true
    }
    return false
  }

  return (
    <div className={classes['pageListContainer']}>
      {selectedPageListData.map((item, index) => (
        <React.Fragment key={index}>
          {/* PARENT */}
          <ListItem
            button
            className={
              selectedParentItem(item) ?
              `${classes['parentItemContainer']} ${classes['selectedParentItem']}` :
              classes['parentItemContainer']
            }
            onClick={() => onParentItemIsClicked(item, index)}
            onMouseEnter={() => setHoveredParent(index)}
            onMouseLeave={() => setHoveredParent(null)}
          >
            {/* ICON */}
            <img 
              src={
                selectedParentItem(item) ?
                item['iconSelected'] :
                item['iconDefault']
              } 
              alt='' 
              className={classes['pageItemIcon']}
            />
            
            {/* NAME */}
            {isSidebarOpen &&
            <Typography
              variant='subtitle2'
              className={
                selectedParentItem(item) ?
                classes['selectedPageItemName'] :
                classes['pageItemName']
              }
            >
              {item['name']}
            </Typography>}

            {/* EXPAND/COLLAPSE ICON */}
            {item['type'] === 'collection' && isSidebarOpen &&
            <img 
              src={IconExpand} 
              alt='' 
              className={
                isExpand === index ?
                classes['iconCollapse'] :
                classes['iconExpand']
              }
            />}

            {/* PARENT AND CHILDREN ON SIDEBAR IS CLOSED */}
            {!isSidebarOpen && hoveredParent === index &&
            <div className={classes['parentAndChildrenOnSidebarIsClosed']}>
              {/* PARENT */}
              {item['type'] === 'single' &&
              <ListItem 
                button
                className={
                  selectedParentItem(item) ?
                  `${classes['parentItemContainer']} ${classes['selectedParentItem']}` :
                  classes['parentItemContainer']
                }
                onClick={() => onParentItemIsClicked(item, index)}
              >
                <Typography
                  variant='subtitle2'
                  className={
                    selectedParentItem(item) ?
                    classes['selectedPageItemName'] :
                    classes['pageItemName']
                  }
                >
                  {item['name']}
                </Typography>
              </ListItem>}

              {/* CHILDREN */}
              {item['children'] &&
              item['children'].map((childrenItem, childrenIndex) => (
                <ListItem
                  key={childrenIndex}
                  button
                  className={
                    selectedParentItem(item) ?
                    `${classes['childrenItemContainer']} ${classes['selectedParentItem']}` :
                    classes['childrenItemContainer']
                  }
                  onClick={() => onChildrenItemIsClicked(item, childrenItem)}
                >
                  {/* CIRCLE */}
                  <div
                    className={
                      selectedChildrenItem(item, childrenItem) ?
                      `${classes['childrenCirlce']} ${classes['childrenCirlceOnSidebarIsClosed']} ${classes['selectedChildrenCirlce']}`:
                      `${classes['childrenCirlce']} ${classes['childrenCirlceOnSidebarIsClosed']}`
                    }
                  />
                  <Typography
                    variant='subtitle2'
                    className={
                      selectedChildrenItem(item, childrenItem) ?
                      `${classes['childrenName']} ${classes['selectedChildrenItem']}` :
                      classes['childrenName']
                    }
                  >
                    {childrenItem}
                  </Typography>
                </ListItem>
              ))}
            </div>}
          </ListItem>

          {/* CHILDREN */}
          <Collapse 
            in={(item['children'] && isExpand === index) && isSidebarOpen} 
            timeout='auto' 
            unmountOnExit
          >
            {item['children'] &&
            item['children'].map((childrenItem, childrenIndex) => (
              <ListItem
                key={childrenIndex}
                button
                className={
                  selectedParentItem(item) ?
                  `${classes['childrenItemContainer']} ${classes['selectedParentItem']}` :
                  classes['childrenItemContainer']
                }
                onClick={() => onChildrenItemIsClicked(item, childrenItem)}
              >
                {/* CIRCLE */}
                <div
                  className={
                    selectedChildrenItem(item, childrenItem) ?
                    `${classes['childrenCirlce']} ${classes['selectedChildrenCirlce']}`:
                    classes['childrenCirlce']
                  }
                />
                <Typography
                  variant='subtitle2'
                  className={
                    selectedChildrenItem(item, childrenItem) ?
                    `${classes['childrenName']} ${classes['selectedChildrenItem']}` :
                    classes['childrenName']
                  }
                >
                  {childrenItem}
                </Typography>
              </ListItem>
            ))}
          </Collapse>
        </React.Fragment>
      ))}
    </div>
  )
}

export default PageList
