import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import DialogAddOrEditAccessory from '../DialogAddOrEditAccessory/DialogAddOrEditAccessory'

// CONTEXTS
import { PageAccessoriesContext } from 'contexts/PageAccessoriesContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './accessoriesHeaderUseStyles'

const AccessoriesHeader = () => {
  const {
    search,
    changeSearch,
  } = useContext(PageAccessoriesContext)
  
  const classes = useStyles()

  const [ dialogAddOrEditAccessory, setDialogAddOrEditAccessory ] = useState(null)

  return (
    <div className={classes['accessoriesHeader']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditAccessory(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Accessories
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* DIALOG ADD ACCESSORIES */}
      <DialogAddOrEditAccessory
        type='add'
        dialogAddOrEditAccessory={dialogAddOrEditAccessory}
        setDialogAddOrEditAccessory={setDialogAddOrEditAccessory}
      />
    </div>
  )
}

export default AccessoriesHeader
