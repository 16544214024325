import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  initialToggle: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  rotateToggle: {
    transform: 'rotate(-180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
}))

export default useStyles