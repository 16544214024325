import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import DialogAddOrEditPlace from '../DialogAddOrEditPlace/DialogAddOrEditPlace'

// CONTEXTS
import { PagePlacesContext } from 'contexts/PagePlacesContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './placesHeaderUseSytles'

const PlacesHeader = () => {
  const classes = useStyles()

  const {
    search,
    changeSearch,
    isRightContentOpen,
    changeIsRightContentOpen,
  } = useContext(PagePlacesContext)

  const [ dialogAddOrEditPlace, setDialogAddOrEditPlace ] = useState(false)

  return (
    <div className={classes['placesHeaderRoot']}> 
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditPlace(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Places
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isRightContentOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isRightContentOpen}
        onClick={() => changeIsRightContentOpen(current => !current)}
      />

      {/* DIALOG ADD PLACE */}
      <DialogAddOrEditPlace
        type='add'
        dialogAddOrEditPlace={dialogAddOrEditPlace} 
        setDialogAddOrEditPlace={setDialogAddOrEditPlace}
      />
    </div>
  )
}

export default PlacesHeader
