// UTILS
import colors from 'utils/colors'

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  addButtonSmallRoot: {
    width: 24,
    height: 24,
    backgroundColor: colors['lightcyanBlue'],
    borderRadius: 2,
    cursor: 'pointer',
  },
  icon: {
    color: colors['dodgerblue'],
  },
}))

export default useStyles