// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewDeviceApi = async (
  inputImei,
  inputSimNumber,
  imputSerialNumber,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/device/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      imei: inputImei,
      simNumber: inputSimNumber,
      snNumber: imputSerialNumber,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}