// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  flyoutMainSectionRoot: {
    padding: 20,
  },
  instructionText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
  mainContentTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
    marginBottom: 15,
  },
  title: {
    color: colors['black'],
    fontWeight: 600,
  },
  iconExpandContainer: {
    width: 36,
    height: 36,
  },
  iconExpand: {
    cursor: 'pointer',
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  iconCollapse: {
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  blackerText: {
    color: colors['black'],
    fontWeight: 400,
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 12,
    color: colors['darkgray'],
    fontWeight: 600,
  },
  iconAndTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconForText: {
    color: colors['darkgray'],
    marginRight: 20,
  },
}))

export default useStyles