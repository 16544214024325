import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageTasksContext } from 'contexts/PageTasksContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers'

// MUI ICONS
import IconAllInbox from '@mui/icons-material/AllInbox'
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconEventAvailable from '@mui/icons-material/EventAvailable'
import IconDescription from '@mui/icons-material/Description'
import IconPinDrop from '@mui/icons-material/PinDrop'

// SERVICES
import { getOnlyStaffListApi } from 'services/staffs/getStaffListApi'
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'
import { formatGetSalesOrderListApi } from 'services/salesOrder/getSalesOrderListApi'
import { getPlacesListApi } from 'services/places/getPlacesListApi'
import { postNewTaskApi } from 'services/tasks/postNewTaskAPi'
import { putUdpateTaskApi } from 'services/tasks/putUdpateTaskApi'
import { getTasksListByTypeApi } from 'services/tasks/getTasksListApi'

// STYLES
import useStyles from './dialogAddTaskOrEditUseStyles'

const DialogAddOrEditTask = (props) => {
  const { 
    type,
    dialogAddOrEditTask, 
    setDialogAddOrEditTask,
  } = props

  const { 
    pageType, 
    changeTableData, 
    changeIsLoading,
  } = useContext(PageTasksContext)

  const { changeToast } = useContext(PageAllContext)
  
  const classes = useStyles()

  const [ selectedStockOutOrSalesOrder, setSelectedStockOutOrSalesOrder ] = useState(null)
  const [ stockOutOrSalesOrderList, setStockOutOrSalesOrderList ] = useState([])
  const [ selectedStaff, setSelectedStaff ] = useState(null)
  const [ staffList, setStaffList ] = useState([])
  const [ startDateTime, setStartDateTime ] = useState(
    (type === 'edit' && dialogAddOrEditTask['row']['startDate']) ? new Date(dialogAddOrEditTask['row']['startDate']) : (type === 'add' ? null : null)
  )
  const [ endDateTime, setEndDateTime ] = useState(
    (type === 'edit' && dialogAddOrEditTask['row']['endDate']) ? new Date(dialogAddOrEditTask['row']['endDate']) : (type === 'add' ? null : null)
  )
  const [ description, setDescription ] = useState(
    type === 'edit' ? dialogAddOrEditTask['row']['description'] : (type === 'add' ? '' : '')
  )
  const [ selectedDealerInstallation, setSelectedDelaerInstallation ] = useState(null)
  const [ placeList, setPlaceList ] = useState([])
  const [ completionDate, setCompletionDate ] = useState(
    (type === 'edit' && dialogAddOrEditTask['row']['completionDate']) ? new Date(dialogAddOrEditTask['row']['completionDate']) : (type === 'add' ? null : null)
  )
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditTask(false)
    }
  }

  const fetchApis = async () => {
    setIsDialogLoading(true)

    const staffListData = await getOnlyStaffListApi()
    if(staffListData['error']) {
      setErrorMessage(`${staffListData['error']}: ${staffListData['message']}`)
    }
    else {
      setStaffList(staffListData)
    }
    
    if(pageType === 'pickup') {
      const stockOutListData = await formatGetStockOutListApi()
      if(stockOutListData['error']) {
        setErrorMessage(`${stockOutListData['error']}: ${stockOutListData['message']}`)
      }
      else {
        setStockOutOrSalesOrderList(stockOutListData)
      }
    }
    else if(pageType === 'installation') {
      const salesOrderListData = await formatGetSalesOrderListApi()
      if(salesOrderListData['error']) {
        setErrorMessage(`${salesOrderListData['error']}: ${salesOrderListData['message']}`)
      }
      else {
        setStockOutOrSalesOrderList(salesOrderListData)
      }
    }
    
    if(type === 'edit') {
      const placeListData = await getPlacesListApi()
      if(placeListData['error']) {
        setErrorMessage(`${placeListData['error']}: ${placeListData['message']}`)
      }
      else {
        setPlaceList(placeListData)
      }
    }

    setIsDialogLoading(false)
  }

  const onSaveButtonIsClicked = async () => {
    if(
      !selectedStockOutOrSalesOrder || !selectedStaff || selectedStaff === '' || 
      !startDateTime || !endDateTime || description === ''
    ) {
      setErrorMessage('Please fill all fields')
    }
    else {
      changeIsLoading(true)

      let referenceType 
      if(pageType === 'pickup') referenceType = 'DELIVERY_ORDER'
      else if(pageType === 'installation') referenceType = 'SALES_ORDER'
  
      let response
      if(type === 'add') {
        response = await postNewTaskApi(
          referenceType, // FREE VALUE
          description,
          selectedStockOutOrSalesOrder['uuid'],
          startDateTime,
          endDateTime,
          referenceType,
          selectedStaff['id'],
        )
      }
      else if(type === 'edit') {
        let referenceInfo = {}
        if(pageType === 'pickup' && selectedDealerInstallation) {
          referenceInfo = { 
            updatePickUpRequest: { 
              salesPointId: selectedDealerInstallation['id'],
            } 
          }
        }
        else if(pageType === 'installation' && selectedDealerInstallation) {
          referenceInfo = { 
            updateInstallationRequest: { 
              installationPlaceId: selectedDealerInstallation['id'],
            } 
          }
        }

        response = await putUdpateTaskApi(
          dialogAddOrEditTask['id'],
          referenceType, // FREE VALUE
          description,
          selectedStockOutOrSalesOrder['uuid'],
          selectedStaff['id'],
          startDateTime,
          endDateTime,
          referenceInfo,
          completionDate,
        )
      }
      // console.log(response)

      if(response['error']) {
        changeToast({
          open: true,
          message: `${response['error']}: ${response['message']}`,
          severity: 'error',
        })
      }
      else {
        changeToast({
          open: true,
          message: `Sucessfully ${type}ing a ${type === 'add' ? 'new' : ''} request`, 
          severity: 'success',
        })

        const data = await getTasksListByTypeApi(pageType)
        if(data['error']) {
          changeToast({
            open: true,
            message: `${data['error']}: ${data['message']}`,
            severity: 'error',
          })
        }
        else {
          changeTableData(data)
        }
      }
      
      changeIsLoading(false)
      setDialogAddOrEditTask(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [
    selectedStockOutOrSalesOrder,
    selectedStaff, 
    startDateTime, 
    endDateTime, 
    description,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchApis()

    return () => fetchApis()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if(
      type === 'edit' && 
      placeList['length'] > 0 && 
      staffList['length'] > 0 && 
      stockOutOrSalesOrderList['length'] > 0
    ) {
      if(dialogAddOrEditTask['row']['assignee']) {
        const tempSelectedStaff = staffList.filter(
          item => dialogAddOrEditTask['row']['assignee']['id'] === item['id']
        )
        setSelectedStaff(tempSelectedStaff[0])
      } 

      const tempSelectedStockOutOrSalesOrder = stockOutOrSalesOrderList.filter(
        item => dialogAddOrEditTask['row']['referenceId'] === item['uuid']
      )
      setSelectedStockOutOrSalesOrder(tempSelectedStockOutOrSalesOrder[0])

      let tempSelectedPlace
      if(pageType === 'pickup') {
        tempSelectedPlace = placeList.filter(
          item => dialogAddOrEditTask['row']['referenceInfo']['salesPoint']['id'] === item['id']
        )
      }
      else if(pageType === 'installation') {
        tempSelectedPlace = placeList.filter(
          item => dialogAddOrEditTask['row']['referenceInfo']['installationPlace']['id'] === item['id']
        )
      }
      setSelectedDelaerInstallation(tempSelectedPlace[0])
    }
  }, [placeList, staffList, stockOutOrSalesOrderList]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditTask)}
      onClose={handleClose}
    >
      {isDialogLoading ?
      <CustomCircularProgress/> :
      <>
      {/* DIALOG TITLE */}
        <CustomDialogTitle>
          {`${type} ${pageType} request`}
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes['errorMessage']}
          >
            {errorMessage}
          </Alert>}

          {/* STOCK OUT/DELIVERY ORDER OR SALES ORDER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconAllInbox className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={selectedStockOutOrSalesOrder}
              onChange={(event, newValue) => setSelectedStockOutOrSalesOrder(newValue)}
              // inputValue={inputCarModel}
              // onInputChange={(event, newInputValue) => setInputCarModel(newInputValue)}
              options={stockOutOrSalesOrderList}
              getOptionLabel={(option) => option['uuid']}
              renderOption={(option) => (
                <div>
                  {/* UUID */}
                  <Typography
                    variant='subtitle2'
                    className={classes['bolderText']}
                  >
                    {`UUID: ${option['uuid']}`}
                  </Typography> 

                  {/* STOCK OUT PROPERTIES */}
                  {pageType === 'pickup' && 
                  <Typography
                    variant='subtitle2'
                    className={classes['lighterText']}
                  >
                    {`Delivery Order Number: ${option['doNumber']}`}
                    <br></br>
                    {`Sales Point: ${option['salesPoint']}`}
                    <br></br>
                    {`Date: ${option['date']}`}
                  </Typography>}

                  {/* SALES ORDER PROPERTIES */}
                  {pageType === 'installation' && 
                  <Typography
                    variant='subtitle2'
                    className={classes['lighterText']}
                  >
                    {`Contract Number: ${option['contractNumber']}`}
                    <br></br>
                    {`Customer Name: ${option['customerName']}`}
                    <br></br>
                    {`Car Model: ${option['carModel']['name']}`}
                  </Typography>}
                </div>
              )}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label={`${pageType === 'pickup' ? 'Delivery Order UUID' : 'Sales Order UUID'}`} 
                />
              )}
            />
          </div>

          {/* TECHNICIAN/STAFF */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconAccountBox className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={selectedStaff}
              onChange={(event, newValue) => setSelectedStaff(newValue)}
              // inputValue={inputCarModel}
              // onInputChange={(event, newInputValue) => setInputCarModel(newInputValue)}
              options={staffList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Technician Name' 
                />
              )}
            />
          </div>

          <div className={classes['iconAndTextFieldContainer']}>
            {/* START DATE AND ITS TIME */}
            <IconInsertInvitation className={classes['icon']}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                className={`${classes['textField']} ${classes['datePickerTextField']}`}
                animateYearScrolling
                label='Start Date'
                format='dd/MM/yyyy HH:mm:ss a'
                value={startDateTime}
                onChange={(date) => setStartDateTime(date)}
              />
            </MuiPickersUtilsProvider>

            {/* END DATE AND ITS TIME */}
            <IconEventAvailable className={classes['icon']}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                className={`${classes['textField']} ${classes['datePickerTextField']}`}
                animateYearScrolling
                label='End Date'
                format='dd/MM/yyyy HH:mm:ss a'
                value={endDateTime}
                onChange={(date) => setEndDateTime(date)}
              />
            </MuiPickersUtilsProvider>
          </div>

          {/* DESCRIPTION */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconDescription className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Description' 
              value={description}
              onChange={(event) => setDescription(event['target']['value'])}
            />
          </div>

          {/* DEALER INSTALLATION/SALES POINT */}
          {type === 'edit' &&
          <div className={classes['iconAndTextFieldContainer']}>
            <IconPinDrop className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={selectedDealerInstallation}
              onChange={(event, newValue) => setSelectedDelaerInstallation(newValue)}
              // inputValue={inputCarModel}
              // onInputChange={(event, newInputValue) => setInputCarModel(newInputValue)}
              options={placeList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label={
                    pageType === 'pickup' ? 
                    'Sales Point' : 
                    (pageType === 'installation' ? 'Dealer Installation' : '')
                  } 
                />
              )}
            />
          </div>}

          {/* COMPLETION DATE AND ITS TIME */}
          {type === 'edit' && <div className={classes['iconAndTextFieldContainer']}>
            <IconInsertInvitation className={classes['icon']}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                className={`${classes['textField']} ${classes['datePickerTextField']}`}
                animateYearScrolling
                label='Completion Date'
                format='dd/MM/yyyy HH:mm:ss a'
                value={completionDate}
                onChange={(date) => setCompletionDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>}
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* CANCEL BUTTON */}
          <Button onClick={() => setDialogAddOrEditTask(false)}>
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>

          {/* OK BUTTON */}
          <Button onClick={() => onSaveButtonIsClicked()}>
            <Typography
              variant='subtitle2'
              className={classes['okText']}
            >
              Save
            </Typography>
          </Button>
        </CustomDialogActions>
      </>}
    </CustomDialog>
  )
}

export default DialogAddOrEditTask
