import React, { useState, useEffect } from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// STYLES
import useStyles from './dialogUpdateStaffUseStyles'

const DialogUpdateStaff = (props) => {
  const { 
    dialogUpdateStaff, 
    setDialogUpdateStaff,
  } = props

  const classes = useStyles()

  const [ staffName, setStaffName ] = useState('')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogUpdateStaff(false)
    }
  }

  const onSaveButtonIsClicked = () => {
    if(staffName === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      setDialogUpdateStaff(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [staffName]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogUpdateStaff)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Edit Staff
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* NAME TEXT FIELD */}
        <CustomTextFieldSmall
          className={classes['textField']}
          label='Name'
          value={staffName}
          onChange={(event) => setStaffName(event['target']['value'])}
        />
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogUpdateStaff(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogUpdateStaff
