import React, { useEffect, useContext } from 'react'

// COMPONENTS
import SubmissionsHeader from './SubmissionsHeader/SubmissionsHeader'
import SubmissionsTable from './SubmissionsTable/SubmissionsTable'

// CONTEXTS
import { PageSubmissionsContext } from 'contexts/PageSubmissionsContext'

// SERVICES
import { formatGetSubmissionInstallationListApi } from 'services/submissions/getSubmissionsListApi'

// STYLES
import useStyles from './submissionsUseStyles'

const Submissions = () => {
  const { changeTableData } = useContext(PageSubmissionsContext)

  const classes = useStyles()

  const fetchFormatGetSubmissionInstallationListApi = async () => {
    const data = await formatGetSubmissionInstallationListApi()
    changeTableData(data)
  }

  useEffect(() => {
    fetchFormatGetSubmissionInstallationListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['submissionsRoot']}>
      {/* HEADER */}
      <SubmissionsHeader/>

      <div className={`${classes['cardsContainer']} ${classes['cardsContainerFull']}`}>
        {/* LEFT CONTENT */}
        <div className={classes['cardLeft']}>
          <SubmissionsTable/>
        </div>
      </div>
    </div>
  )
}

export default Submissions
