import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// IMAGES
import IconClock from 'images/icons/staff_detail_clock.svg'
import IconLocation from 'images/icons/staff_detail_location.svg'
import IconStaffInArrow from 'images/icons/staff_in_arrow.svg'
import IconStaffOutArrow from 'images/icons/staff_out_arrow.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './detailDialogUseStyles'

const DetailDialog = (props) => {
  const { 
    isDetailDialogShown, 
    setIsDetailDialogShown,
    detailObject,
  } = props

  const classes = useStyles()
  
  const [ isDialogLargeShown, setIsDialogLargeShown ] = useState(false)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDetailDialogShown(false)
    }
  }

  return (
    <CustomDialog 
      open={isDetailDialogShown}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Detail
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {detailObject &&
        <div className={classes['detailInfoContainer']}>
          {/* PHOTO PROFILE */}
          <img
            src={detailObject['photo']}
            alt=''
            className={classes['photoProfile']}
            onClick={() => setIsDialogLargeShown(true)}
          />

          {/* DIALOG LARGE PHOTO PROFILE */}
          <Dialog 
            open={isDialogLargeShown}
            onClose={() => setIsDialogLargeShown(false)}
          >
            <img
              src={detailObject['photo']}
              alt=''
              className={classes['photoProfileLarge']}
            />
          </Dialog>

          <div>
            {/* NAME */}
            <Typography
              variant='subtitle1'
              className={classes['name']}
            >
              {detailObject['name']}
            </Typography>

            {/* TIME AND GMT */}
            <div className={classes['iconAndTextContainer']}>
              {/* CLOCK ICON */}
              <img
                src={IconClock}
                alt=''
                className={classes['icon']}
              />

              <div>
                {/* TIME */}
                <Typography
                  variant='subtitle2'
                  className={classes['text']}
                >
                  {detailObject['time']}
                </Typography>

                {/* TIME */}
                <Typography
                  variant='subtitle2'
                  className={classes['text']}
                >
                  {detailObject['gmt']}
                </Typography>
              </div>
            </div>

            {/* ADDRESS */}
            <div className={classes['iconAndTextContainer']}>
              {/* ADDRESS ICON */}
              <img
                src={IconLocation}
                alt=''
                className={classes['icon']}
              />

              {/* ADDRESS TEXT */}
              <Typography
                variant='subtitle2'
                className={classes['text']}
              >
                {detailObject['address']}
              </Typography>
            </div>

            {/* CLOCK TYPE */}
            <div className={classes['iconAndTextContainer']}>
              {/* CLOCK TYPE ICON */}
              <img
                src={detailObject['type'] === 'in' ? IconStaffInArrow : IconStaffOutArrow}
                alt=''
                className={classes['icon']}
              />

              {/* CLOCL TYPE TEXT */}
              <Typography
                variant='subtitle2'
                className={classes['text']}
              >
                {detailObject['type'].toUpperCase()}
              </Typography>
            </div>
          </div>
        </div>}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        <Button onClick={() => setIsDetailDialogShown(false)}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Ok
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DetailDialog
