// UTILS
import colors from 'utils/colors'

const initialMapCenter = {
  lat: -0.7893,
  lng: 113.9213,
}

const mapContainerStyle = ({display}) => {
  return {
    width: '100%',
    height: 280,
    borderRadius: 4,
    marginBottom: 25,
    display: display,
  }
}

const circleOptions = {
  fillColor: colors['coral'],
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: colors['coral'],
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 1,
}

export { 
  initialMapCenter,
  mapContainerStyle,
  circleOptions,
}