import React, { createContext, useState } from 'react'

const PageSignUpContext = createContext()

const PageSignUpContextProvider = (props) => {

  const initialSignUpUserObject = {
    email: null,
    password: null,
    name: null,
    phoneNumber: null,
  }

  const [ signUpUserObject, setSignUpUserObject ] = useState(initialSignUpUserObject)

  const updateSignUpUserObject = (inputObject) => {
    setSignUpUserObject(inputObject)
  }

  return (
    <PageSignUpContext.Provider
      value={{
        signUpUserObject,
        updateSignUpUserObject
      }}
    >
      {props['children']}
    </PageSignUpContext.Provider>
  )
}

export { PageSignUpContextProvider, PageSignUpContext }