import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageDevicesContext } from 'contexts/PageDevicesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MUI ICONS
import IconSimCard from '@mui/icons-material/SimCard'
import IconHorizontalSplit from '@mui/icons-material/HorizontalSplit'

// SERVICES
import { formatGetDevicesListApi } from 'services/devices/getDevicesListApi'
import { postNewDeviceApi } from 'services/devices/postNewDeviceApi'
import { putUpdateDeviceApi } from 'services/devices/putUpdateDeviceApi'

// STYLES
import useStyles from './dialogAddOrEditDeviceUseStyles'

const DialogAddOrEditDevice = (props) => {
  const { 
    type,
    dialogAddOrEditDevice,
    setDialogAddOrEditDevice,
  } = props

  const { 
    changeIsLoading,
    changeTableData,
  } = useContext(PageDevicesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ simNumber, setSimNumber ] = useState(type === 'edit' ? dialogAddOrEditDevice['row']['simNumber'] : (type === 'add' ? '' : ''))
  const [ imei, setImei ] = useState(type === 'edit' ? dialogAddOrEditDevice['row']['imei'] : (type === 'add' ? '' : '')) 
  // const [ serialNumber, setSerialNumber ] = useState(type === 'edit' ? dialogAddOrEditDevice['row']['serialNumber'] : (type === 'add' ? '' : ''))
  const [ errorMessage, setErrorMessage ] = useState(null)

  const fetchPostNewDeviceApi = async () => {
    changeIsLoading(true)

    let response
    if(type === 'add') {
      response = await postNewDeviceApi(
        imei,
        simNumber,
        '000000000000',
      )
    }
    else if(type === 'edit') {
      response = await putUpdateDeviceApi(
        dialogAddOrEditDevice['row']['id'],
        simNumber,
        imei,
      )
    }

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Sucessfully ${type}ing a ${type === 'add' ? 'new' : ''} device`, 
        severity: 'success',
      })

      const data = await formatGetDevicesListApi()
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false) 
    setDialogAddOrEditDevice(false)
  }
  
  const onFinishButtonIsClicked = () => {
    if(simNumber === '' || imei === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewDeviceApi()
    }
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditDevice(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [simNumber, imei]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditDevice)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {`${type} a ${type === 'add' ? 'new' : ''} Device`}
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* SIM NUMBER TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconSimCard className={classes['icon']}/>
          <CustomTextFieldSmall
            className={classes['textField']}
            // disabled={isLoading}
            label='Sim Number'
            value={simNumber}
            onChange={(event) => setSimNumber(event['target']['value'])}
          />
        </div>

        {/* IMEI TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconHorizontalSplit className={classes['icon']}/>
          <CustomTextFieldSmall
            className={classes['textField']}
            // disabled={isLoading}
            label='IMEI'
            value={imei}
            onChange={(event) => setImei(event['target']['value'])}
          />
        </div>

        {/* SERIAL NUMBER FIELD */}
        {/* <CustomTextFieldSmall
          className={classes['textField']}
          // disabled={isLoading}
          label='Serial Number'
          value={serialNumber}
          onChange={(event) => setSerialNumber(event['target']['value'])}
        /> */}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setDialogAddOrEditDevice(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddOrEditDevice
