import React, { useState } from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Pagination from '@material-ui/lab/Pagination'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './dialogDetailUseStyles'

const DialogDetail = (props) => {
  const {
    dialogDetail, 
    setDialogDetail,
  } = props

  const classes = useStyles()
  
  const [ selectedPage, setSelectedPage ] = useState(1)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogDetail(false)
    }
  }
  
  return (
    <CustomDialog 
      open={Boolean(dialogDetail)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Detail
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* SELECTED IMAGE */}
        <img
          src={
            Array.isArray(dialogDetail['value']) ?
            dialogDetail['value'][selectedPage - 1] :
            dialogDetail['value']
          }
          alt=''
          className={classes['selectedImage']}
        />

        {/* PAGINATION */}
        {Array.isArray(dialogDetail['value']) &&
        <div className={classes['paginationContainer']}>
          <Pagination 
            color='primary'
            count={dialogDetail['value']['length']} 
            page={selectedPage} 
            onChange={(event, value) => setSelectedPage(value)} 
          />
        </div>}
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* FINISH BUTTON */}
        <Button onClick={() => setDialogDetail(null)}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Ok
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogDetail
