import React, { useState, useEffect, useContext, useCallback } from 'react'

// CONTEXTS
import { PageAllContext } from 'contexts/PageAllContext'

// CONFIGURATIONS
import { 
  initialMapCenter,
  mapContainerStyle,
  circleOptions,
} from './dialogAddPlaceMapSettings'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomMenu from 'components/Custom/CustomMenu'
import CustomSlider from 'components/Custom/CustomSlider'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// GOOGLE MAPS
import {
  Circle,
  GoogleMap,
} from '@react-google-maps/api'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown'
import IconMap from '@material-ui/icons/Map'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MUI ICONS
import IconAutofpsSelect from '@mui/icons-material/AutofpsSelect'
import IconPlace from '@mui/icons-material/Place'
import IconRadar from '@mui/icons-material/Radar'

// REACT GEOCODE
import Geocode from 'react-geocode'

// SERVICES
import { postNewPlaceApi } from 'services/places/postNewPlaceApi'

// STYLES
import useStyles from './dialogAddPlaceUseStyles'

// USE PLACES AUTOCOMPLETE
import usePlacesAutocomplete from 'use-places-autocomplete'

const DialogAddPlace = (props) => {
  const { 
    dialogAddPlace, 
    setDialogAddPlace,
    setIsParentDialogLoading,
    setIsParentDialogOpen,
  } = props

  const { changeToast } = useContext(PageAllContext)

  const {
    value: address,
    suggestions,
    setValue: setAddress,
    clearSuggestions,
  } = usePlacesAutocomplete()
  
  const classes = useStyles()

  const [ placeName, setPlaceName ] = useState('')
  const [ isAddressOptionShown, setIsAddressOptionShown ] = useState(false)
  const [ radius, setRadius ] = useState(50)
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ isMapShown, setIsMapShown ] = useState(false)
  const [ map, setMap ] = useState(null)
  const [ mapCenter, setMapCenter ] = useState(initialMapCenter)
  const [ circle, setCircle ] = useState(null)
  const [ radiusMenuAnchor, setRadiusMenuAnchor ] = useState(false)
  const [ source, setSource ] = useState(null); // 'input' or 'circle'

  const addressOptions = suggestions['data'].map((suggestion) => {
    const { structured_formatting: { main_text, secondary_text } } = suggestion
    return `${main_text}, ${secondary_text}`
  })

  const minRadius = 50
  const maxRadius = 1500

  const radiusOptions = [ 50, 150, 500, 1500 ]

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddPlace(false)
      setIsParentDialogOpen(true)
    }
  }

  const onFinishButtonIsClicked = async () => {
    if(placeName === '' || address === '') setErrorMessage('Please fill all fields')
    else if(radius < 50 || radius > 1500) setErrorMessage('Radius must be in range of 50-1500 meters')
    else {
      setIsParentDialogLoading(true)

      const response = await postNewPlaceApi(
        placeName,
        address,
        circle['center'].lat(),
        circle['center'].lng(),
        radius,
      )

      if(response['error']) {
        changeToast({
          open: true,
          message: `${response['error']}: ${response['message']}`,
          severity: 'error',
        })
      }
      else {
        changeToast({
          open: true,
          message: `Successfully adding a new place`, 
          severity: 'success',
        })
      }

      setDialogAddPlace(false)
      setIsParentDialogOpen(true)
      setIsParentDialogLoading(false)
    }
  }

  const onRadiusMenuItemIsClicked = (inputValue) => {
    setRadius(inputValue)
    setRadiusMenuAnchor(null)
  }

  const onSuggestionItemClicked = (inputItem, inputIndex) => {
    setAddress(inputItem, false)
    getLocationFromSelectedAddress(inputItem)
    setSource('input')
  }

  const getLocationFromSelectedAddress = (inputSelectedAddress) => {
    Geocode.fromAddress(inputSelectedAddress)
    .then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        setIsMapShown(true)
        setMapCenter({lat, lng})
        clearSuggestions()
      },
      (error) => {
        console.error(error)
      }
    )
  }

  const circleOnLoad = useCallback((circle) => {
    setCircle(circle)
  }, [])

  const circleOnUnMount = useCallback((circle) => {
    setCircle(null)
  }, []) 

  const circleCenterChanged = () => {
    (circle && source === 'circle') && getAddressFromLocation(circle['center'].lat(), circle['center'].lng())
    setSource('circle')
  }
  
  const circleRadiusChanged = () => {
    circle && setRadius(parseInt(circle['radius']))
  }

  const getAddressFromLocation = (inputLatitude, inputLongitude) => {
    Geocode.fromLatLng(inputLatitude, inputLongitude)
    .then(
      (response) => {
        const formattedAddress = response.results[0].formatted_address
        setAddress(formattedAddress, false)
        clearSuggestions()
      },
      (error) => {
        console.error(error)
      }
    )
  }

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onMapUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  useEffect(() => {
    setErrorMessage(null)
  }, [placeName, address, radius])

  useEffect(() => {
    if(map && circle) {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.union(circle.getBounds())
      map.fitBounds(bounds)
      setMap(map)
    }

    return () => {}
  }, [circle && circle['center'].lat(),
      circle && circle['center'].lng(), 
      radius,
      mapCenter,
      address,
  ])  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddPlace)}
      onClose={handleClose}
      className={classes['dialogRoot']}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Add Place
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent className={classes['customDialogContentRoot']}>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* PLACE NAME TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconAutofpsSelect className={classes['icon']}/>
          <CustomTextFieldSmall
            className={classes['textField']}
            // disabled={isLoading}
            label='Place Name'
            value={placeName}
            onChange={(event) => setPlaceName(event['target']['value'])}
          />
        </div>

        {/* ADDRESS TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconPlace className={classes['icon']}/>
          <ClickAwayListener onClickAway={() => setIsAddressOptionShown(false)}>
            <div className={classes['textField']}>
              <CustomTextFieldSmall
                className={classes['textField']}
                label='Address'
                value={address}
                onChange={(event) => setAddress(event['target']['value'])}
                onClick={() => setIsAddressOptionShown(true)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment 
                      position='end' 
                      className={classes['iconMap']}
                      onClick={() => setIsMapShown(current => !current)}
                    >
                      <IconMap/>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </ClickAwayListener>
        </div>

        {/* PLACES SUGGESTIONS */}
        {(isAddressOptionShown && addressOptions['length'] > 0) && 
        <Card className={classes['placesSuggestionsRoot']}>
          {addressOptions.map((item, index) => (
            <ListItem
              key={index}
              className={classes['suggestionItem']}
              onClick={() => onSuggestionItemClicked(item, index)}
            >
              {item}
            </ListItem>
          ))}
        </Card>}

        {/* GOOGLE MAPS */}
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle({display: isMapShown ? 'block' : 'none'})}
            center={mapCenter}
            zoom={5}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
            options={{
              // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
              streetViewControl: false,
              scaleControl: false,
              mapTypeControl: false,
              // zoomControl: false,
            }}
          >
            {address && <Circle
              center={mapCenter}
              radius={radius}
              options={circleOptions}
              onLoad={circleOnLoad}
              onUnmount={circleOnUnMount}
              // TODO: ON CENTER CHANGED SHOULD BE REPLACED BY ON DRAG OR ON DRAG END
              onCenterChanged={circleCenterChanged}
              onRadiusChanged={circleRadiusChanged}
            />}
          </GoogleMap>
        </div>

        {/* RAIDUS TEXT FIELD AND SLIDER */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconRadar className={classes['icon']}/>

          {/* RADIUS TEXT FIELD */}
          <CustomTextFieldSmall
            className={classes['numberTextField']}
            // disabled={isLoading}
            label='Radius'
            value={radius}
            type='number'
            onChange={(event) => 
              event['target']['value'] < 0 ?
              setRadius(0) :
              setRadius(event['target']['value'])
            }
            onClick={(event) => setRadiusMenuAnchor(event['currentTarget'])}
            InputProps={{
              endAdornment: (
                <InputAdornment 
                  position='end' 
                  className={
                    radiusMenuAnchor ?
                    classes['iconRadiusDropUp'] :
                    classes['iconRadiusDropDown']
                  }
                  onClick={(event) => setRadiusMenuAnchor(event['currentTarget'])}
                >
                  <IconArrowDropDown/>
                </InputAdornment>
              ),
            }}
          />

          {/* RADIUS OPTIONS */}
          <CustomMenu
            anchorEl={radiusMenuAnchor}
            keepMounted
            open={Boolean(radiusMenuAnchor)}
            onClose={() => setRadiusMenuAnchor(null)}
            classes={{ paper: classes['customMenu']}}
          >
            {radiusOptions.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => onRadiusMenuItemIsClicked(item)}
              >
                {`${item} meters`}
              </MenuItem>
            ))}
          </CustomMenu>

          {/* RADIUS SLIDER */}
          <CustomSlider
            className={classes['slider']}
            valueLabelDisplay='on'
            value={radius}
            onChange={(event, newValue) => setRadius(newValue)}
            min={minRadius}
            max={maxRadius}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => handleClose()}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddPlace
