// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewPlaceApi = async (
  inputName,
  inputAddress,
  inputLatitude,
  inputLongitude,
  inputRadius,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/place/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: inputName,
      address: inputAddress,
      latitude: parseFloat(inputLatitude),
      longitude: parseFloat(inputLongitude),
      radius: parseFloat(inputRadius),
    }),
  })

  const responseJson = await response.json()
  return responseJson
}