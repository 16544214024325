import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
		display: 'flex',
		flex: 1,
		width: '100vw',
		height: '100vh',
  },
}))

export default useStyles