// UTILS
import colors from 'utils/colors'

export const circleOptions = {
  fillColor: colors['coral'],
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: colors['coral'],
  clickable: false,
  editable: false,
  draggable: false,
  zIndex: 1,
}

export const transparentCircleOptions = {
  fillColor: 'transparent',
  fillOpacity: 0,
  strokeWeight: 0,
  strokeColor: 'transparent',
  clickable: false,
  editable: false,
  draggable: false,
  zIndex: 1,
}