// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  loading: {
    color: colors['dodgerblue'],
  },
  messageText: {
    color: colors['slategray'],
  },
  printPreview: {
    display: 'none',
  },
  itemContainer: {
    width: '5cm',
    height: '3cm',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  qrCodeContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
  },
  qrCodeImage: {
    width: '2cm',
    height: '2cm',
  },
  qrCodeText: {
    flex: 1,
    fontSize: 12,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
}))

export default useStyles