import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'

// CONTEXTS
import { PageSignUpContextProvider } from './contexts/PageSignUpContext'
import { PageAllContext } from './contexts/PageAllContext'

// COMPONENTS
import AuthenticationRoute from './components/AuthenticationRoute/AuthenticationRoute'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Toast from 'components/Toast/Toast'

// PAGES
import SignIn from './pages/SignIn/SignIn'
import SignUp from './pages/SignUp/SignUp'
import PageWithSidebar from './pages/PageWithSidebar/PageWithSidebar'

function App() {
  const { 
    toast, 
    changeToast, 
  } = useContext(PageAllContext)

  const pageList = [
    {
      type: 'authentication',
      exact: true,
      path: '/sign-in',
      children: <SignIn/>,
    },
    {
      type: 'authentication',
      exact: true,
      path: '/sign-up',
      children:
      <PageSignUpContextProvider>
        <SignUp/>
      </PageSignUpContextProvider>,
    },
    {
      type: 'private',
      exact: false,
      path: '/',
      children: <PageWithSidebar/>,
    },
  ]

  return (
    <>
      {/* PAGES */}
      <Router>
        <Redirect from='/' to='/dashboard'/>
        <Switch>
          {pageList.map((item, index) => {
            if(item['type'] === 'authentication') {
              return(
                <AuthenticationRoute
                  key={index}
                  exact={item['exact']}
                  path={item['path']}
                >
                  {item['children']}
                </AuthenticationRoute>
              )
            }
            else if(item['type'] === 'private') {
              return(
                <PrivateRoute 
                  key={index}
                  exact={item['exact']}
                  path={item['path']}
                >
                  {item['children']}
                </PrivateRoute>
              )
            }
            return null
          })}
        </Switch>
      </Router>

      {/* TOAST */}
      {toast &&
      <Toast
        open={toast['open']}
        setToast={changeToast}
        severity={toast['severity']}
        message={toast['message']}
      />}
    </>
  )
}

export default App