import React from 'react'

// IMAGES
import IconToggleRightInfo from 'images/icons/component_toggle_right_info.svg'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// STYLES
import useStyles from './toggleRightInfoUseStyles'

const ToggleRightInfo = (props) => {
  const {
    isRightInfoOpen, 
    onClick,
  } = props

  const classes = useStyles()

  return (
    <IconButton 
      className={
        isRightInfoOpen ?
        `${classes['initialToggle']} ${classes['rotateToggle']}` :
        `${classes['initialToggle']}`
      }
      onClick={onClick}
    >
      <img
        src={IconToggleRightInfo}
        alt=''
      />
    </IconButton>
  )
}

export default ToggleRightInfo
