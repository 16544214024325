import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    '& + .pac-container': {
      zIndex: theme.zIndex.modal + 1
    },
  },
  customDialogContentRoot: {
    position: 'relative',
  },
  errorMessage: {
    marginBottom: 25,
  },
  iconAndTextFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 25,
    width: '100%',
  },
  icon: {
    marginRight: 15,
    color: colors['slategray'],
  },
  textField: {
    width: '100%',
  },
  numberTextField: {
    width: '50%',
    marginRight: 20,
	},
  iconRadiusDropDown: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconRadiusDropUp: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  customMenu: {
    width: 266,
    boxShadow: `0px 10px 10px ${colors['boxShadowDark']}`,
  },
  iconMap: {
    color: colors['slategray'],
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
  },
  placesSuggestionsRoot: {
    transform: 'translate(0px, -25px)',
    width: 'calc(100% - 119px)',
    padding: '10px 0px',
    position: 'absolute',
    zIndex: theme.zIndex.modal + 1,
    marginLeft: 39,
  },
  suggestionItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors['whitesmokeGray'],
    },
  },
  slider: {
    width: '50%',
    marginBottom: -10,
    marginLeft: 20,
    marginTop: 30,
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles