// FIREBASE
import { getAuth } from 'firebase/auth'

export const getAccessoriesListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/list?type=ACCESSORY`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const formatGetAccessoriesListApiForStockOut = async () => {
  const rawAccessoriesList = await getAccessoriesListApi()

  if(rawAccessoriesList['error']) return rawAccessoriesList
  else {
    const output = rawAccessoriesList.map(item => {
      return {
        id: item['id'],
        name: item['name'],
        field: item['name'].toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (match, character) => character.toUpperCase()),
        quantity: 0,
      }
    })
    return output
  }
}