import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

// CLSX
import clsx from 'clsx'

// CUSTOM COMPONENTS
import CustomFormControl from 'components/Custom/CustomFormControl'
import CustomTextField from 'components/Custom/CustomTextField'
import CustomLinearProgress from 'components/Custom/CustomLinearProgress'

// FIREBASE
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth'

// IMAGES
import IconGoogle from 'images/icons/sign_in_google.svg'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MATERIAL UI ICONS
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// STYLES
import useStyles from './rightSectionUseStyles'

const RightSection = () => {
  const TAG = 'SignIn'

  const classes = useStyles()

	const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')
  const [ isPasswordShown, setIsPasswordShown ] = useState(false)
	const [ errorMessage, setErrorMessage ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(false)

	const dataValidation = () => {
		setErrorMessage(null)
		
		if(email === '' || password === '') {
			setErrorMessage('Please fill all fields')
		}
		else {
			signInUser()
		}
	}

	const signInUser = () => {
    setIsLoading(true)

		signInWithEmailAndPassword(getAuth(), email, password)
		.then((userCredential) => {
			const { user } = userCredential
			console.log(`${TAG}, signInUser, user: `, user)
      setErrorMessage(null)
      setIsLoading(false)
		})
		.catch((error) => {
			const { code, message } = error
      console.log(`${TAG}, signInUser, error code: ${code}, error message: ${message}`)
      formatErroMessage(message)
      setIsLoading(false)
		})
	}
  
  const handleGoogleSignIn = () => {
    setIsLoading(true)
    setErrorMessage(null)

    const provider = new GoogleAuthProvider()

    signInWithPopup(getAuth(), provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const { accessToken } = credential
      const { user } = result

      console.log(`${TAG}, handleGoogleSignIn,
        accessToken: ${accessToken},
        user: ${user}`
      )
      setErrorMessage(null)
      setIsLoading(false)
    }).catch((error) => {
      const { code, message, email: errorEmail } = error
      const credential = GoogleAuthProvider.credentialFromError(error)
      
      console.log(`${TAG}, handleGoogleSignIn,
        error code: ${code},
        error message: ${message},
        email: ${errorEmail},
        credentials: ${credential}`
      )
      formatErroMessage(message)
      setIsLoading(false)
    })
  }

  const formatErroMessage = (inputErrorMessage) => {
    const output = `Error: ${inputErrorMessage.split('/')[1].split(')')[0].replaceAll('-', ' ')}`
    setErrorMessage(output)
  }

  const handleClickShowPassword = () => {
    setIsPasswordShown(current => !current)
  }

  useEffect(() => {
		setErrorMessage(null)
	}, [email, password])

  return (
    <Grid 
      item 
      xs={12} md={6} lg={4}
    >
      <div className={classes['rightSection']}>
        <div className={classes['rightContent']}>
          {/* TITLE */}
          <Typography
            variant='h4'
            className={classes['title']}
          >
            Welcome to Lacak.io
          </Typography>

          {/* CAPTION */}
          <Typography
            variant='h6'
            className={classes['caption']}
          >
            New here?&nbsp;
            <Link
              to='/sign-up'
              className={classes['signUpLink']}
            >
              Create an Account
            </Link>
          </Typography>

          {/* LOADING */}
          {isLoading && <CustomLinearProgress className={classes['loading']}/>}

          {/* ERROR MESSAGE */}
          { 
            errorMessage && 
            <Alert 
              variant='filled'
              severity='error' 
              className={classes['errorMessage']}
            >
              {errorMessage}
            </Alert> 
          }

          {/* EMAIL TEXT FIELD */}
          <CustomTextField
            className={classes['emailTextField']}
            disabled={isLoading}
            label='Email'
            variant='outlined'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          {/* PASSWORD TEXT FIELD */}
          <CustomFormControl
            variant='outlined'
            className={clsx(classes['passwordTextField'])}
          >
            <InputLabel htmlFor='outlined-adornment-password'>
              Password
            </InputLabel>
            <OutlinedInput
              id='standard-adornment-password'
              type={isPasswordShown ? 'text' : 'password'}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => handleClickShowPassword()}
                    edge='end'
                  >
                    {isPasswordShown ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={80}
            />
          </CustomFormControl>

          {/* SIGN IN BUTTON */}
          <Button
            className={classes['signInButton']}
            disabled={isLoading}
            onClick={() => dataValidation()}
          >
            <Typography
              variant='subtitle1'
              className={classes['signInText']}
            >
              Sign In
            </Typography>
          </Button>

          {/* OPTION TEXT */}
          <Typography
            variant='subtitle1'
            className={classes['optionText']}
          >
            Or login with social media
          </Typography>

          {/* GOOGLE SIGN IN BUTTON */}
          <Button
            className={classes['googleSignInButton']}
            disabled={isLoading}
            onClick={() => handleGoogleSignIn()}
          >
            <img src={IconGoogle} alt=''/>
            <Typography
              variant='subtitle1'
              className={classes['googleSignInText']}
            >
              Google
            </Typography>
          </Button>
        </div>
      </div>
    </Grid>
  )
}

export default RightSection
