import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  staffRoot: {
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors['whitesmokeGray'],
    padding: 24,
    position: 'relative',
    overflow: 'hidden',
  },
  cardsContainer: {
    position: 'relative',
    paddingRight: 424,
    flex: 1,
    height: '100%',
    // transition: 'padding 0.25s ease-in-out',
  },
  cardsContainerFull: {
    paddingRight: 0,
    // transition: 'padding 0.25s ease-in-out',
  },
  cardLeft: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: `0px 5px 10px ${colors['boxShadow']}`,
  },
  cardRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxShadow: `0px 5px 10px ${colors['boxShadow']}`,
    width: 400,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
      backgroundColor: colors['whitesmokeGray'],
    },
    '&::-webkit-scrollbar-thumb': {
      width: 5,
      height: 5,
      backgroundColor: colors['darkgray'],
    },
    transition: 'all 0.25s ease-in-out',
  },
  cardRightHide: {
    transform: 'translateX(424px)',
    transition: 'all 0.25s ease-in-out',
  },
}))

export default useStyles