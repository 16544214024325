import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    padding: 30,
  },
  fileName: {
    color: colors['slategray'],
    marginLeft: 8,
    marginBottom: 20,
  },
  reuploadButton: {
    textTransform: 'none',
    backgroundColor: 'white',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  reuploadText: {
    color: colors['dodgerblue'],
  },
}))

export default useStyles