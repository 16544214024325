import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageTasksContext } from 'contexts/PageTasksContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MUI ICONS
import IconAssignmentInd from '@mui/icons-material/AssignmentInd'
import IconAdjust from '@mui/icons-material/Adjust'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconEventAvailable from '@mui/icons-material/EventAvailable'
import IconDateRange from '@mui/icons-material/DateRange'
import IconPinDrop from '@mui/icons-material/PinDrop'
import IconReceipt from '@mui/icons-material/Receipt'

// STYLES
import useStyles from './flyoutMainSectionUseStyles'

// UTILS
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

const FlyoutMainSection = () => {
  const { 
    pageType,
    selectedRowList, 
    tableData, 
  } = useContext(PageTasksContext)

  const classes = useStyles()

  const [ isMainContentExpand, setIsMainContentExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  let placeInfo
  if(pageType === 'pickup') {
    placeInfo = selectedData['length'] > 0 && selectedData[0]['referenceInfo']['salesPoint']
  }
  else if(pageType === 'installation') {
    placeInfo = selectedData['length'] > 0 && selectedData[0]['referenceInfo']['installationPlace']
  }

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Technician Name',
      content: selectedData[0]['assignee'] ? selectedData[0]['assignee']['name'] : 'Technician not specified',
      icon: <IconAssignmentInd className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Tasks Status',
      content: `${selectedData[0]['status'][0]}${selectedData[0]['status'].substring(1).toLowerCase()}`,
      icon: <IconAdjust className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Start',
      content: 
      selectedData[0]['formattedStartDate'] ? 
      `${getStringDateFromStringDate(selectedData[0]['formattedStartDate'])} ${getStringClockFromStringDate(selectedData[0]['formattedStartDate'])}`: 
      '-',
      icon: <IconInsertInvitation className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Finish',
      content: 
      selectedData[0]['formattedEndDate'] ? 
      `${getStringDateFromStringDate(selectedData[0]['formattedEndDate'])} ${getStringClockFromStringDate(selectedData[0]['formattedEndDate'])}`: 
      '-',
      icon: <IconEventAvailable className={classes['iconForText']}/>,
    },
    {
      subtitle: pageType === 'pickup' ? 'Sales Point' : 'Dealer Installation',
      content: placeInfo && placeInfo['name'],
      subContent: placeInfo && placeInfo['address'],
      icon: <IconPinDrop className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Creation Date',
      content: 
      selectedData[0]['formattedCreatedAt'] ? 
      `${getStringDateFromStringDate(selectedData[0]['formattedCreatedAt'])} ${getStringClockFromStringDate(selectedData[0]['formattedCreatedAt'])}`: 
      '-',
      icon: <IconDateRange className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Completion Date',
      content: 
      selectedData[0]['formattedCompletionDate'] ? 
      `${getStringDateFromStringDate(selectedData[0]['formattedCompletionDate'])} ${getStringClockFromStringDate(selectedData[0]['formattedCompletionDate'])}`: 
      '-',
      icon: <IconDateRange className={classes['iconForText']}/>,
    },
    {
      subtitle: 'UUID',
      content: selectedData[0]['referenceId'],
      icon: <IconReceipt className={classes['iconForText']}/>,
    },
  ]

  return (
    <div className={classes['flyoutMainSectionRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* MAIN CONTENT TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['mainContentTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Main Section
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsMainContentExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isMainContentExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isMainContentExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NAME, PHONE, EMAIL, AND CREATED DATE */}
        {selectedData && data.map((item, index) => (
          <div 
            key={index}
            className={classes['iconAndTextContainer']}
          >
            {/* ICON */}
            {item['icon']}

            <div>
              {/* SUBTITLE */}
              <Typography
                variant='subtitle2'
                className={classes['subtitle']}
              >
                {item['subtitle']}
              </Typography>

              {/* CONTENT */}
              <Typography
                variant='subtitle2'
                className={classes['blackerText']}
              >
                {item['content']}
              </Typography>

              {/* SUBCONTENT */}
              {item['subContent'] &&
              <Typography
                variant='subtitle2'
                className={classes['lighterText']}
              >
                {item['subContent']}
              </Typography>}
            </div>
          </div>
        ))}
      </Collapse>}
    </div>
  )
}

export default FlyoutMainSection
