import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogAddOrEditAdmin from '../DialogAddOrEditAdmin/DialogAddOrEditAdmin'
import DialogDeleteAdmin from '../DialogDeleteAdmin/DialogDeleteAdmin'

// CONTEXTS
import { PageAdminsContext } from 'contexts/PageAdminsContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './adminsTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

const AdminsTable = () => {
  const { 
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList,
    changeIsRightInfoOpen,
  } = useContext(PageAdminsContext)

  const [ hovered, setHovered ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ dialogAddOrEditAdmin, setDialogAddOrEditAdmin ] = useState(null)
  const [ dialogDeleteAdmin, setDialogDeleteAdmin ] = useState(null)

  const cellClickRef = useRef(null)

  const classes = useStyles()

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'formattedCreatedAt',
      headerName: 'Created',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {`${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}`}
        </Typography>
      ),
    },
    {
      field: 'disabled',
      headerName: 'Status',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value'] ? 'Inactive' : 'Active'}
        </Typography>
      ),
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === hovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditAdmin(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeleteAdmin(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const newData = tableData.filter(item => 
        (item['name'] && item['name'].toLowerCase().includes(search.toLowerCase())) ||
        (item['phoneNumber'] && item['phoneNumber'].toLowerCase().includes(search.toLowerCase())) ||
        (item['email'] && item['email'].toLowerCase().includes(search.toLowerCase())) ||
        (item['formattedCreatedAt'] && 
        `${getStringDateFromStringDate(item['formattedCreatedAt'])} ${getStringClockFromStringDate(item['formattedCreatedAt'])}`
        .toLowerCase().includes(search.toLowerCase())) ||
        (item['disabled'] ? 
        'Inactive'.toLowerCase().includes(search.toLowerCase()) : 
        'Active'.toLowerCase().includes(search.toLowerCase()))
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData}
        columns={columns}
        onRowEnter={(params, event) => setHovered(params['row']['id'])}
        onRowLeave={(params, event) => setHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsRightInfoOpen,
          )
        }
      />

      {/* DIALOG ADD OR EDIT ADMIN */}
      {dialogAddOrEditAdmin &&
      <DialogAddOrEditAdmin
        type='edit'
        dialogAddOrEditAdmin={dialogAddOrEditAdmin}
        setDialogAddOrEditAdmin={setDialogAddOrEditAdmin}
      />}

      {/* DIALOG DELETE ADMIN */}
      {dialogDeleteAdmin &&
      <DialogDeleteAdmin
        dialogDeleteAdmin={dialogDeleteAdmin}
        setDialogDeleteAdmin={setDialogDeleteAdmin}
      />}
    </>
  )
}

export default AdminsTable
