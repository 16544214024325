import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MUI ICONS
import IconSimCard from '@mui/icons-material/SimCard'
import IconHorizontalSplit from '@mui/icons-material/HorizontalSplit'

// SERVICES
import { postNewDeviceApi } from 'services/devices/postNewDeviceApi'

// STYLES
import useStyles from './dialogAddDeviceUseStyles'

const DialogAddDevice = (props) => {
  const { 
    dialogAddDevice,
    setDialogAddDevice,
    setIsParentDialogLoading,
    setIsParentDialogOpen,
  } = props

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ simNumber, setSimNumber ] = useState('')
  const [ imei, setImei ] = useState('') 
  const [ errorMessage, setErrorMessage ] = useState(null)

  const fetchPostNewDeviceApi = async () => {
    setIsParentDialogLoading(true)

    const response = await postNewDeviceApi(
      imei,
      simNumber,
      '000000000000',
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Sucessfully adding a new device`, 
        severity: 'success',
      })
    }

    setDialogAddDevice(false)
    setIsParentDialogLoading(false) 
    setIsParentDialogOpen(true)
  }
  
  const onFinishButtonIsClicked = () => {
    if(simNumber === '' || imei === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewDeviceApi()
    }
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddDevice(false)
      setIsParentDialogOpen(true)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [simNumber, imei]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddDevice)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Add Device
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* SIM NUMBER TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconSimCard className={classes['icon']}/>
          <CustomTextFieldSmall
            className={classes['textField']}
            // disabled={isLoading}
            label='Sim Number'
            value={simNumber}
            onChange={(event) => setSimNumber(event['target']['value'])}
          />
        </div>

        {/* IMEI TEXT FIELD */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconHorizontalSplit className={classes['icon']}/>
          <CustomTextFieldSmall
            className={classes['textField']}
            // disabled={isLoading}
            label='IMEI'
            value={imei}
            onChange={(event) => setImei(event['target']['value'])}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => handleClose()}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={onFinishButtonIsClicked}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddDevice
