import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  filterHeaderRoot: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    marginRight: 'auto',
  },
  dropdownSelect: {
    backgroundColor: colors['whitesmokeGray'],
    borderRadius: 4,
  },
  groupByPlacesDropdown: {
    width: 220,
    marginRight: 15,
  },
  timeDropdown: {
    width: 130,
  },
}))

export default useStyles