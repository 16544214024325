import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const drawerWidth = 256

const useStyles = makeStyles((theme) => ({
  drawer: {
    padding: 0,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 70,
  },
  sidebarContainer: {
    backgroundColor: colors['darkslategray'],
    height: '100vh',
    boxSizing: 'border-box',
    padding: '20px 0px 0px',
  },
}))

export default useStyles