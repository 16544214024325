import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import CarColorCell from './CarColorCell'
import DialogAddOrEditCarModel from '../DialogAddOrEditCarModel/DialogAddOrEditCarModel'
import DialogDeleteCarModel from '../DialogDeleteCarModel/DialogDeleteCarModel'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

// STYLES
import useStyles from './carModelTableUseStyles'

const CarModelTable = () => {
  const { 
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList, 
  } = useContext(PageCarModelContext)
  
  const classes = useStyles()

  const [ pageSize, setPageSize ] = useState(10)
  const [ isHovered, setIsHovered ] = useState(null)
  const [ filteredData, setFilteredData ] = useState([]) 
  const [ dialogAddOrEditCarModel, setDialogAddOrEditCarModel ] = useState(false)
  const [ dialogDeleteCarModel, setDialogDeleteCarModel ] = useState(false)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'name',
      headerName: 'Car Model Name',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'colorName',
      headerName: 'Car Color Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <CarColorCell 
          name={params['row']['colorName']}
          hexCode={params['row']['hex']}
        />
      ),
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === isHovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditCarModel(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeleteCarModel(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const newData = tableData.filter(item =>
        item['name'].toLowerCase().includes(search.toLowerCase()) ||
        item['colorName'].toLowerCase().includes(search.toLowerCase())
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData}
        columns={columns}
        onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
        onRowLeave={(params, event) => setIsHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            null,
          )
        }
      />

      {/* DIALOG ADD OR EDIT CAR MODEL */}
      {dialogAddOrEditCarModel &&
      <DialogAddOrEditCarModel
        type='edit'
        dialogAddOrEditCarModel={dialogAddOrEditCarModel}
        setDialogAddOrEditCarModel={setDialogAddOrEditCarModel}
      />}

      {/* DIALOG DELETE CAR MODEL */}
      <DialogDeleteCarModel
        dialogDeleteCarModel={dialogDeleteCarModel}
        setDialogDeleteCarModel={setDialogDeleteCarModel}
      />
    </>
  )
}

export default CarModelTable
