// FIREBASE
import { getAuth } from 'firebase/auth'

export const getSalesOrderListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const formatGetSalesOrderListApi = async () => {
  const rawListData = await getSalesOrderListApi()

  if(rawListData['error']) return rawListData
  else {
    const output = rawListData.map(item => {
      return {
        ...item,
        formattedInstallationDate: new Date(item['installmentDate']).getTime(),
        formattedCreatedAt: new Date(item['createdAt']).getTime(),
        formattedUpdatedAt: new Date(item['updatedAt']).getTime(),
      }
    })
    return output
  }
}