import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  accessoriesRoot: {
    width: '100%',
    height: '100vh',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors['whitesmokeGray'],
    padding: 24,
  },
  contentCard: {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: `0px 5px 10px ${colors['boxShadow']}`,
  },
}))

export default useStyles