import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    height: 15,
    width: 15,
    color: colors['slategray'],
  },
  defaultText: {
    fontWeight: 400,
    fontSize: 12,
  },
  ligtherText: {
    fontWeight: 400,
    fontSize: 12,
    color: colors['slategray'],
  },
}))

export default useStyles