import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageAdminsContext } from 'contexts/PageAdminsContext'

// IMAGES
import IconExpand from 'images/icons/staff_expand.svg'

// MATERIAL UI CORES
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MUI ICONS
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconCall from '@mui/icons-material/Call'
import IconEmail from '@mui/icons-material/Email'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconAdjust from '@mui/icons-material/Adjust'

// STYLES
import useStyles from './flyoutMainSectionUseStyles'

const FlyoutMainSection = () => {
  const classes = useStyles()

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageAdminsContext)

  const [ isMainContentExpand, setIsMainContentExpand ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Name',
      content: selectedData[0]['name'] ? selectedData[0]['name'] : '-',
      icon: <IconAccountBox className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Phone Number',
      content: selectedData[0]['phoneNumber'] ? selectedData[0]['phoneNumber'] : '-',
      icon: <IconCall className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Email',
      content: selectedData[0]['email'] ? selectedData[0]['email'] : '-',
      icon: <IconEmail className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Created',
      content: selectedData[0]['created'] ? selectedData[0]['created'] : '-',
      icon: <IconInsertInvitation className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Status',
      content: selectedData[0]['status'] ? selectedData[0]['status'] : '-',
      icon: <IconAdjust className={classes['iconForText']}/>,
    },
  ]

  return (
    <div className={classes['flyoutMainSectionRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}

      {/* MAIN CONTENT TITLE CONTAINER */}
      {selectedRowList['length'] === 1 &&
      <div className={classes['mainContentTitleContainer']}>
        {/* TITLE */}
        <Typography
          variant='subtitle1'
          className={classes['title']}
        >
          Main Section
        </Typography>

        {/* ICON EXPAND */}
        <IconButton
          onClick={() => setIsMainContentExpand(current => !current)}
          className={classes['iconExpandContainer']}
        >
          <img 
            src={IconExpand}
            alt=''
            className={
              isMainContentExpand ?
              classes['iconCollapse'] :
              classes['iconExpand']
            }
          />
        </IconButton>
      </div>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      <Collapse
        in={isMainContentExpand} 
        timeout='auto' 
        unmountOnExit
      >
        {/* NAME, PHONE, EMAIL, AND CREATED DATE */}
        {selectedData && data.map((item, index) => (
          <div 
            key={index}
            className={classes['iconAndTextContainer']}
          >
            {/* ICON */}
            {item['icon']}

            <div>
              {/* SUBTITLE */}
              <Typography
                variant='subtitle2'
                className={classes['subtitle']}
              >
                {item['subtitle']}
              </Typography>

              {/* CONTENT */}
              <Typography
                variant='subtitle2'
                className={classes['blackerText']}
              >
                {item['content']}
              </Typography>
            </div>
          </div>
        ))}
      </Collapse>}
    </div>
  )
}

export default FlyoutMainSection
