import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  caption: {
    color: colors['slategray'],
  },
  link: {
    color: colors['dodgerblue'],
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: 60,
  },
  errorMessage: {
    marginTop: 25,
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles