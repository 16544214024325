import React from 'react'

// MATERIAL UI ICONS
import IconAddRounded from '@material-ui/icons/AddRounded'

// STYLES
import useStyles from './addButtonSmallUseStyles'

const AddButtonSmall = (props) => {
  const { 
    className, 
    onClick, 
  } = props

  const classes = useStyles()

  return (
    <div
      className={
        className ? 
        `${classes['addButtonSmallRoot']} ${className}` : 
        classes['addButtonSmallRoot']
      }
      onClick={onClick}
    >
      <IconAddRounded className={classes['icon']}/>
    </div>
  )
}

export default AddButtonSmall
