import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  formRoot: {},
	loading: {
		marginBottom: 25,
	},
  errorMessage: {
    marginBottom: 25,
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: 4,
  },
	textField: {
		width: '100%',
		marginBottom: 25,
	},
  marginBottomTextField: {
    marginBottom: 50,
  },
	phoneNumberTextField: {
		'& input[type=number]': {
			'-moz-appearance': 'textfield'
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0
		}
	},
	button: {
		backgroundColor: colors['dodgerblue'],
		color: 'white',
		textTransform: 'none',
		height: 55,
		width: '100%',
		borderRadius: 4,
		'&:hover': {
			backgroundColor: colors['dodgerblue'],
		},
	},
	textInButton: {
		fontWeight: 600,
	},
}))

export default useStyles