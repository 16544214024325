import React, { createContext, useState } from 'react'

const PageStockOutContext = createContext()

const PageStockOutContextProvider = (props) => {
  // TYPE = BOOLEAN
  const [ isRightInfoOpen, setIsRightInfoOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)

  // TYPE = STRING
  const [ search, setSearch ] = useState('')

  // TYPE = ARRAY
  const [ tableData, setTableData ] = useState([])
  const [ selectedRowList, setSelectedRowList ] = useState([])

  const changeIsRightInfoOpen = (inputState) => {
    setIsRightInfoOpen(inputState)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeSelectedRowList = (inputData) => {
    setSelectedRowList(inputData)
  }

  return (
    <PageStockOutContext.Provider
      value={{
        isRightInfoOpen, changeIsRightInfoOpen,
        isLoading, changeIsLoading,
        search, changeSearch,
        tableData, changeTableData,
        selectedRowList, changeSelectedRowList,
      }}
    >
      {props['children']}
    </PageStockOutContext.Provider>
  )
}

export { PageStockOutContextProvider, PageStockOutContext }