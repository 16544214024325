import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  deviceUpdateRoot: {
    padding: '0px 20px',
  },
  deviceUpdateTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors['whitesmokeGray']}`,
    marginBottom: 15,
  },
  title: {
    color: colors['black'],
    fontWeight: 600,
  },
  iconExpandContainer: {
    width: 36,
    height: 36,
  },
  iconExpand: {
    cursor: 'pointer',
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  iconCollapse: {
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    transition: 'transform 0.2s ease-in-out',
  },
  timeline: {
    margin: 0,
    padding: 0,
  },
  timelineOpposite: {
    flex: 0,
    padding: 0,
    margin: 0,
  },
  timelineDotBlue: {
    padding: 3,
    marginTop: 10,
    border: `4px solid ${colors['dodgerblue']}`,
    borderRadius: 'unset',
  },
  timelineDotGray: {
    marginTop: 10,
    padding: 3,
    border: `4px solid ${colors['slategray']}`,
  },
  timelineConnectorBlue: {
    backgroundColor: 'unset',
    border: `1px dashed ${colors['dodgerblue']}`,
  },
  timelineConnectorGray: {
    backgroundColor: 'unset',
    border: `1px dashed ${colors['slategray']}`,
  },
  timelineContent: {
    flex: 1,
  },
  timelineTextBlue: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  timelineTextGray: {
    color: colors['slategray'],
    fontWeight: 400,
  },
  textBold: {
    fontWeight: 600,
  },
  timelineStatus: {
    position: 'relative',
    paddingLeft: 15,
    '&::before': {
      content: '""',
      height: 7,
      width: 7,
      borderRadius: '50%',
      backgroundColor: `${colors['slategray']}`,
      display: 'inline-block',
      position: 'absolute',
      top: '50%',
      transform: 'translate(-15px, -50%)',
    },
  },
  timelineStatusDelivery: {
    '&::before': {
      backgroundColor: 'orange',
    },
  },
  timelineStatusReceived: {
    borderTop: `1px solid ${colors['whitesmokeGray']}`,
    paddingTop: 10,
    marginTop: 10,
    '&::before': {
      transform: 'translate(-15px, calc(-50% + 5px))',
    },
  },
  timelineStatusReceivedGreen: {
    '&::before': {
      backgroundColor: 'green',
    },
  },
  deliveryCompleteRoot: {
    marginTop: 25,
    marginBottom: 25,
    '& .MuiAlert-icon': {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  },
  goToTasksMenu: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'inherit',
  },
}))

export default useStyles