import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  addIconButton: {
    position: 'fixed',
    bottom: 40,
    right: 40,
  },
}))

export default useStyles