// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewUserApi = async (
  inputDisplayName,
  inputEmail,
  inputPhoneNumber,
  inputPassword,
  inputRoleNameList,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/user/`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      displayName: inputDisplayName,
      email: inputEmail,
      phoneNumber: inputPhoneNumber,
      password: inputPassword,
      roleNames: inputRoleNameList,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}