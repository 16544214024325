// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUdpateTaskApi = async (
  inputId,
  inputLable,
  inputDescription,
  inputReferenceId,
  inputAssigneeId,
  inputStartDate,
  inputEndDate,
  inputReferenceInfo,
  inputCompletionDate,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/task/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: inputId,
      lable: inputLable,
      description: inputDescription,
      referenceId: inputReferenceId,
      assigneeId: inputAssigneeId,
      startDate: inputStartDate,
      endDate: inputEndDate,
      referenceInfo: inputReferenceInfo,
      completionDate: inputCompletionDate,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}