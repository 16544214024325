// MATERIAL UI CORES
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

let customTheme = createTheme({
  typography: {
    fontFamily: [
      // 'Montserrat', 'sans-serif'
      // 'Poppins', 'sans-serif'
      'Open Sans', 'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#179FD8', // DODGER BLUE
    },
  },
})

customTheme = responsiveFontSizes(customTheme)

export default customTheme