// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUpdateAccessoryApi = async (
  inputItemId,
  inputNewName,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/item/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      itemId: inputItemId,
      newName: inputNewName,
      newType: 'ACCESSORY',
    }),
  })

  const responseJson = await response.json()
  return responseJson
}