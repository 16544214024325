// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewTaskApi = async (
  inputLabel,
  inputDescription,
  inputReferenceId,
  inputStartDate,
  inputEndDate,
  inputReferenceType,
  inputAssigneeId,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/task/`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      label: inputLabel,
      description: inputDescription,
      referenceId: inputReferenceId,
      startDate: inputStartDate,
      endDate: inputEndDate,
      referenceType: inputReferenceType,
      assigneeId: inputAssigneeId,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}