import React, {useState, useEffect, useContext, useRef } from 'react'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

// PDF PRINT
import ReactToPrint from 'react-to-print'

// QR CODE
import QRCode from 'qrcode'

// STYLES
import useStyles from './dialogPrintQrCodesUseStyles'

const DialogPrintQrCodes = (props) => {
  const { 
    isDialogPrintQrCodesOpen, 
    setIsDialogPrintQrCodesOpen, 
  } = props

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageStockOutContext)

  const classes = useStyles()

  const [ qrCodeList, setQrCodeList ] = useState([])

  const printPreviewRef = useRef()

  const getPageMargins = () => {
    return `@page { margin: 0 0 0 0 !important; }`
  }

  const pageStyle = `
    @page {
      size: 5cm 3cm;
    }

    @media all {
      .pagebreak {
        display: none;
      }
    }

    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setIsDialogPrintQrCodesOpen(false)
    }
  }

  const generateQrCodeList = async () => {
    const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))
    const qrCodeTextList = selectedData.map(item =>
      `LW-${item['uuid'].toString()}`
    )
    let qrCodeObjectList = []
    for(let item of qrCodeTextList) {
      const qrCodeObject = {
        url: await QRCode.toDataURL(item, { margin: 1 }),
        text: item,
      }
      qrCodeObjectList.push(qrCodeObject)
    }
    setQrCodeList(qrCodeObjectList)
  }

  useEffect(() => {
    generateQrCodeList()

    return () => {}
  }, [selectedRowList]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <CustomDialog 
      open={isDialogPrintQrCodesOpen}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Print
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {qrCodeList['length'] === 0 ?
        // LOADING
        <CircularProgress className={classes['loading']}/> :
        // MESSAGE
        <Typography
          variant='subtitle1'
          className={classes['messageText']}
        >
          The file is ready to be printed
        </Typography>}
      </CustomDialogContent>


      {/* PRINT PREVIEW */}
      <div className={classes['printPreview']}>
        <div ref={printPreviewRef}>
          <style>{getPageMargins()}</style>
          {qrCodeList.map((item, index) => (
            <div
              key={index}
              className={classes['itemContainer']}
            >
              {/* QR CODE */}
              <div className={classes['qrCodeContainer']}>
                <img
                  src={item['url']}
                  alt=''
                  className={classes['qrCodeImage']}
                />
              </div>

              {/* QR CODE TEXT */}
              <Typography
                variant='subtitle1'
                className={classes['qrCodeText']}
              >
                {item['text']}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button 
          className={classes['cancelButton']}
          onClick={() => setIsDialogPrintQrCodesOpen(false)}
        >
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* PRINT BUTTON */}
        <ReactToPrint
          trigger={() =>
            <Button className={classes['okButton']}>
              <Typography
                variant='subtitle2'
                className={classes['okText']}
              >
                Print
              </Typography>
            </Button>
          }
          content={() => printPreviewRef.current}
          pageStyle={pageStyle}
          onAfterPrint={() => setIsDialogPrintQrCodesOpen(false)}
        />
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogPrintQrCodes
