// FIREBASE
import { getAuth } from 'firebase/auth'

export const getUserListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/user/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const formatGetUserListApi = async () => {
  const rawUserList = await getUserListApi()

  if(rawUserList['error']) return rawUserList
  else {
    let output = rawUserList.filter(item => item['roles'].includes('admin'))
    output = output.map(item => {
      return {
        ...item,
        id: item['uid'],
        formattedCreatedAt: new Date(item['createdAt']).getTime(),
      }
    })
    return output
  }
}