import React from 'react'

// CLSX
import clsx from 'clsx'

// MATERIAL UI ICONS
import Check from '@material-ui/icons/Check'

// STYLES
import useCustomSteperLabelStyles from './customStepperLabelUseStyles'

const CustomStepperLabel = (props) => {
  const classes = useCustomSteperLabelStyles()
  
  const { active, completed } = props

  const icons = {
    1: '1',
    2: '2',
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <Check/> : icons[String(props['icon'])]}
    </div>
  )
}

export default CustomStepperLabel
