import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

// CONTEXTS
import { CurrentUserContext } from 'contexts/CurrentUserContext'

const PrivateRoute = (props) => {
  const { currentUser }= useContext(CurrentUserContext)

  return (
    <Route {...props}>
      { currentUser ? props['children'] : <Redirect to='/sign-in'/> }
    </Route>
  )
}

export default PrivateRoute