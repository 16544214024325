// MATERIAL UI CORES
import Input from '@material-ui/core/Input'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomSearchInput = withStyles((theme) => ({
  root: {
    fontSize: 14,
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid ${colors['slategray']}`,
    },
  },
}))(Input)

export default CustomSearchInput