import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageSignUpContext } from 'contexts/PageSignUpContext'

// CUSTOM COMPONENTS
import CustomLinearProgress from 'components/Custom/CustomLinearProgress'
import CustomTextField from 'components/Custom/CustomTextField'

// FIREBASE
import { 
  getAuth, 
  createUserWithEmailAndPassword,
} from 'firebase/auth'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// STYLES
import useStyles from './formTwoUseStyles'

const FormTwo = (props) => {
  const TAG = 'SignUp'

  const { setActiveStep } = props

  const classes = useStyles()

  const { signUpUserObject, updateSignUpUserObject } = useContext(PageSignUpContext)

  const [ name, setName ] = useState('')
  const [ phoneNumber, setPhoneNumber ] = useState('') 
  const [ errorMessage, setErrorMessage ] = useState(null)
	const [ isLoading, setIsLoading ] = useState(false)

  const onButtonIsClicked = () => {
    if(name === '' || phoneNumber === '') {
			setErrorMessage('Please fill all fields')
    }
    else {
      processUserObject()
    }
  }
  
  const processUserObject = () => {
    const newUserObject = { 
      ...signUpUserObject,
      name,
      phoneNumber
    }
    updateSignUpUserObject(newUserObject)
    setActiveStep(current => current + 1)
    handleSignUp()
  }

  const handleSignUp = () => {
    setIsLoading(true)

		createUserWithEmailAndPassword(
      getAuth(),
      signUpUserObject['email'],
      signUpUserObject['password']
    )
		.then((userCredential) => {
			const { user } = userCredential
			console.log(`${TAG}, handleSignUp, user: `, user)
      setErrorMessage(null)
      setIsLoading(false)
		})
		.catch((error) => {
			const { code, message } = error
			console.log(`${TAG}, handleSignUp, error code: ${code}, error message: ${message}`)
      formatErroMessage(message)
      setIsLoading(false)
		})
	}
  
  const formatErroMessage = (inputErrorMessage) => {
    const output = `Error: ${inputErrorMessage.split('/')[1].split(')')[0].replaceAll('-', ' ')}`
    setErrorMessage(output)
  }

  useEffect(() => {
		setErrorMessage(null)
	}, [name, phoneNumber])

  return (
    <div className={classes['formRoot']}>
      {/* LOADING */}
      {isLoading && <CustomLinearProgress className={classes['loading']}/>}

      {/* ERROR MESSAGE */}
      { 
        errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert> 
      }

      {/* NAME TEXT FIELD */}
      <CustomTextField
        className={classes['textField']}
        disabled={isLoading}
        label='Name'
        variant='outlined'
        type='text'
        value={name}
        onChange={(event) => setName(event.target.value)}
      />

      {/* PHONE NUMBER TEXT FIELD */}
      <CustomTextField
        className={`${classes['textField']} ${classes['marginBottomTextField']} ${classes['phoneNumberTextField']}`}
        disabled={isLoading}
        label='Phone Number'
        variant='outlined'
        type='number'
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(event.target.value)}
      />

      {/* BUTTON */}
      <Button
        className={classes['button']}
        disabled={isLoading}
        onClick={onButtonIsClicked}
      >
        <Typography
          variant='subtitle1'
          className={classes['textInButton']}
        >
          Sign Up
        </Typography>
      </Button>
    </div>
  )
}

export default FormTwo