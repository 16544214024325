import React from 'react'

// MATERIAL UI CORE
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './carModelTableUseStyles'

const CarColorCell = (props) => {
  const { carColorText, carColorCircle } = useStyles(props)

  return (
    <Typography
      variant='subtitle2'
      className={`${carColorText} ${carColorCircle}`}
    >
      {props['name']}
    </Typography>
  )
}

export default CarColorCell
