import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  marker: {
    width: 25,
    height: 25,
    backgroundColor: colors['coral'],
    borderRadius: '50%',
    // cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '-12.5px',
    marginTop: '-12.5px',
  },
}))

export default useStyles