import React, { useContext } from 'react'

// CONTEXTS
import { PagePlacesContext } from 'contexts/PagePlacesContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconLabel from '@material-ui/icons/Label'
import IconLocationOn from '@material-ui/icons/LocationOn'

// STYLES
import useStyles from './informationUseStyles'

const Information = () => {
  const classes = useStyles()

  const {
    selectedRowList,
    tableData,
  } = useContext(PagePlacesContext)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const data = selectedData['length'] > 0 && [
    {
      subtitle: 'Place Name',
      content: selectedData[0]['name'],
      icon: <IconLabel className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Address',
      content: selectedData[0]['address'],
      icon: <IconLocationOn className={classes['iconForText']}/>,
    },
    {
      subtitle: 'Radius',
      content: `${parseInt(selectedData[0]['radius'])} meters`,
      icon: <IconLabel className={classes['iconForText']}/>,
    },
  ]

  return (
    <div className={classes['informationRoot']}>
      {/* INSTRUCTION TEXT */}
      {selectedRowList['length'] === 0 &&
      <Typography
        variant='subtitle2'
        className={classes['instructionText']}
      >
        Select an entry from the list
      </Typography>}
      
      {/* TITLE */}
      {selectedRowList['length'] === 1 &&
      <Typography
        variant='subtitle1'
        className={classes['title']}
      >
        Main Section
      </Typography>}

      {/* COUNT */}
      {selectedRowList['length'] > 1 &&
      <Typography
        variant='subtitle2'
        className={classes['blackerText']}
      >
        {`${selectedRowList['length']} record entries chosen`}
      </Typography>}

      {/* SINGLE ITEM */}
      {selectedRowList['length'] === 1 &&
      data && data.map((item, index) => (
        <div 
          key={index}
          className={classes['iconAndTextContainer']}
        >
          {/* ICON */}
          {item['icon']}

          <div>
            {/* SUBTITLE */}
            <Typography
              variant='subtitle2'
              className={classes['subtitle']}
            >
              {item['subtitle']}
            </Typography>

            {/* CONTENT */}
            <Typography
              variant='subtitle2'
              className={classes['blackerText']}
            >
              {item['content']}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Information
