// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  selectedImage: {
    width: '100%',
    marginBottom: 15,
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
}))

export default useStyles