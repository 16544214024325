import React from 'react'

// COMPONENTS
// import LeftSection from './LeftSection/LeftSection'
import RightSection from './RightSection/RightSection'

// MATERIAL UI CORES
import Grid from '@material-ui/core/Grid'

// STYLES
import useStyles from './signUpUseStyles'

const SignUp = () => {
  const classes = useStyles()

  return (
    <div className={classes['root']}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        {/* <LeftSection/> */}
        <RightSection/>
      </Grid>
    </div>
  )
}

export default SignUp
