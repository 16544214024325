import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import DialogAddOrEditShipment from '../DialogAddOrEditShipment/DialogAddOrEditShipment'
import DialogPrintQrCodes from '../DialogPrintQrCodes/DialogPrintQrCodes'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// IMAGES
import IconQrCode from 'images/icons/devices_qr_code.svg'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './stockOutHeaderUseStyles'

const StockOutHeader = () => {
  const classes = useStyles()

  const { 
    isRightInfoOpen,
    changeIsRightInfoOpen,
    search,
    changeSearch,
    selectedRowList,
  } = useContext(PageStockOutContext)

  const [ dialogAddOrEditShipment, setDialogAddOrEditShipment ] = useState(false)
  const [ isDialogPrintQrCodesOpen, setIsDialogPrintQrCodesOpen ] = useState(false)

  const onQrCodeButtonIsClicked = () => {
    setIsDialogPrintQrCodesOpen(true)
  }

  return (
    <div className={classes['stockOutHeaderRoot']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditShipment(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Shipments
      </Typography>

      {/* QR CODE */}
      {selectedRowList['length'] > 0 &&
      <IconButton onClick={onQrCodeButtonIsClicked}>
        <img
          src={IconQrCode}
          alt=''
        />
      </IconButton>}

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isRightInfoOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isRightInfoOpen}
        onClick={() => changeIsRightInfoOpen(current => !current)}
      />

      {/* DIALOG ADD DELIVERY ORDER */}
      <DialogAddOrEditShipment
        type='add'
        dialogAddOrEditShipment={dialogAddOrEditShipment}
        setDialogAddOrEditShipment={setDialogAddOrEditShipment}
      />

      {/* DIALOG PRINT QR CODES */}
      <DialogPrintQrCodes
        isDialogPrintQrCodesOpen={isDialogPrintQrCodesOpen}
        setIsDialogPrintQrCodesOpen={setIsDialogPrintQrCodesOpen}
      />
    </div>
  )
}

export default StockOutHeader
