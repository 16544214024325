import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  uploadFileContainer: {
    border: `1px dashed ${colors['slategray']}`,
    borderWidth: 2,
    borderRadius: 4,
    boxSizing: 'border-box',
  },
}))

export default useStyles