import React, { useEffect, useContext } from 'react'

// COMPONENTS
import NoFile from './NoFile/NoFile'
import WithFile from './WithFile/WithFile'

// CONTEXTS
import { PageDevicesContext } from 'contexts/PageDevicesContext'

// REACT DROPZONE
import { useDropzone } from 'react-dropzone'

// STYLES
import useStyles from './uploadFileUseStyles'

const UploadFile = () => {
  const classes = useStyles()

  const { changeFiles } = useContext(PageDevicesContext)
  
  const { 
    getRootProps, 
    getInputProps, 
    open, 
    acceptedFiles,
  } = useDropzone({
    noClick: true,
    accept: '.xlsx, .xls, .csv',
    maxFiles: 1,
  })

  useEffect(() => {
    changeFiles(acceptedFiles)
  }, [acceptedFiles]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['uploadFileContainer']}>
      <div {...getRootProps({className: 'dropzone'})}>
        {/* DROPZONE */}
        {acceptedFiles['length'] === 0 ?
        <NoFile
          getInputProps={getInputProps}
          open={open}
        /> :
        <WithFile 
          getInputProps={getInputProps}
          acceptedFiles={acceptedFiles}
          open={open}
        />}
      </div>
    </div>
  )
}

export default UploadFile
