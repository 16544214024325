import React, { useState, useEffect, useContext, useCallback } from 'react'

// CONTEXTS
import { PageStaffContext } from 'contexts/PageStaffContext'

// IMAGES
import IconMarker from 'images/icons/places_marker.svg'

// GOOGLE MAPS
import {
  GoogleMap,
  OverlayView,
} from '@react-google-maps/api'

// STYLES
import useStyles from './showMapUseStyles'

const ShowMap = (props) => {
  const { containerStyle } = props

  const classes = useStyles()

  const { 
    selectedRowList,
    tableData,
  } = useContext(PageStaffContext)

  const selectedStaffs = tableData.filter(item => selectedRowList.includes(item['id']))

  const [ map, setMap ] = useState(null)

  const initialCenter = {
    lat: -0.7893,
    lng: 113.9213,
  }

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onMapUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  useEffect(() => {
    if(map) {
      const bounds = new window.google.maps.LatLngBounds()
      selectedStaffs.forEach(item => {
        bounds.extend(new window.google.maps.LatLng(
          item['lastPosition']['latitude'],
          item['lastPosition']['longitude'],
        ))
      })
      map.fitBounds(bounds)
      setMap(map)
    }
  }, [selectedStaffs]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={initialCenter}
      zoom={4}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
      options={{
        // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        // zoomControl: false,
      }}
    >
      {/* MARKERS */}
      {selectedStaffs.map((item, index) => (
        <OverlayView
          key={index}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={{
            lat: item['lastPosition']['latitude'], 
            lng: item['lastPosition']['longitude'],
          }}
        >
          <div className={classes['marker']}>
            <img
              src={IconMarker}
              alt=''
            />
          </div>
        </OverlayView>
      ))}
    </GoogleMap>
  )
}

export default ShowMap
