import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100vw',
		height: '100vh',
		minHeight: 800,
  },
}))

export default useStyles