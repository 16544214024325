const onSelectionModelChange = (
  inputModel,
  inputCellClickRef,
  inputSelectedRowList,
  inputChangeSelectedRowList,
  inputChangeIsRightInfoOpen,
) => {
  if (inputCellClickRef['current']) {
    if (inputModel['length'] > 1) {
      const selectionSet = new Set(inputSelectedRowList)
      const result = inputModel.filter((s) => !selectionSet.has(s))

      inputChangeSelectedRowList(result)
    } else {
      inputChangeSelectedRowList(inputModel)
    }
  } else {
    inputChangeSelectedRowList(inputModel)
  }

  inputChangeIsRightInfoOpen && inputChangeIsRightInfoOpen(true)
  inputCellClickRef.current = null
}

export default onSelectionModelChange