import React from 'react'

// COMPONENTS
import ShowMap from './ShowMap/ShowMap'
import Information from './Information/Information'

const RightInfo = () => {
  const containerStyle = {
    width: '100%',
    height: 400,
    borderRadius: '4px 4px 0px 0px',
  }

  return (
    <>
      <ShowMap containerStyle={containerStyle}/>
      <Information/>
    </>
  )
}

export default RightInfo
