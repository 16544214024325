import React, { useState } from 'react'

// CLSX
import clsx from 'clsx'

// COMPONENTS
import Toggle from './Toggle/Toggle'
import PageList from './PageList/PageList'

// MATERIAL UI CORES
import Container from '@material-ui/core/Container'

// STYLES
import useStyles from './sidebarUseStyles'

const Sidebar = () => {
  const classes = useStyles()

  const [ isSidebarOpen, setIsSidebarOpen ] = useState(true)

  return (
    <Container
      className={clsx(classes['drawer'], {
        [classes['drawerOpen']]: isSidebarOpen,
        [classes['drawerClose']]: !isSidebarOpen,
      })}
      classes={{
        root: clsx({
          [classes['drawerOpen']]: isSidebarOpen,
          [classes['drawerClose']]: !isSidebarOpen,
        }),
      }}
    >
      {/* SIDEBAR CONTAINER */}
      <div className={classes['sidebarContainer']}>
        {/* TOGGLE */}
        <Toggle
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />

        {/* PAGE LIST */}
        <PageList isSidebarOpen={isSidebarOpen}/>
      </div>
    </Container>
  )
}

export default Sidebar
