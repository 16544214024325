import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tableToolbarRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 12,
    borderBottom: `1px solid rgba(224, 224, 224, 1)`,
  },
  toolbarStyles: {
    fontSize: 12,
  },
}))

export default useStyles