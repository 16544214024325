import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import DialogAddOrEditAdmin from '../DialogAddOrEditAdmin/DialogAddOrEditAdmin'

// CONTEXTS
import { PageAdminsContext } from 'contexts/PageAdminsContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './adminsHeaderUseStyles'

const AdminsHeader = () => {
  const { 
    isRightInfoOpen,
    changeIsRightInfoOpen, 
    search,
    changeSearch,
  } = useContext(PageAdminsContext)

  const classes = useStyles()

  const [ dialogAddOrEditAdmin, setDialogAddOrEditAdmin ] = useState(null)

  return (
    <div className={classes['adminsHeaderRoot']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditAdmin(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Admins
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isRightInfoOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isRightInfoOpen}
        onClick={() => changeIsRightInfoOpen(current => !current)}
      />

      {/* DIALOG ADD OR EDIT ADMIN */}
      <DialogAddOrEditAdmin
        type='add'
        dialogAddOrEditAdmin={dialogAddOrEditAdmin}
        setDialogAddOrEditAdmin={setDialogAddOrEditAdmin}
      />
    </div>
  )
}

export default AdminsHeader
