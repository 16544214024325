import React, { createContext, useState } from 'react'

const PageAllContext = createContext()

const PageAllContextProvider = (props) => {
  // TYPE = OBJECT
  const [ toast, setToast ] = useState(null)

  const changeToast = (inputValue) => {
    setToast(inputValue)
  }

  return (
    <PageAllContext.Provider
      value={{
        toast, changeToast,
      }}
    >
      {props['children']}
    </PageAllContext.Provider>
  )
}

export { PageAllContextProvider, PageAllContext }