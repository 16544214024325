import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    marginBottom: 25,
  },
  iconAndTextFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 25,
    width: '100%',
  },
  icon: {
    marginRight: 15,
    color: colors['slategray'],
  },
  menuItem: {
    fontSize: 14,
  },
  textField: {
    width: '100%',
  },
  datePickerTextField: {
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
  textFieldHalf: {
    width: '50%',
    marginRight: 20,
    '&:last-child': {
      marginRight: 0,
    },
  },
  okText: {
    color: colors['dodgerblue'],
    fontWeight: 600,
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles