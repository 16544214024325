import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    color: colors['slategray'],
    height: 56,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
}))

export default useStyles