// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUpdateUserApi = async (
  inputUid,
  inputDisplayName,
  inputEmail,
  inputPhoneNumber,
  inputRoleNames,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/user/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      uid: inputUid,
      displayName: inputDisplayName,
      email: inputEmail,
      phoneNumber: inputPhoneNumber,
      roleNames: inputRoleNames,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}