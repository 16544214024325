// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewStockOutApi = async (
  inputSalesPointId,
  inputDoDate,
  inputDevicesList,
  inputAccessoriesList,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/delivery-order/create`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      doNumber: '123456789',
      salesPointId: inputSalesPointId,
      doDate: inputDoDate,
      devices: inputDevicesList,
      accessories: inputAccessoriesList,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}