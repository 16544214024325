const colors = {
  black: '#1E1E2D', // BLACK
  blackDarker: '#0F0F1B', // DARKER BLACK
  coral: '#FE9330', // ORANGE
  dodgerblue: '#179FD8', // BLUE
  darkgray: '#A7A8BB', // GRAY
  darkslategray: '#3B424D', // DARK GRAY
  lightcyanBlue: '#E2F7FF', // LIGHT BLUE
  lightcyanGreen: '#C9F7F5', // LIGHT GREEN
  lightGray: '#D0D2DA', // LIGHT GRAY
  lightseagreen: '#1BC5BD', // GREEN
  lightslategray: '#8F919F', // LIGHT GRAY
  midnightblue: '#144682', // DARK BLUE
  mistyrose: '#FFE2E5', // LIGHT RED
  slategray: '#6F7687', // DARK GRAY
  tomato: '#F64E60', // RED
  whitesmokeGray: '#F2F3F7', // LIGHT GRAY
  whitesmokeCream: '#F9F3F4', // LIGHT CREAM

  // BOX SHADOW
  boxShadow: 'rgba(145, 158, 171, 0.3)', // LIGHT
  boxShadowDark: 'rgba(145, 158, 171, 0.6)', // DARK

  // DASHBOARD BACKGROUNDS
  lightseagreenBlue: '#3CBEAF', // GREEN BLUE
  cornflowerBlue: '#6993FF', // LIGHT BLUE
}

export default colors