import React from 'react'

// MATERIAL UI
import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridPanel,
} from '@mui/x-data-grid-pro'
import { GlobalStyles } from '@mui/material'
import { withStyles } from '@material-ui/core/styles'

// STYLES
import useStyles from './tableToolbarUseStyles'

export const TableToolbar = () => {
  const classes = useStyles()

  return (
    <div className={classes['tableToolbarRoot']}>
      <GridToolbarColumnsButton className={classes['toolbarStyles']}/>
      <GridToolbarFilterButton className={classes['toolbarStyles']}/>
      <GridToolbarDensitySelector className={classes['toolbarStyles']}/>
      <GridToolbarExport className={classes['toolbarStyles']}/>
    </div>
  )
}

// CUSTOM STYLE FOR POPUP FROM GRIDTOOLBARCOLUMNSBUTTON AND GRIDTOOLBARFILTERBUTTON
export const CustomGridPanel = withStyles((theme) => ({
  root: {
    '& .MuiTypography-root, & .MuiButton-label, & .MuiFormLabel-root, & .MuiInputBase-input': {
      fontSize: 12,
    },
  },
}))(GridPanel)

// CUSTOM STYLE FOR POPUP FROM GRIDTOOLBARDENSITYSELECTOR AND GRIDTOOLBAREXPORT
export const GlobalStyleComponent = () => (
  <GlobalStyles
    styles={{
      '.MuiDataGrid-gridMenuList': {
        '& .MuiMenuItem-root': {
          fontSize: 12,
        },
      },
    }}
  />
)