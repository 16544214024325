// FIREBASE
import { getAuth } from 'firebase/auth'

export const postUploadFileApi = async (inputFormData) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/device/import`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    body: inputFormData,
  })

  const responseJson = await response.json()
  return responseJson
}