// MATERIAL UI CORES
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core/styles'

const CustomDialogActions = withStyles((theme) => ({
  root: {
    padding: '20px 40px',
  },
}))(DialogActions)

export default CustomDialogActions