// FIREBASE
import { getAuth } from 'firebase/auth'

export const putUpdateStockOutApi = async (
  inputId,
  inputSalesPointId,
  inputDoNumber,
  inputDoDate,
  inputDevicesList,
  inputAccessoriesList,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/delivery-order/update`
  
  const response = await fetch(completeUrl, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: inputId,
      salesPointId: inputSalesPointId,
      doNumber: inputDoNumber,
      doDate: inputDoDate,
      devices: inputDevicesList,
      accessories: inputAccessoriesList,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}