import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'

// CONTEXTS
import { CurrentUserContextProvider } from './contexts/CurrentUserContext'
import { PageAllContextProvider } from './contexts/PageAllContext'

// MATERIAL UI CORES
import { ThemeProvider } from '@material-ui/core/styles'

// MATERIAL UI DATA GRIDS
import { LicenseInfo } from '@mui/x-data-grid-pro'

// GOOGLE MAPS
import { LoadScript } from '@react-google-maps/api'

// REACT GEOCODE
import Geocode from 'react-geocode'

// SENTRY
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

// UTILS
import customTheme from './utils/customTheme'

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLocationType('ROOFTOP')

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <CurrentUserContextProvider>
      <PageAllContextProvider>
        <LoadScript 
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          libraries={['drawing', 'places']}
        >
          <App />
        </LoadScript>
      </PageAllContextProvider>
    </CurrentUserContextProvider>
  </ThemeProvider>,
  document.getElementById('root')
)