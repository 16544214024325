import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers'

// MUI ICONS
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'

// SERVICES
import { putUpdateReceiveShipmentApi } from 'services/stocktOut/putUpdateReceiveShipmentApi'
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'

// STYLES
import useStyles from './dialogReceiveUseStyles'

const DialogReceive = (props) => {
  const { 
    dialogReceive, 
    setDialogReceive,
    setIsDialogQuickActionOpen,
  } = props

  const { 
    selectedRowList, 
    tableData, 
    changeTableData,
    changeIsLoading,
  } = useContext(PageStockOutContext)

  const { changeToast } = useContext(PageAllContext)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const classes = useStyles()

  const [ receivedByName, setReceivedByName ] = useState('')
  const [ receivedDate, setReceivedDate ] = useState(new Date())
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogReceive(false)
    }
  }

  const onCancelButtonIsClicked = () => {
    setDialogReceive(false)
    setIsDialogQuickActionOpen(true)
  }

  const fetchPutUpdateReceiveShipmentApi = async () => {
    changeIsLoading(true)

    const shipmentItem = selectedData[0]['shipments'][ selectedData[0]['shipments']['length'] - 1 ]

    const response = await putUpdateReceiveShipmentApi(
      selectedData[0]['id'],
      shipmentItem['id'],
      shipmentItem['shippingNumber'],
      shipmentItem['from'],
      shipmentItem['sentBy'],
      shipmentItem['to'],
      shipmentItem['sentAt'],
      shipmentItem['carrierName'],
      receivedByName,
      receivedDate,
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: 'Successfully adding a new receive item', 
        severity: 'success',
      })

      const data = await formatGetStockOutListApi()
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogReceive(false)
  }

  const onSaveButtonIsClicked = () => {
    if(receivedByName === '' || receivedDate === '' || !receivedDate) {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPutUpdateReceiveShipmentApi()
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [receivedByName, receivedDate]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogReceive)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Receive
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* RECEIVE BY NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconAccountBox className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Recieved by Name' 
            value={receivedByName}
            type='text'
            onChange={(event) => setReceivedByName(event['target']['value'])}
          />
        </div>

        {/* RECEIVE DATE PICKER */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconInsertInvitation className={classes['icon']}/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateTimePicker
              className={`${classes['textField']} ${classes['datePickerTextField']}`}
              animateYearScrolling
              label='Date'
              format='dd/MM/yyyy hh:mm:ss aa'
              value={receivedDate}
              onChange={(date) => setReceivedDate(date)}
            />
          </MuiPickersUtilsProvider>
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => onCancelButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* OK BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogReceive
