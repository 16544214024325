// FIREBASE
import { getAuth } from 'firebase/auth'

export const getTasksListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/task/list`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const getTasksListByTypeApi = async (inputPageType) => {
  const rawListData = await getTasksListApi()

  if(rawListData['error']) return rawListData
  else {
    let output = []
    if(inputPageType === 'pickup') {
      output = rawListData.filter(item => item['referenceType'] === 'DELIVERY_ORDER')
    }
    else if(inputPageType === 'installation') {
      output = rawListData.filter(item => item['referenceType'] === 'SALES_ORDER')
    }

    output = output.map(item => {
      return {
        ...item,
        formattedStartDate: 
          item['startDate'] ? 
          new Date(item['startDate']).getTime() : 
          null,
        formattedEndDate: 
          item['endDate'] ? 
          new Date(item['endDate']).getTime() : 
          null,
        formattedCreatedAt: 
          item['createdAt'] ? 
          new Date(item['createdAt']).getTime() : 
          null,
        formattedCompletionDate: 
          item['completionDate'] ? 
          new Date(item['completionDate']).getTime() : 
          null,
      }
    })
    return output
  }
}