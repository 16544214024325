// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewDeliveryShipmentApi = async (
  inputDeliverOrderId,
  inputShippingNumber,
  inputFrom,
  inputSentBy,
  inputTo,
  inputSentAt,
  inputCarrierName,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/delivery-order/${inputDeliverOrderId}/shipment`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      shippingNumber: inputShippingNumber,
      from: inputFrom,
      sentBy: inputSentBy,
      to: inputTo,
      sentAt: inputSentAt,
      carrierName: inputCarrierName,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}