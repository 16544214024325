import React, { useEffect, useContext } from 'react'

// COMPONENTS
import StaffHeader from './StaffHeader/StaffHeader'
import StaffTable from './StaffTable/StaffTable'
import RightInfo from './RightInfo/RightInfo'

// CONTEXTS
import { PageStaffContext } from 'contexts/PageStaffContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { formatGetStaffListApi } from 'services/staffs/getStaffListApi'

// STYLES
import useStyles from './staffUseStyles'

const Staff = () => {
  const classes = useStyles()

  const { 
    isRightInfoOpen,
    isLoading,
    changeIsLoading,
    changeTableData,
  } = useContext(PageStaffContext)

  const { changeToast } = useContext(PageAllContext)

  const fetchFormatGetStaffListApi = async () => {
    changeIsLoading(true)
    const data = await formatGetStaffListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }
    changeIsLoading(false)
  }

  useEffect(() => {
    fetchFormatGetStaffListApi()

    return () => fetchFormatGetStaffListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['staffRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* HEADER */}
        <StaffHeader/>

        <div
          className={
            isRightInfoOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <StaffTable/>
          </div>

          {/* RIGHT CONTENT */}
          <div
            className={
              isRightInfoOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <RightInfo/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Staff
