// MATERIAL UI CORES
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 4,
  },
  colorPrimary: {},
  bar: {
    backgroundColor: colors['dodgerblue'],
  },
}))(LinearProgress)

export default CustomLinearProgress
