import React, { createContext, useState } from 'react'

const PageDevicesContext = createContext()

const PageDevicesContextProvider = (props) => {
  // TYPE = STRING
  const [ search, setSearch ] = useState('')
  
  // TYPE = BOOLEAN
  const [ isHistoryOpen, setIsHistoryOpen ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  
  // TYPE = ARRAY
  const [ tableData, setTableData ] = useState([])
  const [ selectedRowList, setSelectedRowList ] = useState([])
  const [ files, setFiles ] = useState(null)

  // TYPE = OBJECT
  const [ toast, setToast ] = useState(null)

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeFiles = (inputFiles) => {
    setFiles(inputFiles)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeIsHistoryOpen = (inputState) => {
    setIsHistoryOpen(inputState)
  }

  const changeSelectedRowList = (inputData) => {
    setSelectedRowList(inputData)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  const changeToast = (inputValue) => {
    setToast(inputValue)
  }

  return (
    <PageDevicesContext.Provider
      value={{
        search, changeSearch,
        files, changeFiles,
        tableData, changeTableData,
        isHistoryOpen, changeIsHistoryOpen,
        selectedRowList, changeSelectedRowList,
        isLoading, changeIsLoading,
        toast, changeToast,
      }}
    >
      {props['children']}
    </PageDevicesContext.Provider>
  )
}

export { PageDevicesContextProvider, PageDevicesContext }