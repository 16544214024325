import React from 'react'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './withFileUseStyles'

const WithFile = (props) => {
  const {
    getInputProps,
    acceptedFiles,
    open,
  } = props

  const classes = useStyles()

  return (
    <div className={classes['dropzoneContainer']}>
      {/* INPUT */}
      <input {...getInputProps()}/>

      {/* FILE NAME */}
      <Typography
        variant='subtitle1'
        className={classes['fileName']}
      >
        File name: {acceptedFiles[0]['path']}
      </Typography>

      {/* UPLOAD FILE BUTTON */}
      <Button 
        className={classes['reuploadButton']}
        onClick={open}
      >
        <Typography
          variant='subtitle2'
          className={classes['reuploadText']}
        >
          Reupload
        </Typography>
      </Button>
    </div>
  )
}

export default WithFile
