import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import DialogAddOrEditTask from '../DialogAddOrEditTask/DialogAddOrEditTask'

// CONTEXTS
import { PageTasksContext } from 'contexts/PageTasksContext'

// MATERIAL UI CORES
import Typography from '@material-ui/core/Typography'

// STYLES
import useStyles from './tasksHeaderUseStyles'

const TasksHeader = () => {
  const { 
    pageType, 
    search, 
    changeSearch, 
    isRightInfoOpen, 
    changeIsRightInfoOpen,
  } = useContext(PageTasksContext)

  const classes = useStyles()

  const [ dialogAddOrEditTask, setDialogAddOrEditTask ] = useState(null)

  return (
    <div className={classes['tasksHeaderRoot']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setDialogAddOrEditTask(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        {`${pageType} request`}
      </Typography>

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isRightInfoOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isRightInfoOpen}
        onClick={() => changeIsRightInfoOpen(current => !current)}
      />

      {/* DIALOG ADD OR EDIT TASKS */}
      <DialogAddOrEditTask
        type='add'
        dialogAddOrEditTask={dialogAddOrEditTask}
        setDialogAddOrEditTask={setDialogAddOrEditTask}
      />
    </div>
  )
}

export default TasksHeader
