import React, { useEffect, useContext } from 'react'

// COMPONENTS (SORTED BY ORDER)
import AccessoriesHeader from './AccessoriesHeader/AccessoriesHeader'
import AccessoriesTable from './AccessoriesTable/AccessoriesTable'

// CONTEXTS
import { PageAccessoriesContext } from 'contexts/PageAccessoriesContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { getAccessoriesListApi } from 'services/accessories/getAccessoriesListApi'

// STYLES
import useStyles from './accessoriesUseStyles'

const Accessories = () => {
  const {
    isLoading,
    changeIsLoading,
    changeTableData,
  } = useContext(PageAccessoriesContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchGetAccessoriesListApi = async () => {
    changeIsLoading(true)
    const data = await getAccessoriesListApi()
    // console.log(data)
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }
    changeIsLoading(false)
  }
  
  useEffect(() => {
    fetchGetAccessoriesListApi()

    return () => fetchGetAccessoriesListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['accessoriesRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* HEADER */}
        <AccessoriesHeader/>

        <div className={classes['contentCard']}>
          {/* TABLE */}
          <AccessoriesTable/>
        </div>
      </>}

    </div>
  )
}

export default Accessories
