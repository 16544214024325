import React, { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AddButtonSmall from 'components/AddButtonSmall/AddButtonSmall'
import DialogAddPlace from 'components/CrudDialog/DialogAddPlace/DialogAddPlace'
import DialogAddDevice from 'components/CrudDialog/DialogAddDevice/DialogAddDevice'
// import UploadFile from './UploadFile'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// DATE
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'

// MATERIAL UI PICKERS
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers'

// MUI ICONS
import IconInsertInvitation from '@mui/icons-material/InsertInvitation'
import IconPlace from '@mui/icons-material/Place'
import IconHorizontalSplit from '@mui/icons-material/HorizontalSplit'
import IconLabel from '@mui/icons-material/Label'

// SERVICES
import { getPlacesListApi } from 'services/places/getPlacesListApi'
import { getDevicesListApi } from 'services/devices/getDevicesListApi'
import { postNewStockOutApi } from 'services/stocktOut/postNewStockOutApi'
import { formatGetStockOutListApi } from 'services/stocktOut/getStockOutListApi'
import { putUpdateStockOutApi } from 'services/stocktOut/putUpdateStockOutApi'

// STYLES
import useStyles from './dialogAddOrEditShipmentUseStyles'

const DialogAddOrEditShipment = (props) => {
  const { 
    type,
    dialogAddOrEditShipment, 
    setDialogAddOrEditShipment,
  } = props

  const { 
    tableData,
    changeIsLoading, 
    changeTableData,
  } = useContext(PageStockOutContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const initialSocketList =
  tableData['length'] > 0 &&
  tableData[0]['formattedSockets'].map(item => {
    return {
      ...item,
      itemId: item['id'],
      quantity: 0,
    }
  })

  const [ activeStep, setActiveStep ] = useState(0)
  const [ selectedDate, setSelectedDate ] = useState(
    type === 'edit' ? dialogAddOrEditShipment['row']['doDate'] : (type === 'add' ? new Date() : new Date())
  )
  const [ salesPointList, setSalesPointList ] = useState([])
  const [ selectedSalesPoint, setSelectedSalesPoint ] = useState(null)
  const [ imeiList, setImeiList ] = useState([])
  const [ selectedImeiList, setSelectedImeiList ] = useState([])
  // const [ selectedFiles, setSelectedFiles ] = useState(null)
  const [ socketList, setSocketList ] = useState(initialSocketList)
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)
  const [ dialogAddPlace, setDialogAddPlace ] = useState(null)
  const [ dialogAddDevice, setDialogAddDevice ] = useState(null)

  const stepperList = [
    'Basic Info',
    'Accessories',
  ]

  const fetchGetPlacesListApi = async () => {
    setIsDialogLoading(true)

    const data = await getPlacesListApi()
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      setSalesPointList(data)
    }

    setIsDialogLoading(false)
  }

  const fetchGetDevicesListApi = async () => {
    setIsDialogLoading(true)

    const data = await getDevicesListApi()
    // console.log(data)
    if(data['error']) {
      setErrorMessage(data['error'])
    }
    else {
      const newData = data.map(item => {
        return {
          stockId: item['id'],
          quantity: 1,
          imei: item['deviceInfo']['imei'],
        }
      })
      setImeiList(newData)
    }

    setIsDialogLoading(false)
  }

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditShipment(false)
    }
  }

  const fetchPostNewStockOutApi = async () => {
    changeIsLoading(true)

    let response
    if(type === 'add') {
      response = await postNewStockOutApi(
        selectedSalesPoint['id'],
        selectedDate,
        selectedImeiList,
        socketList,
      )
    }
    else if(type === 'edit') {
      response = await putUpdateStockOutApi(
        dialogAddOrEditShipment['id'],
        selectedSalesPoint['id'],
        dialogAddOrEditShipment['row']['doNumber'],
        selectedDate,
        selectedImeiList,
        socketList,
      )
    }

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully ${type}ing a ${type === 'add' ? 'new' : ''} shipment item`, 
        severity: 'success',
      })

      const data = await formatGetStockOutListApi()
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogAddOrEditShipment(false)
  }

  const onSaveButtonIsClicked = () => {
    if(activeStep === 0) {
      if(
        selectedDate === '' || !selectedDate ||
        selectedSalesPoint === '' || !selectedSalesPoint || selectedImeiList['length'] === 0
      ) {
        setErrorMessage('Please fill all fields')
      }
      else {
        setActiveStep(current => current + 1)
      }
    }
    else if(activeStep === 1) {
      let showErorrMessage = false
      
      socketList.forEach(item => {
        if(item['quantity'] === '' || Number.isNaN(item['quantity'])) {
          showErorrMessage = true
        }
      })
      
      if(showErorrMessage) {
        setErrorMessage('Please fill all fields')
      }
      else {
        fetchPostNewStockOutApi()
      }
    }
  }

  const onSocketTextFieldChange = (inputValue, inputIndex) => {
    let newSocketList = [...socketList]
    
    inputValue < 0 ?
    newSocketList[inputIndex]['quantity'] = 0 :
    newSocketList[inputIndex]['quantity'] = parseInt(inputValue)
    
    setSocketList(newSocketList)
  }

  const onAddButtonSmallIsClicked = (inputState) => {
    if(inputState === 'sales point') setDialogAddPlace(true)
    else if(inputState === 'imei') setDialogAddDevice(true)
    setDialogAddOrEditShipment(false)
  }

  useEffect(() => {
    fetchGetPlacesListApi()
    fetchGetDevicesListApi()
    
    return () => {
      fetchGetPlacesListApi()
      fetchGetDevicesListApi()
    }
  }, [dialogAddOrEditShipment]) // eslint-disable-line react-hooks/exhaustive-deps  

  useEffect(() => {
    setErrorMessage(null)
  }, [
    selectedDate,
    selectedSalesPoint,
    selectedImeiList,
    socketList,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(
      type === 'edit' && 
      salesPointList['length'] > 0 && 
      imeiList['length'] > 0 && 
      socketList['length'] > 0
    ) {
      const tempSelectedSalesPoint = salesPointList.filter(
        item => dialogAddOrEditShipment['row']['salesPoint']['placeId'] === item['id']
      )
      setSelectedSalesPoint(tempSelectedSalesPoint[0])

      let tempSelectedImeiList = []
      for(let i = 0; i < imeiList['length']; i++) {
        const imeiListItem = imeiList[i]
        for(let j = 0; j < dialogAddOrEditShipment['row']['devices']['imei']['length']; j++) {
          const imeiDialogItem = dialogAddOrEditShipment['row']['devices']['imei'][j]
          if(imeiListItem['imei'] === imeiDialogItem) {
            let count = 0
            for(let k = 0; k < tempSelectedImeiList['length']; k++) {
              const tempSelectedImeiListItem = tempSelectedImeiList[k]
              if(tempSelectedImeiListItem['imei'] === imeiDialogItem) count++
            }
            if(count === 0) tempSelectedImeiList.push(imeiListItem)
          }
        }
      }
      setSelectedImeiList(tempSelectedImeiList)

      let tempSocketList = []
      for(let i = 0; i < socketList['length']; i++) {
        const socketListItem = socketList[i]
        for(let j = 0; j < dialogAddOrEditShipment['row']['formattedSockets']['length']; j++) {
          const formattedSocketItem = dialogAddOrEditShipment['row']['formattedSockets'][j]
          if(socketListItem['id'] === formattedSocketItem['id']) {
            tempSocketList.push({
              ...formattedSocketItem,
              itemId: formattedSocketItem['id'],
            })
          }
        }
      }
      setSocketList(tempSocketList)
    }
  }, [salesPointList, imeiList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    {/* DIALOG ADD SHIPMENT */}
    <CustomDialog 
      open={Boolean(dialogAddOrEditShipment)}
      onClose={handleClose}
    >
      {isDialogLoading ?
      <CustomCircularProgress/> :
      <>
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          {`${type} Shipment`}
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* STEPPER */}
          <Stepper 
            activeStep={activeStep} 
            alternativeLabel
          >
            {stepperList.map((item, index) => (
              <Step key={index}>
                <StepLabel>{item}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes['errorMessage']}
          >
            {errorMessage}
          </Alert>}

          {/* DATE PICKER */}
          {activeStep === 0 &&
          <div className={classes['iconAndTextFieldContainer']}>
            <IconInsertInvitation className={classes['icon']}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={`${classes['textField']} ${classes['datePickerTextField']}`}
                animateYearScrolling
                label='Date'
                format='dd/MM/yyyy'
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
              />
            </MuiPickersUtilsProvider>
          </div>}

          {/* SALES POINT AUTOCOMPLETE*/}
          {activeStep === 0 &&
          <div className={classes['iconAndTextFieldContainer']}>
            <IconPlace className={classes['icon']}/>
            <Autocomplete
              className={classes['textField']}
              value={selectedSalesPoint}
              onChange={(event, newValue) => setSelectedSalesPoint(newValue)}
              options={salesPointList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Sales Point'
                />
              )}
            />
            <AddButtonSmall 
              className={classes['addSmallButton']}
              onClick={() => onAddButtonSmallIsClicked('sales point')}
            />
          </div>}

          {/* IMEI AUTOCOMPLETE */}
          {activeStep === 0 &&
          <div className={classes['iconAndTextFieldContainer']}>
            <IconHorizontalSplit className={classes['icon']}/>
            <Autocomplete
              className={`${classes['textField']} ${classes['autocompleteChip']}`}
              value={selectedImeiList}
              onChange={(event, newValue) => setSelectedImeiList(newValue)}
              multiple
              limitTags={3}
              options={imeiList}
              getOptionLabel={(option) => option['imei']}
              renderInput={(params) => (
                <CustomTextFieldSmall
                  {...params}
                  variant='standard'
                  label='IMEI'
                />
              )}
            />
            <AddButtonSmall 
              className={classes['addSmallButton']}
              onClick={() => onAddButtonSmallIsClicked('imei')}
            />
          </div>}

          {/* UPLOAD FILE */}
          {/* {activeStep === 0 &&
          <UploadFile setFiles={setSelectedFiles}/>} */}

          {/* ALL SOCKETS */}
          {activeStep === 1 &&
          initialSocketList.map((item, index) => (
            <div 
              key={index}
              className={classes['iconAndTextFieldContainer']}
            >
              <IconLabel className={classes['icon']}/>
              <CustomTextFieldSmall 
                className={classes['textField']}
                label={item['name']} 
                value={socketList[index]['quantity']}
                type='number'
                onChange={(event) => onSocketTextFieldChange(event['target']['value'], index)}
              />
            </div>
          ))}
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* PREVIOUS BUTTON */}
          {activeStep === 1 &&
          <Button 
            className={`${classes['cancelButton']} ${classes['previousButton']}`}
            onClick={() => setActiveStep(current => current - 1)}
          >
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Prev
            </Typography>
          </Button>}

          {/* CANCEL BUTTON */}
          <Button 
            className={classes['cancelButton']}
            onClick={() => setDialogAddOrEditShipment(false)}
          >
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>

          {/* OK BUTTON */}
          <Button 
            className={classes['okButton']}
            onClick={() => onSaveButtonIsClicked()}
          >
            <Typography
              variant='subtitle2'
              className={classes['okText']}
            >
              {activeStep === 0 && 'Next'}
              {activeStep === 1 && 'Save'}
            </Typography>
          </Button>
        </CustomDialogActions>
      </>}
    </CustomDialog>

    {/* DIALOG ADD PLACE */}
    <DialogAddPlace
      dialogAddPlace={dialogAddPlace}
      setDialogAddPlace={setDialogAddPlace}
      setIsParentDialogLoading={setIsDialogLoading}
      setIsParentDialogOpen={setDialogAddOrEditShipment}
    />

    {/* DIALOG ADD DEVICE */}
    <DialogAddDevice
      dialogAddDevice={dialogAddDevice}
      setDialogAddDevice={setDialogAddDevice}
      setIsParentDialogLoading={setIsDialogLoading}
      setIsParentDialogOpen={setDialogAddOrEditShipment}
    />
    </>
  )
}

export default DialogAddOrEditShipment
