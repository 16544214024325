import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  logoAndToogleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    marginBottom: 20,
    position: 'relative',
  },
  companyLogo: {
    height: 30,
    cursor: 'pointer',
  },
  iconToggleSidebar: {
    position: 'absolute',
    left: 25,
    cursor: 'pointer',
    marginRight: 7.5,
    transform: 'rotateY(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconToogleSidebarOnClose: {
    position: 'absolute',
    left: 25,
    cursor: 'pointer',
    marginRight: 17.5,
    transform: 'rotateY(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  clientName: {
    color: 'white',
    margin: '30px 0px 15px 25px',
    fontWeight: 600,
  },
}))

export default useStyles