// FIREBASE
import { getAuth } from 'firebase/auth'

export const getSubmissionInstallationListApi = async () => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/dashboard/submission/installation`
  
  const response = await fetch(completeUrl, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })

  const responseJson = await response.json()
  return responseJson
}

export const formatGetSubmissionInstallationListApi = async () => {
  const rawListData = await getSubmissionInstallationListApi()
  if(rawListData['error']) return rawListData
  else {
    const output = rawListData.map(item => {
      return {
        ...item,
        id: item['submissionId'],
        formattedCreationDate: new Date(item['creationDate']).getTime(),
        formattedTaskStart: new Date(item['taskStart']).getTime(),
        formattedTaskFinish: new Date(item['taskFinish']).getTime(),
        formattedTaskCompletionDate: item['taskCompletionDate'] && new Date(item['taskCompletionDate']).getTime(),
      }
    })
    return output
  }
}