import React, { useState, useEffect, useContext, useRef } from 'react'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// COMPONENTS
import DialogAddOreditDevice from '../DialogAddOrEditDevice/DialogAddOrEditDevice'
import DialogDeleteDevice from '../DialogDeleteDevice/DialogDeleteDevice'

// CONTEXTS
import { PageDevicesContext } from 'contexts/PageDevicesContext'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './devicesTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'

const Table = () => {
  const {
    search,
    tableData,
    selectedRowList,
    changeSelectedRowList,
    changeIsHistoryOpen,
  } = useContext(PageDevicesContext)

  const classes = useStyles()
  
  const [ isHovered, setIsHovered ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([])
  const [ dialogAddOrEditDevice, setDialogAddOrEditDevice ] = useState(null)
  const [ dialogDelete, setDialogDelete ] = useState(null)

  const cellClickRef = useRef(null)

  const columns = [
    {
      field: 'simNumber',
      headerName: 'Sim Number',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'imei',
      headerName: 'IMEI',
      flex: 1,
      minWidth: 150,
    },
    // {
    //   field: 'serialNumber',
    //   headerName: 'Serial Number',
    //   flex: 1,
    //   minWidth: 150,
    // },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === isHovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditDevice(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDelete(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (search !== '') {
      const newData = tableData.filter(item => 
        // item['serialNumber'].toString().includes(search) ||
        item['imei'].toString().includes(search) ||
        item['simNumber'].toString().includes(search)
      )
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData}
        columns={columns}
        onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
        onRowLeave={(params, event) => setIsHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsHistoryOpen,
          )
        }
      />

      {/* DIALOG EDIT */}
      {dialogAddOrEditDevice &&
      <DialogAddOreditDevice
        type='edit'
        dialogAddOrEditDevice={dialogAddOrEditDevice}
        setDialogAddOrEditDevice={setDialogAddOrEditDevice}
      />}

      {/* DIALOG DELETE */}
      {dialogDelete &&
      <DialogDeleteDevice
        dialogDelete={dialogDelete}
        setDialogDelete={setDialogDelete}
      />}
    </>
  )
}

export default Table
