import React, { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import DialogAddOrEditTask from '../DialogAddOrEditTask/DialogAddOrEditTask'
import DialogDeleteTask from '../DialogDeleteTask/DialogDeleteTask'

// CONTEXTS
import { PageTasksContext } from 'contexts/PageTasksContext'

// CUSTOM COMPONENTS
import CustomDataGrid from 'components/Custom/CustomDataGrid'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI ICONS
import IconDelete from '@material-ui/icons/Delete'
import IconPencil from '@material-ui/icons/Create'

// STYLES
import useStyles from './tasksTableUseStyles'

// UTILS
import onSelectionModelChange from 'utils/selectionTable'
import {
  getStringDateFromStringDate,
  getStringClockFromStringDate,
} from 'utils/formatStringDate'

const TasksTable = () => {
  const { 
    pageType,
    selectedRowList,
    changeSelectedRowList,
    tableData, 
    changeIsRightInfoOpen,
    search,
  } = useContext(PageTasksContext)

  const classes = useStyles()

  const [ hovered, setHovered ] = useState(null)
  const [ pageSize, setPageSize ] = useState(10)
  const [ filteredData, setFilteredData ] = useState([]) 
  const [ dialogAddOrEditTask, setDialogAddOrEditTask ] = useState(null)
  const [ dialogDeleteTask, setDialogDeleteTask ] = useState(null)

  const cellClickRef = useRef(null)
  
  const columns = [
    {
      field: 'assignee',
      headerName: 'Technician Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        params['value'] ?
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value']['name']}
        </Typography> :
        <Typography
          variant='subtitle2'
          className={classes['ligtherText']}
        >
          Technician not specified
        </Typography>
      ),
      sortComparator: (v1, v2) => v1['name'].localeCompare(v2['name']),
    },
    {
      field: 'status',
      headerName: 'Task Status',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        let statusClassName
        if(params['value'].toLowerCase() === 'unassigned') {
          statusClassName = `${classes['statusText']} ${classes['statusTextUnassigned']}`
        }
        else if(params['value'].toLowerCase() === 'assigned') {
          statusClassName = `${classes['statusText']} ${classes['statusTextAssigned']}`
        }
        else if(params['value'].toLowerCase() === 'completed') {
          statusClassName = `${classes['statusText']} ${classes['statusTextCompleted']}`
        }
        else if(params['value'].toLowerCase() === 'failed') {
          statusClassName = `${classes['statusText']} ${classes['statusTextFailed']}`
        }

        return (
          <Typography
            variant='subtitle2'
            className={statusClassName}
          >
            {params['value'][0].toUpperCase() + params['value'].substring(1).toLowerCase()}
          </Typography>
        )
      },
    },
    {
      field: 'formattedStartDate',
      headerName: 'Start',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value'] ?
          `${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}` :
          ''}
        </Typography>
      ),
    },
    {
      field: 'formattedEndDate',
      headerName: 'Finish',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value'] ?
          `${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}` :
          ''}
        </Typography>
      ),
    },
    {
      field: 'referenceInfo',
      headerName: pageType === 'pickup' ? 
      'Sales Point' : 
      (pageType === 'installation' ? 'Dealer Installation' : ''),
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        let placeInfo
        if(pageType === 'pickup') {
          placeInfo = params['value']['salesPoint']
        }
        else if(pageType === 'installation') {
          placeInfo = params['value']['installationPlace']
        }

        return(
          <div>
            <Typography
              variant='subtitle2'
              className={classes['defaultText']}
            >
              {placeInfo && placeInfo['name']}
            </Typography>
            <Typography
              variant='subtitle2'
              className={classes['ligtherText']}
            >
              {placeInfo && placeInfo['address']}
            </Typography>
          </div>
        )
      },
      sortComparator: (v1, v2) => 
      pageType === 'pickup' ? 
      v1['salesPoint']['name'].localeCompare(v2['salesPoint']['name']) : 
      (pageType === 'installation' ? 
      v1['installationPlace']['name'].localeCompare(v2['installationPlace']['name']) : 
      null),
    },
    {
      field: 'formattedCreatedAt',
      headerName: 'Creation Date',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value'] ?
          `${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}` :
          ''}
        </Typography>
      ),
    },
    {
      field: 'formattedCompletionDate',
      headerName: 'Completion Date',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Typography
          variant='subtitle2'
          className={classes['defaultText']}
        >
          {params['value'] ?
          `${getStringDateFromStringDate(params['value'])} ${getStringClockFromStringDate(params['value'])}` :
          ''}
        </Typography>
      ),
    },
    {
      field: 'referenceId',
      headerName: 'UUID',
      flex: 1,
      minWidth: 300,
    },
    {
      field: '',
      headerName: '',
      width: 75,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        params['id'] === hovered &&
        <div className={classes['actionContainer']}>
          <IconButton onClick={() => setDialogAddOrEditTask(params)}>
            <IconPencil className={classes['actionIcon']}/>
          </IconButton>
          <IconButton onClick={() => setDialogDeleteTask(params)}>
            <IconDelete className={classes['actionIcon']}/>
          </IconButton>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if(search !== '') {
      const newData = tableData.filter(item => {
        let placeInfo
        if(pageType === 'pickup') {
          placeInfo = item['referenceInfo']['salesPoint']
        }
        else if(pageType === 'installation') {
          placeInfo = item['referenceInfo']['installationPlace']
        }

        if(
          (item['assignee'] && item['assignee']['name'].toString().toLowerCase().includes(search.toLowerCase())) ||
          (item['status'] && item['status'].toString().toLowerCase().includes(search.toLowerCase())) ||
          (item['formattedStartDate'] &&
          `${getStringDateFromStringDate(item['formattedStartDate'])} ${getStringClockFromStringDate(item['formattedStartDate'])}`
          .toLowerCase().includes(search.toLowerCase())) ||
          (item['formattedEndDate'] &&
          `${getStringDateFromStringDate(item['formattedEndDate'])} ${getStringClockFromStringDate(item['formattedEndDate'])}`
          .toLowerCase().includes(search.toLowerCase())) ||
          (placeInfo && placeInfo['name'].toString().toLowerCase().includes(search.toLowerCase())) ||
          (placeInfo && placeInfo['address'].toString().toLowerCase().includes(search.toLowerCase())) ||
          (item['formattedCreatedAt'] &&
          `${getStringDateFromStringDate(item['formattedCreatedAt'])} ${getStringClockFromStringDate(item['formattedCreatedAt'])}`
          .toLowerCase().includes(search.toLowerCase())) ||
          (item['formattedCompletionDate'] &&
          `${getStringDateFromStringDate(item['formattedCompletionDate'])} ${getStringClockFromStringDate(item['formattedCompletionDate'])}`
          .toLowerCase().includes(search.toLowerCase())) ||
          (item['referenceId'] && item['referenceId'].toLowerCase().includes(search.toLowerCase()))
        ) return item
      })
      setFilteredData(newData)
    }
  }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {/* DATA GRID */}
      <CustomDataGrid 
        rows={search === '' ? tableData : filteredData} 
        columns={columns}
        onRowEnter={(params, event) => setHovered(params['row']['id'])}
        onRowLeave={(params, event) => setHovered(null)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        selectionModel={selectedRowList}
        onCellClick={() => cellClickRef['current'] = true}
        onSelectionModelChange={(model) =>
          onSelectionModelChange(
            model, 
            cellClickRef, 
            selectedRowList, 
            changeSelectedRowList,
            changeIsRightInfoOpen,
          )
        }
      />

      {/* DIALOG ADD OR EDIT TASKS */}
      {dialogAddOrEditTask &&
      <DialogAddOrEditTask
        type='edit'
        dialogAddOrEditTask={dialogAddOrEditTask}
        setDialogAddOrEditTask={setDialogAddOrEditTask}
      />}

      {/* DIALOG DELETE TASK */}
      <DialogDeleteTask
        dialogDeleteTask={dialogDeleteTask}
        setDialogDeleteTask={setDialogDeleteTask}
      />
    </>
  )
}

export default TasksTable
