import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  itemListContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 500,
    marginBottom: 20,
    cursor: 'pointer',
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.25s ease-in-out',
    '&:hover': {
      transform: 'translate(15px, 0px)',
      transition: 'transform 0.25s ease-in-out',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    width: 35,
    height: 35,
    marginRight: 15,
  },
  itemTitle: {
    fontWeight: 600,
    lineHeight: 1,
  },
  itemCaption: {
    color: colors['slategray'],
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles