// FIREBASE
import { getAuth } from 'firebase/auth'

export const deleteCarModelApi = async (inputId) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/car-model/${inputId}`
  
  const response = await fetch(completeUrl, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  if(response['status'] === 204) {
    return response
  }
  else {
    const responseJson = await response.json()
    return responseJson
  }
}