import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

// MUI ICONS
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconFormatColorFill from '@mui/icons-material/FormatColorFill'
import IconColorLens from '@mui/icons-material/ColorLens'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SERVICES
import { postNewCarModelApi } from 'services/carModel/postNewCarModelApi'

// STYLES
import useStyles from './dialogAddCarModelUseStyles'

const DialogAddCarModel = (props) => {
  const { 
    dialogAddCarModel, 
    setDialogAddCarModel, 
    setIsParentDialogLoading,
    setIsParentDialogOpen,
  } = props

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ carModelName, setCarModelName ] = useState('')
  const [ carColorName, setCarColorName ] = useState('')
  const [ colorCode, setColorCode ] = useState('#000000')
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddCarModel(false)
      setIsParentDialogOpen(true)
    }
  }

  const fetchPostNewCarModelApi = async () => {
    setIsParentDialogLoading(true)

    const response = await postNewCarModelApi(
      carModelName,
      { 
        name: carColorName, 
        hex: colorCode, 
      },
    )

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully adding a new car model item`, 
        severity: 'success',
      })
    }

    setDialogAddCarModel(false)
    setIsParentDialogOpen(true)
    setIsParentDialogLoading(false)
  }

  const onSaveButtonIsClicked = () => {
    if(carModelName === '' || carColorName === '' || colorCode === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewCarModelApi()
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [carModelName], carColorName, colorCode) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddCarModel)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Add a New Car Model
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* CAR MODEL NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconDirectionsCar className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Car Model Name' 
            value={carModelName}
            type='text'
            onChange={(event) => setCarModelName(event['target']['value'])}
          />
        </div>

        {/* CAR COLOR NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconFormatColorFill className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Car Color Name' 
            value={carColorName}
            type='text'
            onChange={(event) => setCarColorName(event['target']['value'])}
          />
        </div>

        {/* COLOR PICKER */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconColorLens className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Color Code' 
            value={colorCode}
            type='text'
            onChange={(event) => setColorCode(event['target']['value'])}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CustomTextFieldSmall 
                    className={classes['colorPicker']}
                    value={colorCode}
                    type='color'
                    onChange={(event) => setColorCode(event['target']['value'])}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => handleClose()}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddCarModel
