// FIREBASE
import { getAuth } from 'firebase/auth'

export const postNewSalesOrderApi = async (
  inputSellerPlaceId,
  inputInstallationPlaceId,
  inputContractNumber,
  inputMachineNumber,
  inputCustomerName,
  inputBpkbCustomerName,
  inputCarModelId,
  inputChassisNumber,
  inputAdminName,
  inputInstallationDate,
  inputPicName,
  inputPicPhoneNumber,
) => {
  const token = await getAuth().currentUser.getIdToken(false)

  const baseUrl = process.env.REACT_APP_WULING_API_BASE_URL
  const completeUrl = `${baseUrl}/stock/sales-order/`
  
  const response = await fetch(completeUrl, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sellerPlaceId: inputSellerPlaceId,
      installationPlaceId: inputInstallationPlaceId,
      contractNumber: inputContractNumber,
      machineNumber: inputMachineNumber,
      customerName: inputCustomerName,
      bpkbCustomerName: inputBpkbCustomerName,
      carModelId: inputCarModelId,
      chassisNumber: inputChassisNumber,
      adminName: inputAdminName,
      installmentDate: inputInstallationDate,
      picName: inputPicName,
      picPhoneNumber: inputPicPhoneNumber,
    }),
  })

  const responseJson = await response.json()
  return responseJson
}