import React, { createContext, useState } from 'react'

const PageCarModelContext = createContext()

const PageCarModelContextProvider = (props) => {
  // TYPE = STRING
  const [ search, setSearch ] = useState('')

  // BOOLEAN
  const [ isLoading, setIsLoading ] = useState(false)

  // TYPE = ARRAY
  const [ tableData, setTableData ] = useState([])
  const [ selectedRowList, setSelectedRowList ] = useState([])

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeSelectedRowList = (inputRowList) => {
    setSelectedRowList(inputRowList)
  }

  return (
    <PageCarModelContext.Provider
      value={{
        search, changeSearch,
        isLoading, changeIsLoading,
        tableData, changeTableData,
        selectedRowList, changeSelectedRowList,
      }}
    >
      {props['children']}
    </PageCarModelContext.Provider>
  )
}

export { PageCarModelContextProvider, PageCarModelContext }