// COMPONENTS
import { 
  TableToolbar, 
  CustomGridPanel,
  GlobalStyleComponent,
 } from 'components/TableToolbar/TableToolbar'

// MATERIAL UI
import { DataGridPro } from '@mui/x-data-grid-pro'
import { withStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const CustomDataGrid = withStyles((theme) => ({
  root: {
    // ROOT
    height: '100%',
    border: 'none',
    fontSize: 12,
    
    // SCROLL BAR
    '& .MuiDataGrid-window': {
      '&::-webkit-scrollbar': {
        width: 5,
        height: 5,
        // backgroundColor: 'transparent',
        backgroundColor: colors['whitesmokeGray'],
      },
      '&::-webkit-scrollbar-thumb': {
        width: 5,
        height: 5,
        // backgroundColor: 'transparent',
        backgroundColor: colors['darkgray'],
      }
    },

    // CHECKED
    '& .Mui-checked': {
      color: colors['dodgerblue'],
    },
    
    // HEADER
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-sortIcon': {
      color: colors['dodgerblue'],
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600,
      fontSize: 12,
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },

    // ROW
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors['whitesmokeGray'],
      },
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: `${colors['dodgerblue']}20`,
      '&:hover': {
        backgroundColor: colors['whitesmokeGray'],
      },
    },

    // CELL
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },

    // PAGINATION
    '& .MuiTablePagination-caption': {
      fontSize: 12,
    },
    '& .MuiTablePagination-select': {
      fontSize: 12,
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
  },
}))(DataGridPro)

// CUSTOM STYLE FOR POPUP FROM PAGINATION
const paginationSelectStyles = {
  root: {
    '& .MuiMenuItem-root': {
      fontSize: 12,
    },
  },
}

const NewCustomDataGrid = withStyles(paginationSelectStyles)((props) => (
  <>
    <GlobalStyleComponent/>
    <CustomDataGrid
      {...props}
      rowHeight={49}
      headerHeight={49}
      rowsPerPageOptions={[ 10, 25, 50 ]}
      pagination
      // autoPageSize={true}
      checkboxSelection
      // disableSelectionOnClick
      componentsProps={{
        pagination: {
          SelectProps: {
            MenuProps: {
              className: props['classes']['root']
            }
          }
        }
      }}
      components={{ 
        Toolbar: TableToolbar,
        Panel: CustomGridPanel,
      }}
    />
  </>
))

export default NewCustomDataGrid