import React, { useState, useEffect, useContext } from 'react'

// CLSX
import clsx from 'clsx'

// CONTEXTS
import { PageSignUpContext } from 'contexts/PageSignUpContext'

// CUSTOM COMPONENTS
import CustomFormControl from 'components/Custom/CustomFormControl'
import CustomTextField from 'components/Custom/CustomTextField'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// MATERIAL UI ICONS
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// STYLES
import useStyles from './formOneUseStyles'

const FormOne = (props) => {
  const { setActiveStep } = props

  const classes = useStyles()

  const { signUpUserObject, updateSignUpUserObject } = useContext(PageSignUpContext)

  const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')
	const [ confirmPassword, setConfirmPassword ] = useState('')
	const [ errorMessage, setErrorMessage ] = useState(null)

  const [ isPasswordShown, setIsPasswordShown ] = useState(false)
  const [ isConfirmPasswordShown, setIsConfirmPasswordShown ] = useState(false)

  const onButtonIsClicked = () => {
    setErrorMessage(null)
		
		if(email === '' || password === '' || confirmPassword === '') {
			setErrorMessage('Please fill all fields')
		}
		else {
			if(password !== confirmPassword) {
				setErrorMessage('Confirm passwords does not match')
			}
			else {
				if(password.length < 8) {
					setErrorMessage('Password must be at least 8 characters')
				}
				else {
          goToNextForm()
				}
			}
		}
  }

  const goToNextForm = () => {
    const newUserObject = { 
      ...signUpUserObject,
      email,
      password,
    }
    updateSignUpUserObject(newUserObject)
    setActiveStep(current => current + 1)
  }

  useEffect(() => {
		setErrorMessage(null)
	}, [email, password, confirmPassword])

  return (
    <div className={classes['formRoot']}>
      {/* ERROR MESSAGE */}
      { 
        errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert> 
      }

      {/* EMAIL TEXT FIELD */}
      <CustomTextField
        className={classes['emailTextField']}
        label='Email'
        variant='outlined'
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />

      {/* PASSWORD TEXT FIELD */}
      <CustomFormControl
        variant='outlined'
        className={clsx(classes['passwordTextField'])}
      >
        <InputLabel htmlFor='outlined-adornment-password'>
          Password
        </InputLabel>
        <OutlinedInput
          type={isPasswordShown ? 'text' : 'password'}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setIsPasswordShown(current => !current)}
                edge='end'
              >
                {isPasswordShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={80}
        />
      </CustomFormControl>

      {/* CONFIRM PASSWORD TEXT FIELD */}
      <CustomFormControl
        variant='outlined'
        className={clsx(classes['confirmPasswordTextField'])}
      >
        <InputLabel htmlFor='outlined-adornment-password'>
          Confirm Password
        </InputLabel>
        <OutlinedInput
          type={isConfirmPasswordShown ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => setIsConfirmPasswordShown(current => !current)}
                edge='end'
              >
                {isConfirmPasswordShown ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={150}
        />
      </CustomFormControl>

      {/* BUTTON */}
      <Button
        className={classes['button']}
        onClick={onButtonIsClicked}
      >
        <Typography
          variant='subtitle1'
          className={classes['textInButton']}
        >
          Next
        </Typography>
      </Button>
    </div>
  )
}

export default FormOne