import React, { useEffect, useContext } from 'react'

// COMPONENTS
import AdminsHeader from './AdminsHeader/AdminsHeader'
import AdminsTable from './AdminsTable/AdminsTable'
import FlyoutMainSection from './FlyoutMainSection/FlyoutMainSection'

// CONTEXTS
import { PageAdminsContext } from 'contexts/PageAdminsContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'

// SERVICES
import { formatGetUserListApi } from 'services/admins/getUserListApi'

// STYLES
import useStyles from './adminsUseStyles'

const Admins = () => {
  const { 
    isRightInfoOpen, 
    changeTableData,
    isLoading, 
    changeIsLoading,
  } = useContext(PageAdminsContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const fetchFormatGetUserListApi = async () => {
    changeIsLoading(true)

    const data = await formatGetUserListApi()
    if(data['error']) {
      changeToast({
        open: true,
        message: `${data['error']}: ${data['message']}`,
        severity: 'error',
      })
    }
    else {
      changeTableData(data)
    }

    changeIsLoading(false)
  }

  useEffect(() => {
    fetchFormatGetUserListApi()
    
    return () => fetchFormatGetUserListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes['adminsRoot']}>
      {isLoading ?
      <CustomCircularProgress/> :
      <>
        {/* HEADER */}
        <AdminsHeader/>

        <div
          className={
            isRightInfoOpen ?
            classes['cardsContainer'] :
            `${classes['cardsContainer']} ${classes['cardsContainerFull']}`
          }
        >
          {/* LEFT CONTENT */}
          <div 
            className={classes['cardLeft']}
          >
            <AdminsTable/>
          </div>

          {/* RIGHT CONTENT */}
          <div
            className={
              isRightInfoOpen ?
              `${classes['cardRight']}` :
              `${classes['cardRight']} ${classes['cardRightHide']}`
            }
          >
            <FlyoutMainSection/>
          </div>
        </div>
      </>}
    </div>
  )
}

export default Admins
