import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  dialogAction: {
    minWidth: 500,
  },
  itemListContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    cursor: 'pointer',
    transform: 'translate(0px, 0px)',
    transition: 'transform 0.25s ease-in-out',
    '&:hover': {
      transform: 'translate(15px, 0px)',
      transition: 'transform 0.25s ease-in-out',
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  iconContainer: {
    width: 35,
    height: 35,
    marginRight: 15,
    backgroundColor: colors['dodgerblue'],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemTitle: {
    fontWeight: 600,
    lineHeight: 1,
  },
  itemCaption: {
    color: colors['slategray'],
  },
  cancelText: {
    color: colors['lightslategray'],
    fontWeight: 600,
  },
}))

export default useStyles