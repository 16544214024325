import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageAdminsContext } from 'contexts/PageAdminsContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomCircularProgress from 'components/Custom/CustomCircularProgress'
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'

// MUI ICONS
import IconAccountBox from '@mui/icons-material/AccountBox'
import IconCall from '@mui/icons-material/Call'
import IconEmail from '@mui/icons-material/Email'
import IconPassword from '@mui/icons-material/Password'
import IconSettingsAccessibility from '@mui/icons-material/SettingsAccessibility'

// SERVICES
import { getRoleListApi } from 'services/admins/getRoleListApi'
import { postNewUserApi } from 'services/admins/postNewUserApi'
import { formatGetUserListApi } from 'services/admins/getUserListApi'
import { putUpdateUserApi } from 'services/admins/putUpdateUserApi'

// STYLES
import useStyles from './dialogAddOrEditAdminUseStyles'

const DialogAddOrEditAdmin = (props) => {
  const {
    type,
    dialogAddOrEditAdmin, 
    setDialogAddOrEditAdmin,
  } = props

  const { 
    changeIsLoading,
    changeTableData, 
  } = useContext(PageAdminsContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ name, setName ] = useState(type === 'edit' ? dialogAddOrEditAdmin['row']['name'] : (type === 'add' ? '' : ''))
  const [ phoneNumber, setPhoneNumber ] = useState(type === 'edit' ? dialogAddOrEditAdmin['row']['phoneNumber'] : (type === 'add' ? '' : ''))
  const [ email, setEmail ] = useState(type === 'edit' ? dialogAddOrEditAdmin['row']['email'] : (type === 'add' ? '' : ''))
  const [ password, setPassword ] = useState('')
  const [ roleList, setRoleList ] = useState([])
  const [ selectedRoleList, setSelectedRoleList ] = useState([])
  const [ errorMessage, setErrorMessage ] = useState(null)
  const [ isDialogLoading, setIsDialogLoading ] = useState(false)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditAdmin(false)
    }
  }

  const fetchGetRoleListApi = async () => {
    setIsDialogLoading(true)

    const data = await getRoleListApi()
    // console.log(data)
    setRoleList(data)

    setIsDialogLoading(false)
  }

  const fetchPostNewUserApi = async () => {
    changeIsLoading(true)

    const selectedRoleNameList = selectedRoleList.map(item => item['name'])

    let response
    if(type === 'add') {
      response = await postNewUserApi(
        name,
        email,
        phoneNumber,
        password,
        selectedRoleNameList,
      )
    }
    else if(type === 'edit') {
      response = await putUpdateUserApi(
        dialogAddOrEditAdmin['row']['uid'],
        name,
        email,
        phoneNumber,
        selectedRoleNameList,
      )

      // TODO: HANDLE CHANGE PASSWORD HERE
    }

    // console.log(response)

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully ${type}ing a ${type === 'add' ? 'new ' : ''}user`, 
        severity: 'success',
      })

      const data = await formatGetUserListApi()
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogAddOrEditAdmin(null)
  }

  const onSaveButtonIsClicked = () => {
    if(
      name === '' || phoneNumber === '' || email === '' || 
      password === '' || selectedRoleList['length'] === 0
    ) {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewUserApi()
    }
  }

  useEffect(() => {
    fetchGetRoleListApi()

    return () => fetchGetRoleListApi()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(type === 'edit') {
      let initialSelectedRoleList = []
      roleList.forEach(roleListItem => {
        dialogAddOrEditAdmin['row']['roles'].forEach(userRoleItem => {
          if(roleListItem['name'] === userRoleItem) initialSelectedRoleList.push(roleListItem)
        })
      })
      setSelectedRoleList(initialSelectedRoleList)
    }
  }, [roleList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setErrorMessage(null)
  }, [name, phoneNumber, email, password, selectedRoleList]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditAdmin)}
      onClose={handleClose}
    >
      {isDialogLoading ?
      <CustomCircularProgress/> :
      <>
        {/* DIALOG TITLE */}
        <CustomDialogTitle>
          {`${type} a ${type === 'add' ? 'new' : ''} user`}
        </CustomDialogTitle>

        {/* DIALOG CONTENT */}
        <CustomDialogContent>
          {/* ERROR MESSAGE */}
          {errorMessage && 
          <Alert 
            variant='filled'
            severity='error' 
            className={classes['errorMessage']}
          >
            {errorMessage}
          </Alert>}

          {/* NAME */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconAccountBox className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Name' 
              value={name}
              onChange={(event) => setName(event['target']['value'])}
            />
          </div>

          {/* PHONE NUMBER */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconCall className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Phone Number' 
              // type='number'
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event['target']['value'])}
            />
          </div>

          {/* EMAIL */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconEmail className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Email' 
              value={email}
              onChange={(event) => setEmail(event['target']['value'])}
            />
          </div>

          {/* PASSWORD */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconPassword className={classes['icon']}/>
            <CustomTextFieldSmall 
              className={classes['textField']}
              label='Password' 
              type='password'
              value={password}
              onChange={(event) => setPassword(event['target']['value'])}
            />
          </div>

          {/* ROLES */}
          <div className={classes['iconAndTextFieldContainer']}>
            <IconSettingsAccessibility className={classes['icon']}/>
            <Autocomplete
              className={`${classes['textField']} ${classes['autocompleteChip']}`}
              value={selectedRoleList}
              onChange={(event, newValue) => setSelectedRoleList(newValue)}
              multiple
              limitTags={3}
              options={roleList}
              getOptionLabel={(option) => option['name']}
              renderInput={(params) => (
                <CustomTextFieldSmall 
                  {...params} 
                  label='Roles' 
                />
              )}
            />
          </div>
        </CustomDialogContent>

        {/* DIALOG ACTIONS */}
        <CustomDialogActions>
          {/* CANCEL BUTTON */}
          <Button onClick={() => setDialogAddOrEditAdmin(null)}>
            <Typography
              variant='subtitle2'
              className={classes['cancelText']}
            >
              Cancel
            </Typography>
          </Button>

          {/* OK BUTTON */}
          <Button onClick={() => onSaveButtonIsClicked()}>
            <Typography
              variant='subtitle2'
              className={classes['okText']}
            >
              Save
            </Typography>
          </Button>
        </CustomDialogActions>
      </>}
    </CustomDialog>
  )
}

export default DialogAddOrEditAdmin
