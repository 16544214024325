import React from 'react'

// MATERIAL UI CORES
import Snackbar from '@material-ui/core/Snackbar'

// MATERIAL UI LAB
import MuiAlert from '@material-ui/lab/Alert'

const Toast = (props) => {
  const {
    open,
    setToast,
    severity,
    message,
  } = props

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast(null)
  }

  return (
    <Snackbar 
      open={Boolean(open)} 
      autoHideDuration={6000} 
      onClose={handleToastClose}
    >
      <MuiAlert 
        elevation={6}
        variant='filled'
        onClose={handleToastClose}
        severity={severity}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export default Toast
