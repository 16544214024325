import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  accessoriesHeader: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'center',
  },
  addIconButton: {
    marginRight: 25,
  },
  title: {
    fontWeight: 600,
    marginRight: 'auto',
  },
}))

export default useStyles