// MATERIAL UI CORES
import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  defaultText: {
    fontWeight: 400,
    fontSize: 12,
  },
  ligtherText: {
    fontWeight: 400,
    fontSize: 12,
    color: colors['slategray'],
  },
  statusText: {
    fontWeight: 400,
    fontSize: 12,
    '&::before': {
      content: '""',
      display: 'inline-block',
      marginRight: 5,
      width: 7,
      height: 7,
      borderRadius: '50%',
    },
  },
  statusTextUnassigned: {
    '&::before': {
      backgroundColor: colors['slategray'],
    },
  },
  statusTextAssigned: {
    '&::before': {
      backgroundColor: colors['dodgerblue'],
    },
  },
  statusTextCompleted: {
    '&::before': {
      backgroundColor: colors['lightseagreen'],
    },
  },
  statusTextFailed: {
    '&::before': {
      backgroundColor: colors['coral'],
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionIcon: {
    height: 15,
    width: 15,
    color: colors['slategray'],
  },
}))

export default useStyles