import React, { useState, useContext } from 'react'

// CONTEXTS
import { PageStockOutContext } from 'contexts/PageStockOutContext'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import DialogQuickAction from '../DialogQuickAction/DialogQuickAction'

// STYLES
import useStyles from './rightInfoAddButtonUseStyles'

const RightInfoAddButton = () => {
  const classes = useStyles()

  const { 
    selectedRowList, 
    tableData, 
  } = useContext(PageStockOutContext)

  const [ isDialogQuickActionOpen, setIsDialogQuickActionOpen ] = useState(false)

  const selectedData = tableData.filter(item => selectedRowList.includes(item['id']))

  const isAddButtonShown = selectedData['length'] === 1 ? true : false

  return (
    <>
      {/* ADD BUTTON */}
      {isAddButtonShown &&
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setIsDialogQuickActionOpen(true)}
      />}

      {/* DIALOG */}
      <DialogQuickAction
        isDialogQuickActionOpen={isDialogQuickActionOpen}
        setIsDialogQuickActionOpen={setIsDialogQuickActionOpen}
      />
    </>
  )
}

export default RightInfoAddButton
