import React, { useRef } from 'react'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'

// MATERIAL UI CORES
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

// PDF PRINT
import ReactToPrint from 'react-to-print'

// STYLES
import useStyles from './printDialogUseStyles'

const PrintDialog = (props) => {
  const { 
    printDialog, 
    setPrintDialog,
  } = props

  const classes = useStyles()
  
  const printPreviewRef = useRef()

  const getPageMargins = () => {
    return `@page { margin: 0 0 0 0 !important; }`
  }

  const pageStyle = `
    @page {
      size: 5cm 3cm;
    }

    @media all {
      .pagebreak {
        display: none;
      }
    }

    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
  `

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setPrintDialog(false)
    }
  }
  
  return (
    <CustomDialog 
      open={Boolean(printDialog)}
      onClose={handleClose}
      scroll={'paper'}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        Print
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {printDialog && printDialog['length'] === 0 ?
        // LOADING
        <CircularProgress className={classes['loading']}/> :
        // MESSAGE
        <Typography
          variant='subtitle1'
          className={classes['messageText']}
        >
          The file is ready to be printed
        </Typography>}

        {/* PRINT PREVIEW */}
        <div className={classes['printPreview']}>
          <div ref={printPreviewRef}>
            <style>{getPageMargins()}</style>
            {printDialog &&
            printDialog.map((item, index) => (
              <div
                key={index}
                className={classes['itemContainer']}
              >
                {/* QR CODE */}
                <div className={classes['qrCodeContainer']}>
                  <img
                    src={item['url']}
                    alt=''
                    className={classes['qrCodeImage']}
                  />
                </div>

                {/* QR CODE TEXT */}
                <Typography
                  variant='subtitle1'
                  className={classes['qrCodeText']}
                >
                  {item['text']}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CANCEL BUTTON */}
        <Button onClick={() => setPrintDialog(false)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* PRINT BUTTON */}
        <ReactToPrint
          trigger={() =>
            <Button>
              <Typography
                variant='subtitle2'
                className={classes['okText']}
              >
                Print
              </Typography>
            </Button>
          }
          content={() => printPreviewRef.current}
          pageStyle={pageStyle}
          onAfterPrint={() => setPrintDialog(false)}
        />
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default PrintDialog
