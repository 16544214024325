import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useStyles = makeStyles((theme) => ({
  addButtonRoot: {
    width: 56,
    height: 56,
    backgroundColor: colors['dodgerblue'],
    boxShadow: `0px 5px 10px ${colors['boxShadowDark']}`,
    color: 'white',
    '&:hover': {
      backgroundColor: `${colors['dodgerblue']}`,
      filter: 'brightness(105%)',
    },
  },
}))

export default useStyles