// IMAGES
import IconDashboardGray from 'images/icons/sidebar_dashboard_gray.svg'
import IconDashboardWhite from 'images/icons/sidebar_dashboard_white.svg'
import IconItemsGray from 'images/icons/sidebar_item_gray.svg'
import IconItemsWhite from 'images/icons/sidebar_item_white.svg'
import IconPlacesGray from 'images/icons/sidebar_places_gray.svg'
import IconPlacesWhite from 'images/icons/sidebar_places_white.svg'
import IconStockOutGray from 'images/icons/sidebar_stockout_gray.svg'
import IconStockOutWhite from 'images/icons/sidebar_stockout_white.svg'
import IconSalesOrderGray from 'images/icons/sidebar_salesorder_gray.svg'
import IconSalesOrderWhite from 'images/icons/sidebar_salesorder_white.svg'
import IconTasksGray from 'images/icons/sidebar_tasks_gray.svg'
import IconTasksWhite from 'images/icons/sidebar_tasks_white.svg'
import IconSubmissionsGray from 'images/icons/sidebar_submissions_gray.svg'
import IconSubmissionsWhite from 'images/icons/sidebar_submissions_white.svg'
import IconUsersGray from 'images/icons/sidebar_users_gray.svg'
import IconUsersWhite from 'images/icons/sidebar_users_white.svg'
import IconProfileGray from 'images/icons/sidebar_profile_gray.svg'
import IconProfileWhite from 'images/icons/sidebar_profile_white.svg'

export const pageListFullData = [
  {
    type: 'single',
    name: 'Dashboard',
    iconDefault: IconDashboardGray,
    iconSelected: IconDashboardWhite,
  },
  {
    type: 'collection',
    name: 'Items',
    children: [
      'Devices',
      'Accessories',
      'Car Models',
    ],
    iconDefault: IconItemsGray,
    iconSelected: IconItemsWhite,
  },
  {
    type: 'single',
    name: 'Places',
    iconDefault: IconPlacesGray,
    iconSelected: IconPlacesWhite,
  },
  {
    type: 'single',
    name: 'Shipments',
    iconDefault: IconStockOutGray,
    iconSelected: IconStockOutWhite,
  },
  {
    type: 'single',
    name: 'Orders',
    iconDefault: IconSalesOrderGray,
    iconSelected: IconSalesOrderWhite,
  },
  {
    type: 'collection',
    name: 'Tasks',
    children: [
      'Pickup Requests',
      'Installation Requests',
    ],
    iconDefault: IconTasksGray,
    iconSelected: IconTasksWhite,
  },
  {
    type: 'collection',
    name: 'Submissions',
    children: [
      'Pickups',
      'Installations',
    ],
    iconDefault: IconSubmissionsGray,
    iconSelected: IconSubmissionsWhite,
  },
  {
    type: 'collection',
    name: 'Users',
    children: [
      'Staffs',
      'Admins',
    ],
    iconDefault: IconUsersGray,
    iconSelected: IconUsersWhite,
  },
  {
    type: 'single',
    name: 'Profile',
    iconDefault: IconProfileGray,
    iconSelected: IconProfileWhite,
  },
]

export const pageListWulingData = [
  {
    type: 'single',
    name: 'Places',
    iconDefault: IconPlacesGray,
    iconSelected: IconPlacesWhite,
  },
  {
    type: 'single',
    name: 'Installation Tasks',
    iconDefault: IconTasksGray,
    iconSelected: IconTasksWhite,
  },
  {
    type: 'single',
    name: 'Installation Submissions',
    iconDefault: IconSubmissionsGray,
    iconSelected: IconSubmissionsWhite,
  },
]