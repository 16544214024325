import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  searchFieldRoot: {
    display: 'flex',
    overflow: 'hidden',
  },
  iconSearch: {
    height: 44,
    width: 44,
  },
  customSearchInputWide: {
    width: 250,
    transition: 'width 0.25s ease-in-out',
  },
  customSearchInputNarrow: {
    width: 0,
    transition: 'width 0.25s ease-in-out',
  },
  iconClose: {
    width: 43,
    height: 43,
  },
}))

export default useStyles