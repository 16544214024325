import React, { useState, useEffect, useContext, useCallback } from 'react'

// CONFIGURATIONS
import { 
  circleOptions, 
  transparentCircleOptions,
} from './showMapSettings'

// CONTEXTS
import { PagePlacesContext } from 'contexts/PagePlacesContext'

// IMAGES
import IconMarker from 'images/icons/places_marker.svg'

// GOOGLE MAPS
import {
  Circle,
  GoogleMap,
  OverlayView,
} from '@react-google-maps/api'

// STYLES
import useStyles from './showMapUseStyles'

const ShowMap = (props) => {
  const { containerStyle } = props

  const classes = useStyles()

  const {
    selectedRowList,
    tableData,
  } = useContext(PagePlacesContext)

  const selectedPlaces = tableData.filter(item => selectedRowList.includes(item['id']))

  const [ map, setMap ] = useState(null)

  const initialCenter = {
    lat: -0.7893,
    lng: 113.9213,
  }

  const onMapLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds()
    map.fitBounds(bounds)
    setMap(map)
  }, [])

  const onMapUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  useEffect(() => {
    if(map) {
      const bounds = new window.google.maps.LatLngBounds()
      selectedPlaces.forEach(item => {
        bounds.extend(new window.google.maps.LatLng(
          item['latitude'],
          item['longitude'],
        ))
        const newCircle = new window.google.maps.Circle({
          ...transparentCircleOptions,
          map,
          center: { lat: item['latitude'], lng: item['longitude'] },
          radius: item['radius'],
        })
        bounds.union(newCircle.getBounds())
      })
      map.fitBounds(bounds)
      setMap(map)
    }
  }, [selectedPlaces]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={initialCenter}
      zoom={10}
      onLoad={onMapLoad}
      onUnmount={onMapUnmount}
      options={{
        // SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        // zoomControl: false,
      }}
    >
      {/* MARKERS */}
      {selectedPlaces.map((item, index) => (
        <OverlayView
          key={index}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          position={{
            lat: item['latitude'], 
            lng: item['longitude'],
          }}
        >
          <div className={classes['marker']}>
            <img
              src={IconMarker}
              alt=''
            />
          </div>
        </OverlayView>
      ))}

      {/* CIRCLES */}
      {selectedPlaces.map((item, index) => (
        <Circle
          key={index}
          center={{
            lat: item['latitude'], 
            lng: item['longitude'],
          }}
          radius={item['radius']}
          options={circleOptions}
        />
      ))}
    </GoogleMap>
  )
}

export default ShowMap
