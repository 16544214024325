import React, { createContext, useState } from 'react'

const PageStaffContext = createContext()

const PageStaffContextProvider = (props) => {
  // TYPE = BOOLEAN
  const [ isRightInfoOpen, setIsRightInfoOpen ] = useState(true)
  const [ isLoading, setIsLoading ] = useState(false)

  // TYPE = ARRAY
  const [ selectedRowList, setSelectedRowList ] = useState([])
  const [ tableData, setTableData ] = useState([])

  // TYPE = STRING
  const [ search, setSearch ] = useState('')

  // TYPE = OBJECT
  const [ toBeDeletedObject, setToBeDeletedObject ] = useState(null)

  const changeIsRightInfoOpen = (inputState) => {
    setIsRightInfoOpen(inputState)
  }

  const changeSelectedRowList = (inputRowList) => {
    setSelectedRowList(inputRowList)
  }

  const changeIsLoading = (inputState) => {
    setIsLoading(inputState)
  }

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeToBeDeletedObject = (inputObject) => {
    setToBeDeletedObject(inputObject)
  }

  return (
    <PageStaffContext.Provider
      value={{
        isRightInfoOpen, changeIsRightInfoOpen,
        selectedRowList, changeSelectedRowList,
        isLoading, changeIsLoading,
        search, changeSearch,
        tableData, changeTableData,
        toBeDeletedObject, changeToBeDeletedObject,
      }}
    >
      {props['children']}
    </PageStaffContext.Provider>
  )
}

export { PageStaffContextProvider, PageStaffContext }