import { makeStyles } from '@material-ui/core/styles'

// UTILS
import colors from 'utils/colors'

const useCustomSteperLabelStyles = makeStyles({
  root: {
    backgroundColor: colors['darkgray'],
    color: '#fff',
    width: 24,
    height: 24,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
  },
  active: {
    backgroundColor: colors['dodgerblue'],
  },
  completed: {
    backgroundColor: colors['dodgerblue'],
  },
})

export default useCustomSteperLabelStyles