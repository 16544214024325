import React, { useState, useContext } from 'react'

// COMPONENTS
import AddButton from 'components/AddButton/AddButton'
import SearchField from 'components/SearchField/SearchField'
import ToggleRightInfo from 'components/ToggleRightInfo/ToggleRightInfo'
import QuickActionDialog from '../QuickActionDialog/QuickActionDialog'
import PrintDialog from '../PrintDialog/PrintDialog'

// CONTEXTS
import { PageDevicesContext } from 'contexts/PageDevicesContext'

// IMAGES
import IconQrCode from 'images/icons/devices_qr_code.svg'

// MATERIAL UI CORES
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'

// QR CODE
import QRCode from 'qrcode'

// STYLES
import useStyles from './devicesHeaderUseStyles'

const DataGridHeader = () => {
  const { 
    search,
    changeSearch,
    isHistoryOpen,
    changeIsHistoryOpen,
    selectedRowList,
    tableData,
  } = useContext(PageDevicesContext)
  
  const [ isQuickActionDialog, setIsQuickActionDialog ] = useState(false)
  const [ printDialog, setPrintDialog ] = useState(null)

  const classes = useStyles()

  const generateQrCodeList = async () => {
    const selectedRowData = tableData.filter(item => selectedRowList.includes(item['id']))
    const qrCodeTextList = selectedRowData.map(item =>
      `LW-${item['imei'].toString().substr(-6)}-${item['simNumber'].toString().substr(-6)}`
    )
    let qrCodeObjectList = []
    for(let item of qrCodeTextList) {
      const qrCodeObject = {
        url: await QRCode.toDataURL(item, { margin: 1 }),
        text: item,
      }
      qrCodeObjectList.push(qrCodeObject)
    }
    setPrintDialog(qrCodeObjectList)
  }

  return (
    <div className={classes['dataGridHeader']}>
      {/* ADD BUTTON */}
      <AddButton
        className={classes['addIconButton']}
        onClick={() => setIsQuickActionDialog(true)}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={classes['title']}
      >
        Devices
      </Typography>

      {/* QR CODE */}
      {selectedRowList['length'] > 0 &&
      <IconButton onClick={() => generateQrCodeList()}>
        <img
          src={IconQrCode}
          alt=''
        />
      </IconButton>}

      {/* SEARCH TEXT FIELD */}
      <SearchField
        value={search}
        onChange={(event) => changeSearch(event['target']['value'])}
      />

      {/* TITLE */}
      <Typography
        variant='h6'
        className={
          isHistoryOpen ?
          `${classes['information']} ${classes['informationVisible']}` :
          classes['information']
        }
      >
        Information
      </Typography>

      {/* TOGGLE HISTORY */}
      <ToggleRightInfo
        isRightInfoOpen={isHistoryOpen}
        onClick={() => changeIsHistoryOpen(current => !current)}
      />

      {/* QUICK ACTION DIALOG */}
      <QuickActionDialog
        isQuickActionDialog={isQuickActionDialog}
        setIsQuickActionDialog={setIsQuickActionDialog}
      />

      {/* PRINT DIALOG */}
      <PrintDialog
        printDialog={printDialog}
        setPrintDialog={setPrintDialog}
      />
    </div>
  )
}

export default DataGridHeader
