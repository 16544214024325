import React, { createContext, useState } from 'react'

const PageSubmissionsContext = createContext()

const PageSubmissionsContextProvider = (props) => {
  const { pageType } = props

  // TYPE = STRING
  const [ search, setSearch ] = useState('')

  // TYPE = ARRAY
  const [ tableData, setTableData ] = useState([])
  const [ selectedRowList, setSelectedRowList ] = useState([])

  const changeSearch = (inputSearch) => {
    setSearch(inputSearch)
  }

  const changeTableData = (inputData) => {
    setTableData(inputData)
  }

  const changeSelectedRowList = (inputRowList) => {
    setSelectedRowList(inputRowList)
  }

  return (
    <PageSubmissionsContext.Provider
      value={{
        pageType,
        search, changeSearch,
        tableData, changeTableData,
        selectedRowList, changeSelectedRowList,
      }}
    >
      {props['children']}
    </PageSubmissionsContext.Provider>
  )
}

export { PageSubmissionsContextProvider, PageSubmissionsContext }