// MATERIAL UI CORES
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'

const CustomDialog = withStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      height: '100vh',
      width: '100vw',
      borderRadius: 6,
    },
  },
}))(Dialog)

export default CustomDialog
