import React, { useState, useEffect, useContext } from 'react'

// CONTEXTS
import { PageCarModelContext } from 'contexts/PageCarModelContext'
import { PageAllContext } from 'contexts/PageAllContext'

// CUSTOM COMPONENTS
import CustomDialog from 'components/Custom/CustomDialog'
import CustomDialogActions from 'components/Custom/CustomDialogActions'
import CustomDialogContent from 'components/Custom/CustomDialogContent'
import CustomDialogTitle from 'components/Custom/CustomDialogTitle'
import CustomTextFieldSmall from 'components/Custom/CustomTextFieldSmall'

// MATERIAL UI CORES
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

// MUI ICONS
import IconDirectionsCar from '@mui/icons-material/DirectionsCar'
import IconFormatColorFill from '@mui/icons-material/FormatColorFill'
import IconColorLens from '@mui/icons-material/ColorLens'

// MATERIAL UI LABS
import Alert from '@material-ui/lab/Alert'

// SERVICES
import { postNewCarModelApi } from 'services/carModel/postNewCarModelApi'
import { getCarModelListApi } from 'services/carModel/getCarModelListApi'
import { putUpdateCarModel } from 'services/carModel/putUpdateCarModel'

// STYLES
import useStyles from './dialogAddOrEditCarModelUseStyles'

const DialogAddOrEditCarModel = (props) => {
  const { 
    type,
    dialogAddOrEditCarModel, 
    setDialogAddOrEditCarModel, 
  } = props

  const { 
    changeIsLoading, 
    changeTableData, 
  } = useContext(PageCarModelContext)

  const { changeToast } = useContext(PageAllContext)

  const classes = useStyles()

  const [ carModelName, setCarModelName ] = useState(
    type === 'edit' ? dialogAddOrEditCarModel['row']['name'] : (type === 'add' ? '' : '')
  )
  const [ carColorName, setCarColorName ] = useState(
    type === 'edit' ? dialogAddOrEditCarModel['row']['colorName'] : (type === 'add' ? '' : '')
  )
  const [ colorCode, setColorCode ] = useState(
    type === 'edit' ? dialogAddOrEditCarModel['row']['hex'] : (type === 'add' ? '#000000' : '#000000')
  )
  const [ errorMessage, setErrorMessage ] = useState(null)

  const handleClose = (event, reason) => {
    if(reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    }
    else {
      setDialogAddOrEditCarModel(false)
    }
  }

  const fetchPostNewCarModelApi = async () => {
    changeIsLoading(true)

    let response
    if(type === 'add') {
      response = await postNewCarModelApi(
        carModelName,
        { 
          name: carColorName, 
          hex: colorCode, 
        },
      )
    }
    else if(type === 'edit') {
      response = await putUpdateCarModel(
        dialogAddOrEditCarModel['id'],
        carModelName,
        { 
          name: carColorName, 
          hex: colorCode, 
        },
      )
    }

    if(response['error']) {
      changeToast({
        open: true,
        message: `${response['error']}: ${response['message']}`,
        severity: 'error',
      })
    }
    else {
      changeToast({
        open: true,
        message: `Successfully ${type === 'edit' ? 'edit' : 'add'}ing a ${type === 'add' ? 'new ' : ''}car model item`, 
        severity: 'success',
      })

      const data = await getCarModelListApi()
      // console.log(data)
      if(data['error']) {
        changeToast({
          open: true,
          message: `${data['error']}: ${data['message']}`,
          severity: 'error',
        })
      }
      else {
        changeTableData(data)
      }
    }

    changeIsLoading(false)
    setDialogAddOrEditCarModel(false)
  }

  const onSaveButtonIsClicked = () => {
    if(carModelName === '') {
      setErrorMessage('Please fill all fields')
    }
    else {
      fetchPostNewCarModelApi()
    }
  }

  useEffect(() => {
    setErrorMessage(null)
  }, [carModelName]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CustomDialog 
      open={Boolean(dialogAddOrEditCarModel)}
      onClose={handleClose}
    >
      {/* DIALOG TITLE */}
      <CustomDialogTitle>
        {type === 'add' && 'Add a New Car Model'}
        {type === 'edit' && 'Edit a Car Model'}
      </CustomDialogTitle>

      {/* DIALOG CONTENT */}
      <CustomDialogContent>
        {/* ERROR MESSAGE */}
        {errorMessage && 
        <Alert 
          variant='filled'
          severity='error' 
          className={classes['errorMessage']}
        >
          {errorMessage}
        </Alert>}

        {/* CAR MODEL NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconDirectionsCar className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Car Model Name' 
            value={carModelName}
            type='text'
            onChange={(event) => setCarModelName(event['target']['value'])}
          />
        </div>

        {/* CAR COLOR NAME */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconFormatColorFill className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Car Color Name' 
            value={carColorName}
            type='text'
            onChange={(event) => setCarColorName(event['target']['value'])}
          />
        </div>

        {/* COLOR PICKER */}
        <div className={classes['iconAndTextFieldContainer']}>
          <IconColorLens className={classes['icon']}/>
          <CustomTextFieldSmall 
            className={classes['textField']}
            label='Color Code' 
            value={colorCode}
            type='text'
            onChange={(event) => setColorCode(event['target']['value'])}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CustomTextFieldSmall 
                    className={classes['colorPicker']}
                    value={colorCode}
                    type='color'
                    onChange={(event) => setColorCode(event['target']['value'])}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </CustomDialogContent>

      {/* DIALOG ACTIONS */}
      <CustomDialogActions>
        {/* CLOSE BUTTON */}
        <Button onClick={() => setDialogAddOrEditCarModel(null)}>
          <Typography
            variant='subtitle2'
            className={classes['cancelText']}
          >
            Cancel
          </Typography>
        </Button>

        {/* FINISH BUTTON */}
        <Button onClick={() => onSaveButtonIsClicked()}>
          <Typography
            variant='subtitle2'
            className={classes['okText']}
          >
            Save
          </Typography>
        </Button>
      </CustomDialogActions>
    </CustomDialog>
  )
}

export default DialogAddOrEditCarModel
